import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Controller from "../../shared/controller/Controller";
import config from "../../../config";
import FeedbackPreview from "../preview/FeedbackPreview";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { fetchCampNameList } from "../../../redux/action/campaign";
import FieldComponent from "./feedbackComponents/FieldComponent";
import Style from "../../shared/controller/Style";

function Feedback() {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [data, setData] = useState({
    loaded: false,
    initialValues: {
      ACTION: "ADD",
      CAMP_ID: "",
      CAMP_NAME: "",
      COMP_ID: 6,
      ACTIVE: 1,
      DB_OBJECT: "",
      LIST_ID: "null",
      HUNTGROUP: "null",
      DIALER_IP: "null",
      OTP_ENABLE: 0,
      API_DATA: {
        gradientColor1: "",
        gradientColor2: "",
        gradientColor3: "",
        gradientColor4: "",
        backgroundImage: "",
        cardStyle: {
          backgroundColor:""
        },
        heading:"",
        content:"",
        forms: [],
      },
    },
  });
  const { campNames } = useSelector((state) => state.campName);
  const { UserToken } = useSelector((state) => state.userInfo);
  const history = useHistory();
  const dispatch = useDispatch();
  let campOption = [];
  useEffect(() => {
    if (campNames && campNames.length === 0) {
      dispatch(fetchCampNameList(UserToken));
    }
  }, []);
  if (campNames && campNames.length > 0) {
    campOption = campNames.map((item) => {
      return {
        label: item.campname,
        value: item.campid,
      };
    });
  }
  const validationSchema = Yup.object({
    API_DATA: Yup.object(),
  });

  const onSubmit = (values, actions) => {
    console.log(values);
    axios
      .post(`${config.API_URL}/msdash/managecomponent/`, values, {
        headers: { Authorization: UserToken },
      })
      .then((res) => {
        setSuccess(res.data.message);
        window.scroll(0, 0);
        actions.setSubmitting(false);
        console.log(res);
      })
      .catch((err) => {
        setWarning(err.message);
        actions.setSubmitting(false);
        console.log(err);
      });
  };


  return (
    <Formik
      initialValues={data.initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { setFieldValue } = formik;
        const getCompData = (e) => {
          const campid = e.value;
          axios
            .get(`${config.API_URL}/ms/getcompdata/${campid}/6`)
            .then((result) => {
              let forms = Object.values(result.data.forms);
              forms = forms.map((item) => ({
                ...item,
                options: Object.values(item.options),
                validations: Object.values(item.validations).map((child) => ({
                  ...child,
                  params: Object.values(child.params),
                })),
              }));
              setData({
                loaded: true,
                initialValues: {
                  ...data.initialValues,
                  ...result.data,
                  forms: forms,
                  ACTION: "UPDATE",
                  CAMP_ID: campid,
                  CAMP_NAME: e.label,
                },
              });
              setFieldValue("API_DATA", {
                ...result.data,
                forms: forms,
              });
              setFieldValue("ACTION", "UPDATE");
              setFieldValue("CAMP_ID", campid);
              setFieldValue("CAMP_NAME", e.label);
            })
            .catch((err) => {
              formik.resetForm();
              setData({ ...data, loaded: true });
              setFieldValue("CAMP_ID", campid);
              setFieldValue("CAMP_NAME", e.label);
              console.log(err);
            });
        };
        return (
          <>
            <div className={"col-md-8 mx-auto"}>
              <div className="card shadow-lg">
                <div className="card-body">
                  <h6 className="card-title"> Feedback Page</h6>
                  <Form className="forms-sample">
                    {success && (
                      <div className="alert alert-success">{success}</div>
                    )}
                    {warning && (
                      <div className="alert alert-warning">{warning}</div>
                    )}
                    <Controller
                      control="multiSelect"
                      label="Campaign Name"
                      value={formik.values.campid}
                      name="campid"
                      isMulti={false}
                      formik={formik}
                      options={campOption}
                      onChange={getCompData}
                      wrapperClass={
                        formik.errors.campid && formik.touched.campid
                          ? "has-error"
                          : null
                      }
                    />
                    {data.loaded && (
                      <>
                        <Controller
                          control="input"
                          type="color"
                          label="Gradient Color 1"
                          value={formik.values.API_DATA.gradientColor1}
                          name="API_DATA.gradientColor1"
                        />
                        <Controller
                          control="input"
                          type="color"
                          label="Gradient Color 2"
                          value={formik.values.API_DATA.gradientColor2}
                          name="API_DATA.gradientColor2"
                        />
                        <Controller
                          control="input"
                          type="color"
                          label="Gradient Color 3"
                          value={formik.values.API_DATA.gradientColor3}
                          name="API_DATA.gradientColor3"
                        />
                        <Controller
                          control="input"
                          type="color"
                          label="Gradient Color 4"
                          value={formik.values.API_DATA.gradientColor4}
                          name="API_DATA.gradientColor4"
                        />
                        <Controller
                          control="input"
                          type="text"
                          label="Background Image"
                          value={formik.values.API_DATA.backgroundImage}
                          name="API_DATA.backgroundImage"
                        />
                        <Style
                              defaultValue={[
                                "API_DATA",
                                "cardStyle",
                              ]}
                              name="API_DATA.cardStyle"
                              styleName="Style"
                              formik={formik}
                            />
                        <Controller
                          control="input"
                          type="text"
                          label="Form Heading"
                          value={formik.values.API_DATA.heading}
                          name="API_DATA.heading"
                        />
                        <Controller
                          control="textArea"
                          label="Form Content"
                          value={formik.values.API_DATA.content}
                          name="API_DATA.content"
                        />
                        <FieldArray
                          name="API_DATA.forms"
                          render={(arrayHelper) => {
                            const forms = formik.values.API_DATA.forms;
                            console.log("forms", forms);
                            return (
                              <>
                                {forms.length > 0 ? (
                                  forms.map((item, index) => {
                                    return (
                                      <FieldComponent
                                        {...item}
                                        name={`API_DATA.forms[${index}]`}
                                        formIndex={index}
                                        formik={formik}
                                        arrayHelper={arrayHelper}
                                        form={formik.values.API_DATA.forms[index]}
                                      />
                                    );
                                  })
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-rounded btn-dark btn-outline mr-1 mb-5"
                                    onClick={() =>
                                      arrayHelper.push({
                                        control: "input",
                                        type: "text",
                                        label: "",
                                        options: [],
                                        placeholder: "",
                                        name: "",
                                        value: "",
                                        validationType: "",
                                        validations: [],
                                      })
                                    }
                                  >
                                    <i className="ti-plus"></i> Add Field
                                  </button>
                                )}
                              </>
                            );
                          }}
                        />
                        <div className="box-footer text-right">
                          <button
                            type="button"
                            className="btn btn-rounded btn-warning btn-outline mr-1"
                            onClick={() => history.goBack()}
                          >
                            <i className="fa fa-trash-o"></i> Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-rounded btn-primary btn-outline"
                          >
                            {formik.isSubmitting ? (
                              <i className="fa fa-spin fa-refresh"></i>
                            ) : (
                              <i className="fa fa-fw fa-save"></i>
                            )}{" "}
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>

            {data.loaded && (
              <div className="col-md-4" style={{ display: "block" }}>
                <div
                  className="card position-fixed w-25 shadow-lg p-3"
                  style={{ margin: "auto 0px" }}
                >
                  <FeedbackPreview feedbackData={formik.values.API_DATA} />
                </div>
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
}

export default Feedback;
