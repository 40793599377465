import React from 'react'
import CheckBox from './CheckBox';
import EmojiRating from './EmojiRating';
import Input from './Input';
import Radio from './Radio';
import Select from './Select';
import TextArea from './TextArea';
import Toggle from './Toggle';


function Controller(props) {
   const {control, ...rest}=props;
   switch (control) {
      case "input":
          return <Input {...rest} />;
      case "radio":
          return <Radio {...rest}/>;
      case "emojiRating":
          return <EmojiRating {...rest} />;
      case "textArea":
          return <TextArea {...rest} />
      case "select":
          return <Select {...rest}/>
      case "checkBox":
          return <CheckBox {...rest}/>
      case "toggle":
         return  <Toggle {...rest} />;
       
   }
}

export default Controller
