import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Error from "../Component/Error";
import Loader from "../Component/Loader";
import Otp from "../Component/Otp";
import config from "../config";
import { errLog, eventLog } from "../utils/function";
import Error404 from "./Error/Error";

function Login({ randKey, setUser }) {
  const [loader, setLoader] = useState(true);
  const [captchaImage, setCaptchaImage] = useState('');
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  const [ui, setUi] = useState({});
  const [submit, setSubmit] = useState("");
  // const [captchaText, setCaptchaText] = useState(generateCaptcha());
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [otp, setOtp] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  useEffect(async () => {

    axios
      .get(`${config.API_URL}/ms/getUidesign/${randKey}`)
      .then(async (res) => {
        if (res.data.campId) {
          // delete res.data.captcha
          setUi(res.data);
          setLoader(false);
          const data = [
            {
              RAND_KEY: randKey,
              CUST_ID: res.data.cust_id,
              CAMP_ID: res.data.campName,
              STATUS: "Link Clicked.",
            },
          ];
          await eventLog(data);
          const pageLoaded = [
            {
              RAND_KEY: randKey,
              CUST_ID: res.data.cust_id,
              CAMP_ID: res.data.campName,
              STATUS: "LogIn Page Loaded.",
            },
          ];
          await eventLog(pageLoaded);
        } else {
          setError("Something went wrong.");
          setLoader(false);
        }
      })
      .catch(async(err) => {
        setError("You are not allowed for this action.");
        setLoader(false);
        const data = {
          sNo: "9999",
          taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/getUidesign/${randKey}`,
          errMessage: {
            Message: err.message,
          },
          errRemarks: `ERROR OCCURED WITH KEY${randKey}`,
        };
        errLog(data);
        await fetchCaptchaImage()
      });
    await fetchCaptchaImage()
  }, []);


  const fetchCaptchaImage = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/ms/captcha/${randKey}`, {
        responseType: 'arraybuffer' // Set responseType as arraybuffer to receive binary data
      });

      const blob = new Blob([response.data], { type: 'image/png' });
      const imgUrl = URL.createObjectURL(blob);
      setCaptchaImage(imgUrl);
    } catch (error) {
      console.error('Error fetching CAPTCHA:', error);
    }
  };


  const login = async (e) => {
    e.preventDefault();
    setSubmit("loading");


    let password = document.getElementById("exampleInputPassword1").value;
    if (ui.password.controlType === "date") {
      const dateArr = password.split("-");
      password = dateArr.join(""); // Join the date parts into a single string
    }

    // /:radkey/:authkey
    // checkAuthentication

    axios
      .post(`${config.API_URL}/ms/checkAuthentication`, {
        randkey: randKey,
        authkey: password,
        captcha: inputValue,
        capvisible: ui?.captcha?.visible
      })
      .then(async (res) => {

        if (res.data.CUST_COUNT > 0) {
          localStorage.setItem("token", JSON.stringify(res.data.token));
          const user = { ...res.data, randKey };
          let otp = res.data.OTP_ENABLE;

          if (otp) {
            setUserInfo({ ...res.data, randKey });
            setOtp(true);
          } else {
            setUser(user);
            localStorage.setItem("user", JSON.stringify(user));
            setSubmit("success");
          }

          const data = [
            {
              RAND_KEY: randKey,
              CUST_ID: ui.cust_id,
              CAMP_ID: ui.campName,
              STATUS: "LogIn Success.",
            },
          ];
          await eventLog(data);
        } else {
          if (res.data.success !== true) {
            console.log("RES:::::::", res.data.success)
            setWarning(res.data.message);
            setSubmit("fail");
            return;
          }
          setSubmit("fail");
          setTimeout(() => {
            setSubmit("");
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("Error from login:: ", err.response.data.message)
        setError(err.response.data.message ??
          err?.response?.data?.errors ??
          err?.message);

        const data = {
          sNo: "9999",
          taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/checkAuthentication/${randKey}/${password}`,
          errMessage: {
            Message: err.message,
          },
          errRemarks: "ERROR OCCURRED AT LOGIN PAGE.",
        };
        errLog(data);
      });
  };





  // const handleRefresh = (e) => {
  //   e.preventDefault(); // Prevent the default form submission behavior
  //   setCaptchaText(generateCaptcha());
  //   setInputValue('');
  // };

  // const checkCaptcha = () => {
  //   if (inputValue === captchaText) {
  //     // Captcha is correct, handle your logic here
  //     // alert('Captcha is correct!');
  //     return true
  //   } else {
  //     // Captcha is incorrect
  //     // alert('Captcha is incorrect. Please try again.');
  //     return false
  //   }
  // };



  // if (ui && ui.logInMsg) console.log("ui login msg", ui.logInMsg.style);
  return (
    <>
      {otp ? (
        <Otp
          setUser={setUser}
          randKey={randKey}
          userInfo={userInfo}
          ui={ui}
        />
      ) : (
        <div className="main-wrapper">
          <div
            className="page-wrapper full-page"
            style={{
              ...ui.style,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {loader === true && <Loader />}

            {!loader && !error && (
              <div className="page-content d-flex align-items-center justify-content-center">
                <div className="row w-100 mx-0 auth-page">
                  <div className="col-md-3 col-xl-3 mx-auto col-lg-3">
                    <div className="card" style={ui.cardStyle}>
                      <div className="row">
                        <div className="col-md-12 pl-md-0">
                          <div className="auth-form-wrapper px-5 py-4">
                            {warning && (
                              <div className="alert alert-warning">{warning}</div>
                            )}
                            <div
                              className="d-block mb-4 mx-auto d-flex justify-content-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                width: "120px",
                                height: "120px",
                                borderRadius: "50%",
                              }}
                            >
                              <img
                                src={ui.vendorLogo}
                                width="90px"
                                alt=""
                              ></img>
                            </div>
                            <h5
                              className="text font-weight-normal mb-4"
                              style={ui.logInMsg.style}
                              title={ui.logInMsg.tooltip}
                            >
                              {ui.logInMsg.value}
                            </h5>
                            {submit === "Fail" && (
                              <p className="alert alert-danger">
                                You credential does not found.
                              </p>
                            )}
                            <form className="forms-sample">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  style={ui.userName.style}
                                >
                                  {ui.userName.label}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  defaultValue={ui.userName.value}
                                  disabled={true}
                                  title={ui.userName.tooltip}
                                />
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="exampleInputPassword1"
                                  style={ui.password.style}
                                  title={ui.password.tooltip}
                                >
                                  {ui.password.label}
                                </label>
                                {ui.password.controlType === "date" ? (
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="exampleInputPassword1"
                                    autoComplete="current-password"
                                    placeholder="Date of Birth"
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputPassword1"
                                    autoComplete="current-password"
                                  />
                                )}
                              </div>
                              {ui.captcha.visible === 'true' && (
                                <div className="form-group">
                                  <div className="mb-2" style={{ ...ui.captcha.style, fontFamily: "Georgia, 'Times New Roman', Times, serif", fontSize: "20px", textAlign: "center", filter: "drop-shadow" }} >
                                    <span className="font-weight-bold" onClick={fetchCaptchaImage}>
                                      <svg xmlns="" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                      </svg>
                                    </span>
                                    <img src={captchaImage} alt="Captcha" />

                                  </div>
                                  <input
                                    className="form-control"
                                    ref={inputRef}
                                    required
                                    type="text"
                                    value={inputValue}
                                    placeholder={ui.captcha.captchaBox.label}
                                    title={ui.captcha.captchaBox.tooltip}
                                    onChange={(e) => setInputValue(e.target.value)}
                                  />
                                </div>
                              )}


                              <div className="mt-3">
                                <button
                                  className="btn btn-primary mr-2 mb-2 mb-md-0 text-white"
                                  onClick={login}
                                  style={ui.submitBtn.style}
                                  title={ui.submitBtn.tooltip}
                                >
                                  {submit === "loading" && (
                                    <i className="fa fa-spinner fa-spin"></i>
                                  )}{" "}
                                  {ui.submitBtn.value}
                                </button>
                              </div>


                              <a
                                href={`tel:${ui.help.tollfree}`}
                                className="d-block mt-3 text"
                                style={ui.help.style}
                                title={ui.help.tooltip}
                              >
                                {ui.help.value}
                              </a>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {error && <Error404 msg={error} />}
          </div>
        </div>
      )}

    </>
  );
}

export default Login;





















// import axios from "axios";
// import React, { useEffect, useRef, useState } from "react";
// import Error from "../Component/Error";
// import Loader from "../Component/Loader";
// import Otp from "../Component/Otp";
// import config from "../config";
// import { errLog, eventLog } from "../utils/function";

// function Login({ randKey, setUser }) {
//   const [loader, setLoader] = useState(true);
//   const [captchaImage, setCaptchaImage] = useState('');
//   const [error, setError] = useState("");
//   const [warning, setWarning] = useState("");
//   const [ui, setUi] = useState({});
//   const [submit, setSubmit] = useState("");
//   // const [captchaText, setCaptchaText] = useState(generateCaptcha());
//   const inputRef = useRef(null);
//   const [inputValue, setInputValue] = useState('');
//   const [otp, setOtp] = useState(false);
//   const [userInfo, setUserInfo] = useState(false);
//   useEffect(async () => {

//     axios
//       .get(`${config.API_URL}/ms/getUidesign/${randKey}`)
//       .then(async (res) => {
//         if (res.data.campId) {
//           // delete res.data.captcha
//           setUi(res.data);
//           setLoader(false);
//           const data = [
//             {
//               RAND_KEY: randKey,
//               CUST_ID: res.data.cust_id,
//               CAMP_ID: res.data.campName,
//               STATUS: "Link Clicked.",
//             },
//           ];
//           await eventLog(data);
//           const pageLoaded = [
//             {
//               RAND_KEY: randKey,
//               CUST_ID: res.data.cust_id,
//               CAMP_ID: res.data.campName,
//               STATUS: "LogIn Page Loaded.",
//             },
//           ];
//           await eventLog(pageLoaded);
//         } else {
//           setError("Something went wrong.");
//           setLoader(false);
//         }
//       })
//       .catch((err) => {
//         setError("You are not allowed for this action.");
//         setLoader(false);
//         const data = {
//           sNo: "9999",
//           taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/getUidesign/${randKey}`,
//           errMessage: {
//             Message: err.message,
//           },
//           errRemarks: `ERROR OCCURED WITH KEY${randKey}`,
//         };
//         errLog(data);
//       });
//     await fetchCaptchaImage()
//   }, []);


//   const fetchCaptchaImage = async () => {
//     try {
//       const response = await axios.get(`${config.API_URL}/ms/captcha/${randKey}`, { responseType: 'arraybuffer' });
//       const blob = new Blob([response.data], { type: 'image/png' });
//       const imageUrl = URL.createObjectURL(blob);
//       setCaptchaImage(imageUrl);
//     } catch (error) {
//       console.error('Error fetching CAPTCHA:', error);
//     }
//   };


//   const login = async (e) => {
//     e.preventDefault();
//     setSubmit("loading");


//     let password = document.getElementById("exampleInputPassword1").value;
//     if (ui.password.controlType === "date") {
//       const dateArr = password.split("-");
//       password = dateArr.join(""); // Join the date parts into a single string
//     }

//     // /:radkey/:authkey
//     // checkAuthentication

//     axios
//       .post(`${config.API_URL}/ms/checkAuthentication`, {
//         randkey: randKey,
//         authkey: password,
//         captcha: inputValue,
//       })
//       .then(async (res) => {
//         console.log("RES:::::::", res.data.success)
//         if (res.data.success !== false) {
//           setError(res.data.message);
//             return;
//         }
//         if (res.data.CUST_COUNT > 0) {
//           localStorage.setItem("token", JSON.stringify(res.data.token));
//           const user = { ...res.data, randKey };
//           let otp = res.data.OTP_ENABLE;

//           if (otp) {
//             setUserInfo({ ...res.data, randKey });
//             setOtp(true);
//           } else {
//             setUser(user);
//             localStorage.setItem("user", JSON.stringify(user));
//             setSubmit("success");
//           }

//           const data = [
//             {
//               RAND_KEY: randKey,
//               CUST_ID: ui.cust_id,
//               CAMP_ID: ui.campName,
//               STATUS: "LogIn Success.",
//             },
//           ];
//           await eventLog(data);
//         } else {
//           setSubmit("fail");
//           setTimeout(() => {
//             setSubmit("");
//           }, 2000);
//         }
//       })
//       .catch((err) => {
//         setWarning(err.response.data.message ??
//           err?.response?.data?.errors ??
//           err?.message
//         )
//         const data = {
//           sNo: "9999",
//           taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/checkAuthentication/${randKey}/${password}`,
//           errMessage: {
//             Message: err.message,
//           },
//           errRemarks: "ERROR OCCURRED AT LOGIN PAGE.",
//         };
//         errLog(data);
//       });
//   };





//   // const handleRefresh = (e) => {
//   //   e.preventDefault(); // Prevent the default form submission behavior
//   //   setCaptchaText(generateCaptcha());
//   //   setInputValue('');
//   // };

//   // const checkCaptcha = () => {
//   //   if (inputValue === captchaText) {
//   //     // Captcha is correct, handle your logic here
//   //     // alert('Captcha is correct!');
//   //     return true
//   //   } else {
//   //     // Captcha is incorrect
//   //     // alert('Captcha is incorrect. Please try again.');
//   //     return false
//   //   }
//   // };



//   // if (ui && ui.logInMsg) console.log("ui login msg", ui.logInMsg.style);
//   return (
//     <>
//       {otp ? (
//         <Otp
//           setUser={setUser}
//           randKey={randKey}
//           userInfo={userInfo}
//           ui={ui}
//         />
//       ) : (
//         <div className="main-wrapper">
//           <div
//             className="page-wrapper full-page"
//             style={{
//               ...ui.style,
//               backgroundSize: "cover",
//               backgroundRepeat: "no-repeat",
//             }}
//           >
//             {loader === true && <Loader />}

//             {!loader && !error && (
//               <div className="page-content d-flex align-items-center justify-content-center">
//                 <div className="row w-100 mx-0 auth-page">
//                   <div className="col-md-3 col-xl-3 mx-auto col-lg-3">
//                     <div className="card" style={ui.cardStyle}>
//                       <div className="row">
//                         <div className="col-md-12 pl-md-0">
//                           <div className="auth-form-wrapper px-5 py-4">
//                             {warning && (
//                               <div className="alert alert-warning">{warning}</div>
//                             )}
//                             <div
//                               className="d-block mb-4 mx-auto d-flex justify-content-center align-items-center"
//                               style={{
//                                 backgroundColor: "white",
//                                 width: "120px",
//                                 height: "120px",
//                                 borderRadius: "50%",
//                               }}
//                             >
//                               <img
//                                 src={ui.vendorLogo}
//                                 width="90px"
//                                 alt=""
//                               ></img>
//                             </div>
//                             <h5
//                               className="text font-weight-normal mb-4"
//                               style={ui.logInMsg.style}
//                               title={ui.logInMsg.tooltip}
//                             >
//                               {ui.logInMsg.value}
//                             </h5>
//                             {submit === "Fail" && (
//                               <p className="alert alert-danger">
//                                 You credential does not found.
//                               </p>
//                             )}
//                             <form className="forms-sample">
//                               <div className="form-group">
//                                 <label
//                                   htmlFor="exampleInputEmail1"
//                                   style={ui.userName.style}
//                                 >
//                                   {ui.userName.label}
//                                 </label>
//                                 <input
//                                   type="text"
//                                   className="form-control"
//                                   id="exampleInputEmail1"
//                                   defaultValue={ui.userName.value}
//                                   disabled={true}
//                                   title={ui.userName.tooltip}
//                                 />
//                               </div>
//                               <div className="form-group">
//                                 <label
//                                   htmlFor="exampleInputPassword1"
//                                   style={ui.password.style}
//                                   title={ui.password.tooltip}
//                                 >
//                                   {ui.password.label}
//                                 </label>
//                                 {ui.password.controlType === "date" ? (
//                                   <input
//                                     type="date"
//                                     className="form-control"
//                                     id="exampleInputPassword1"
//                                     autoComplete="current-password"
//                                     placeholder="Date of Birth"
//                                   />
//                                 ) : (
//                                   <input
//                                     type="text"
//                                     className="form-control"
//                                     id="exampleInputPassword1"
//                                     autoComplete="current-password"
//                                   />
//                                 )}
//                               </div>
//                               {ui.captcha.visible === 'true' && (
//                                 <div className="form-group">
//                                   <div className="mb-2" style={{ ...ui.captcha.style, fontFamily: "Georgia, 'Times New Roman', Times, serif", fontSize: "20px", textAlign: "center", filter: "drop-shadow" }} >
//                                     <span className="font-weight-bold" onClick={fetchCaptchaImage}>
//                                       <svg xmlns="" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
//                                         <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
//                                         <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
//                                       </svg>
//                                     </span>
//                                     <img src={captchaImage} alt="Captcha" />

//                                   </div>
//                                   <input
//                                     className="form-control"
//                                     ref={inputRef}
//                                     required
//                                     type="text"
//                                     value={inputValue}
//                                     placeholder={ui.captcha.captchaBox.label}
//                                     title={ui.captcha.captchaBox.tooltip}
//                                     onChange={(e) => setInputValue(e.target.value)}
//                                   />
//                                 </div>
//                               )}


//                               <div className="mt-3">
//                                 <button
//                                   className="btn btn-primary mr-2 mb-2 mb-md-0 text-white"
//                                   onClick={login}
//                                   style={ui.submitBtn.style}
//                                   title={ui.submitBtn.tooltip}
//                                 >
//                                   {submit === "loading" && (
//                                     <i className="fa fa-spinner fa-spin"></i>
//                                   )}{" "}
//                                   {ui.submitBtn.value}
//                                 </button>
//                               </div>


//                               <a
//                                 href={`tel:${ui.help.tollfree}`}
//                                 className="d-block mt-3 text"
//                                 style={ui.help.style}
//                                 title={ui.help.tooltip}
//                               >
//                                 {ui.help.value}
//                               </a>
//                             </form>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             )}
//             {error && <Error msg={error} />}
//           </div>
//         </div>
//       )}

//     </>
//   );
// }

// export default Login;
