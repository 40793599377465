import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Controller from "../../shared/controller/Controller";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampNameList } from "../../../redux/action/campaign";
import { useHistory } from "react-router";
import Style from "../../shared/controller/Style";
import config from "../../../config";
import LoginPreview from "../preview/LoginPreview";

const initValue = {
  ACTION: "ADD",
  CAMP_ID: "",
  CAMP_NAME: "",
  COMP_ID: 1,
  ACTIVE: 1,
  DB_OBJECT: "",
  LIST_ID: "null",
  HUNTGROUP: "null",
  DIALER_IP: "null",
  OTP_ENABLE: 0,
  API_DATA: {
    style: {
      backgroundImage: "",
    },
    componentName: "CUST_AUTH",
    apiEndpoint: "",
    campName: "",
    campId: "",
    vendorLogo: "",
    cardStyle: { backgroundColor: "" },
    logInMsg: {
      value: "",
      style: { color: "", fontSize: "" },
      tooltip: "",
      url: "",
    },
    userName: {
      label: "",
      style: { color: "", fontSize: "" },
      tooltip: "",
    },
    password: {
      label: "",
      controlType: "",
      style: { color: "" },
      tooltip: "",
    },
    captcha: {
      visible: false,
      style: {
        backgroundColor: "", color: "",
      },
      captchaBox: {
        label: "",
        style: { color: "" },
        tooltip: ""
      },
    },
    submitBtn: {
      value: "",
      style: { color: "", backgroundColor: "" },
      tooltip: "",
    },
    help: {
      value: "",
      link: "",
      tollfree: "",
      style: { color: "", fontSize: "" },
    },
  },
};

function Login() {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [data, setData] = useState({
    loaded: false,
    initialValues: initValue,
  });
  const { campNames } = useSelector((state) => state.campName);
  const { UserToken } = useSelector((state) => state.userInfo);
  const history = useHistory();
  const dispatch = useDispatch();
  let campOption = [];
  useEffect(() => {
    if (campNames && campNames.length === 0) {
      dispatch(fetchCampNameList(UserToken));
    }
  }, []);

  if (campNames && campNames.length > 0) {
    campOption = campNames.map((item) => {
      return {
        label: item.campname,
        value: item.campid,
      };
    });
  }

  const validationSchema = Yup.object({
    CAMP_ID: Yup.string(),
    API_DATA: Yup.object().shape({
      vendorLogo: Yup.string().required("Vendor logo is reqired."),
      logInMsg: Yup.object().shape({
        value: Yup.string().required("Login message is required."),
      }),
      userName: Yup.object().shape({
        label: Yup.string().required("Firt field label is required."),
      }),
      password: Yup.object().shape({
        label: Yup.string().required("Second field label is required."),
        controlType: Yup.string().required(
          "Second field control type is required."
        ),
      }),
      submitBtn: Yup.object().shape({
        value: Yup.string().required("Second field value is required."),
        tooltip: Yup.string().required("Submit button tooltip is required."),
      }),
      help: Yup.object().shape({
        value: Yup.string().required("Help value is required."),
        link: Yup.string().required("Help link is required."),
        tollfree: Yup.string().required("Help tollfree is required."),
      }),
    }),
  });

  const onSubmit = (values, actions) => {
    values.API_DATA.campName = values.CAMP_NAME;
    values.API_DATA.campId = values.CAMP_ID;
    axios
      .post(`${config.API_URL}/msdash/managecomponent/`, values, {
        headers: { Authorization: UserToken },
      })
      .then((res) => {
        setSuccess(res.data.message);
        window.scroll(0, 0);
        actions.setSubmitting(false);
      })
      .catch((err) => {
        setWarning(err.message);
        actions.setSubmitting(false);
        console.log(err);
      });
  };

  return (
    <Formik
      initialValues={data.initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { setFieldValue } = formik;
        const getCompData = (e) => {
          const campid = e.value;
          axios
            .get(`${config.API_URL}/ms/getcompdata/${campid}/1`)
            .then((result) => {
              result.data.captcha = result.data.captcha ? result.data.captcha : initValue.API_DATA.captcha
              setData({
                loaded: true,
                initialValues: {
                  ...data.initialValues,
                  ...result.data,
                  ACTION: "UPDATE",
                  CAMP_ID: campid,
                  CAMP_NAME: e.label,
                },
              });
              setFieldValue("API_DATA", result.data);
              setFieldValue("ACTION", "UPDATE");
              setFieldValue("CAMP_ID", campid);
              setFieldValue("CAMP_NAME", e.label);
            })
            .catch((err) => {
              formik.resetForm();
              setData({ ...data, loaded: true });
              setFieldValue("CAMP_ID", campid);
              setFieldValue("CAMP_NAME", e.label);
              console.log(err);
            });
        };
        return (
          <>
            <div className={data.loaded ? "col-md-8 mx-auto" : "col-md-12 mx-auto"}>
              <div className="card shadow-lg">
                <div className="card-body">
                  <h6 className="card-title">Add Styling of Login Page</h6>
                  <Form className="forms-sample">
                    {success && (
                      <div className="alert alert-success">{success}</div>
                    )}
                    {warning && (
                      <div className="alert alert-warning">{warning}</div>
                    )}
                    <Controller
                      control="multiSelect"
                      label="Campaign Name"
                      value={formik.values.campid}
                      name="campid"
                      isMulti={false}
                      formik={formik}
                      options={campOption}
                      onChange={getCompData}
                      wrapperClass={
                        formik.errors.campid && formik.touched.campid
                          ? "has-error"
                          : null
                      }
                    />
                    {data.loaded && (
                      <>
                        <Style
                          defaultValue={["API_DATA", "style"]}
                          name="API_DATA.style"
                          styleName="Component Background Style"
                          formik={formik}
                          wrapperClass={
                            formik.errors.campid && formik.touched.campid
                              ? "has-error"
                              : null
                          }
                        />
                        <Controller
                          control="input"
                          label="Api End Point"
                          value={formik.values.API_DATA.apiEndpoint}
                          name="API_DATA.apiEndpoint"
                          wrapperClass={
                            formik.errors.API_DATA &&
                              formik.errors.API_DATA.apiEndpoint &&
                              formik.touched.API_DATA &&
                              formik.touched.API_DATA.apiEndpoint
                              ? "has-error"
                              : null
                          }
                        />
                        <Controller
                          control="input"
                          label="Campaign Header Logo"
                          value={formik.values.API_DATA.vendorLogo}
                          name="API_DATA.vendorLogo"
                          wrapperClass={
                            formik.errors.API_DATA &&
                              formik.errors.API_DATA.vendorLogo &&
                              formik.touched.API_DATA &&
                              formik.touched.API_DATA.vendorLogo
                              ? "has-error"
                              : null
                          }
                        />
                        <Style
                          defaultValue={["API_DATA", "cardStyle"]}
                          name="API_DATA.cardStyle"
                          styleName="Card Background Style"
                          formik={formik}
                        />
                        <fieldset>
                          <legend>Welcome Login Message</legend>
                          <Controller
                            control="input"
                            label="Message"
                            value={formik.values.API_DATA.logInMsg.value}
                            name="API_DATA.logInMsg.value"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.logInMsg &&
                                formik.errors.API_DATA.logInMsg.value &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.logInMsg &&
                                formik.touched.API_DATA.logInMsg.value
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="input"
                            label="Tooltip"
                            value={formik.values.API_DATA.logInMsg.tooltip}
                            name="API_DATA.logInMsg.tooltip"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.logInMsg &&
                                formik.errors.API_DATA.logInMsg.tooltip &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.logInMsg &&
                                formik.touched.API_DATA.logInMsg.tooltip
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="input"
                            label="Url"
                            value={formik.values.API_DATA.logInMsg.url}
                            name="API_DATA.logInMsg.url"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.logInMsg &&
                                formik.errors.API_DATA.logInMsg.url &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.logInMsg &&
                                formik.touched.API_DATA.logInMsg.url
                                ? "has-error"
                                : null
                            }
                          />
                          <Style
                            defaultValue={["API_DATA", "logInMsg", "style"]}
                            name="API_DATA.logInMsg.style"
                            styleName="welcome Message Style"
                            formik={formik}
                          />
                        </fieldset>
                        <fieldset>
                          <legend>Authentication label</legend>
                          <Controller
                            control="input"
                            label="Label"
                            value={formik.values.API_DATA.userName.label}
                            name="API_DATA.userName.label"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.userName &&
                                formik.errors.API_DATA.userName.label &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.userName &&
                                formik.touched.API_DATA.userName.label
                                ? "has-error"
                                : null
                            }
                          />

                          <Controller
                            control="input"
                            label="Tooltip"
                            value={formik.values.API_DATA.userName.tooltip}
                            name="API_DATA.userName.tooltip"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.userName &&
                                formik.errors.API_DATA.userName.tooltip &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.userName &&
                                formik.touched.API_DATA.userName.tooltip
                                ? "has-error"
                                : null
                            }
                          />
                          <Style
                            defaultValue={["API_DATA", "userName", "style"]}
                            name="API_DATA.userName.style"
                            styleName="Style"
                            formik={formik}
                          />
                        </fieldset>
                        <fieldset>
                          <legend>Authentication Input</legend>
                          <Controller
                            control="input"
                            label="Label"
                            value={formik.values.API_DATA.password.label}
                            name="API_DATA.password.label"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.password &&
                                formik.errors.API_DATA.password.label &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.password &&
                                formik.touched.API_DATA.password.label
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="input"
                            label="Tooltip"
                            value={formik.values.API_DATA.password.tooltip}
                            name="API_DATA.password.tooltip"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.password &&
                                formik.errors.API_DATA.password.tooltip &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.password &&
                                formik.touched.API_DATA.password.tooltip
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="simpleSelect"
                            name="API_DATA.password.controlType"
                            label="Control Type"
                            options={[
                              { value: "input", label: "Input" },
                              { value: "date", label: "Date" },
                            ]}
                            value={formik.values.API_DATA.password.controlType}
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.password &&
                                formik.errors.API_DATA.password.controlType &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.password &&
                                formik.touched.API_DATA.password.controlType
                                ? "has-error"
                                : null
                            }
                          />
                          <Style
                            defaultValue={["API_DATA", "password", "style"]}
                            name="API_DATA.password.style"
                            styleName="Style"
                            formik={formik}
                          />
                        </fieldset>




                        {/* ------------------------------------------------------------------------------- */}



                        <fieldset>
                          <legend>Captcha</legend>
                          <Controller
                            control="input"
                            label="Label"
                            value={formik.values.API_DATA.captcha.captchaBox.label}
                            name="API_DATA.captcha.captchaBox.label"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.captcha &&
                                formik.errors.API_DATA.captcha.captchaBox &&
                                formik.errors.API_DATA.captcha.captchaBox.label &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.captcha &&
                                formik.errors.API_DATA.captcha.captchaBox &&
                                formik.errors.API_DATA.captcha.captchaBox.label &&
                                formik.touched.API_DATA.password.label
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="input"
                            label="Tooltip"
                            value={formik.values.API_DATA.captcha.captchaBox.tooltip}
                            name="API_DATA.captcha.captchaBox.tooltip"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.password &&
                                formik.errors.API_DATA.password.tooltip &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.password &&
                                formik.touched.API_DATA.password.tooltip
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="simpleSelect"
                            name="API_DATA.captcha.visible"
                            label="Control Type"
                            options={[
                              { value: true, label: "Visible" },
                              { value: false, label: "Invisible" },
                            ]}
                            value={formik.values.API_DATA.captcha.visible}
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.captcha &&
                                formik.errors.API_DATA.captcha.visible &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.captcha &&
                                formik.touched.API_DATA.captcha.visible
                                ? "has-error"
                                : null
                            }
                          />
                          <Style
                            defaultValue={["API_DATA", "captcha", "style"]}
                            name="API_DATA.captcha.style"
                            styleName="Style"
                            formik={formik}
                          />
                        </fieldset>














                        {/* --------------------------------------------------------------------------------------------------------------------- */}


                        <fieldset>
                          <legend>Button</legend>
                          <Controller
                            control="input"
                            label="Button Value"
                            value={formik.values.API_DATA.submitBtn.value}
                            name="API_DATA.submitBtn.value"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.submitBtn &&
                                formik.errors.API_DATA.submitBtn.value &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.submitBtn &&
                                formik.touched.API_DATA.submitBtn.value
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="input"
                            label="Tooltip"
                            value={formik.values.API_DATA.submitBtn.tooltip}
                            name="API_DATA.submitBtn.tooltip"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.submitBtn &&
                                formik.errors.API_DATA.submitBtn.tooltip &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.submitBtn &&
                                formik.touched.API_DATA.submitBtn.tooltip
                                ? "has-error"
                                : null
                            }
                          />
                          <Style
                            defaultValue={["API_DATA", "submitBtn", "style"]}
                            name="API_DATA.submitBtn.style"
                            styleName="Style"
                            formik={formik}
                          />
                        </fieldset>
                        <fieldset>
                          <legend>Help</legend>
                          <Controller
                            control="input"
                            label="Text"
                            value={formik.values.API_DATA.help.value}
                            name="API_DATA.help.value"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.help &&
                                formik.errors.API_DATA.help.value &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.help &&
                                formik.touched.API_DATA.help.value
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="input"
                            label="Link"
                            value={formik.values.API_DATA.help.link}
                            name="API_DATA.help.link"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.help &&
                                formik.errors.API_DATA.help.link &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.help &&
                                formik.touched.API_DATA.help.link
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="input"
                            label="Toll Free Number"
                            value={formik.values.API_DATA.help.tollfree}
                            name="API_DATA.help.tollfree"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.help &&
                                formik.errors.API_DATA.help.tollfree &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.help &&
                                formik.touched.API_DATA.help.tollfree
                                ? "has-error"
                                : null
                            }
                          />
                          <Style
                            defaultValue={["API_DATA", "help", "style"]}
                            name="API_DATA.help.style"
                            styleName="Style"
                            formik={formik}
                          />
                        </fieldset>

                        <div className="box-footer text-right">
                          <button
                            type="button"
                            className="btn btn-rounded btn-warning btn-outline mr-1"
                            onClick={() => history.goBack()}
                          >
                            <i className="fa fa-trash-o"></i> Cancel
                          </button>

                          <button
                            type="submit"
                            className="btn btn-rounded btn-primary btn-outline"
                          >
                            {formik.isSubmitting ? (
                              <i className="fa fa-spin fa-refresh"></i>
                            ) : (
                              <i className="fa fa-fw fa-save"></i>
                            )}{" "}
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>
            {data.loaded && <div className="col-md-4" style={{ display: "block" }}>
              <div className="card position-fixed w-25 shadow-lg p-3">
                <LoginPreview ui={formik.values.API_DATA} />
              </div>
            </div>}
          </>
        );
      }}
    </Formik>
  );
}

export default Login;
