import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Controller from "../dashboard/shared/controller/Controller";
import axios from "axios";
import config from "../config";
import { useParams } from "react-router-dom";
import { useState } from "react";

function Upload() {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const { randstr } = useParams();
  const initialValues = {
    doc_type: "",
    file: "",
    randstr: randstr,
  };
  const validationSchema = Yup.object({
    doc_type: Yup.string().required("Document type is required."),
  });
  const onSubmit = (values) => {
    // console.log(values);
    const data = new FormData();
    data.append("file", values.file);
    axios
      .post(
        `${config.API_URL}/ms/uploadfile/${values.randstr}/${values.doc_type}`,
        data
      )
      .then((res) => {
          setSuccess(res.data.message);
          setTimeout(()=>{setSuccess('')},5000);
      })
      .catch((err) => {
        setError(err.message)
        setTimeout(()=>{setError('')},5000)
        console.log("image", err);
      });
  };

  const docArr = [
    { label: "Photo", value: "photo" },
    { label: "Aadhar Card", value: "aadhar-card" },
    { label: "PAN Card", value: "pan-card" },
    { label: "Bond Paper", value: "bond-paper" },
    { label: "Address Proof", value: "address-proff" },
  ];

  return (
    <div className="main-wrapper">
      <div className="page-wrapper ml-0" style={{ backgroundColor: "#04203c" }}>
        <div className="container my-auto">
          <div className="card shadow-lg">
            <div className="card-body">
              <div className="row d-flex align-items-center">
                <div className="col-12 col-sm-6 col-lg-6 text-center">
                  <img
                    src="https://cdnlib.a10s.in/cdndata/77755e565ef7ddbff2546231cd8732bf.png"
                    className="w-100"
                  ></img>
                </div>
                <div className="col-12 col-sm-6 col-lg-6">
                  <h3 className="mb-5">UPLOAD DOCUMENTS</h3>
                  {
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {(formik) => {
                        const imageChange = (e) => {
                          const file = e.target.files[0];
                          formik.setFieldValue("file", file);
                          // console.log(file);
                        };
                        return (
                          <Form encType="multipart/form-data">
                            {success && <div className="alert alert-success">{success}</div>}
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="form-group">
                              <Controller
                                control="simpleSelect"
                                label="Document Type"
                                value={formik.values.doc_type}
                                name="doc_type"
                                options={docArr}
                                wrapperClass={
                                  formik.errors.doc_type &&
                                  formik.touched.doc_type
                                    ? "has-error"
                                    : null
                                }
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="file"
                                onChange={imageChange}
                                id="myDropify"
                                class="border"
                              />
                              {/* <label>Upload File here</label>
                              <input
                                type="file"
                                id="myDropify"
                                class="border"
                              /> */}
                            </div>
                            <div className="form-group">
                              <button className="btn btn-primary">
                                Upload
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Upload;
