import { CAMPNAME_LIST_FAIL, CAMPNAME_LIST_REQUEST, CAMPNAME_LIST_SUCCESS, CAMP_LIST_FAIL, CAMP_LIST_REQUEST, CAMP_LIST_SUCCESS } from "../constant/campaign";


const campaignListReducer=(state={camps:[]},action)=>{
    switch(action.type){
        case CAMP_LIST_REQUEST:
            return {loading:true};
        case CAMP_LIST_SUCCESS:
            return {loading:false, camps:action.payload};
        case CAMP_LIST_FAIL:
            return {loading:false, error:action.payload};
        default:
            return state;
    }
}
const campNameListReducer=(state={campNames:[]},action)=>{
    switch(action.type){
        case CAMPNAME_LIST_REQUEST:
            return {loading:true};
        case CAMPNAME_LIST_SUCCESS:
            return {loading:false, campNames:action.payload};
        case CAMPNAME_LIST_FAIL:
            return {loading:false, error:action.payload};
        default:
            return state;
    }
}

export {campaignListReducer,campNameListReducer}