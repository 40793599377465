import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "../../dashboard/shared/controller/TextError";

function CheckBox(props) {
  const { label, options, name,type, value, ...rest } = props;
  return (
    <div className="form-group">
      {label && <label className="d-block font-weight-bold">{label}</label>}
      {options.map((item,index) => (
            <div className="form-check form-check-inline">
            <Field 
              type="checkbox"
              className="form-check-input"
              name={name}
              id={name+"-"+index}
              {...rest}
              value={item.value}
            />
            <label className="form-check-label m-auto" htmlFor={name+"-"+index}>
              {item.label}
            </label>
          </div>
        ))}
        <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default CheckBox;
