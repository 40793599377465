import Axios from 'axios';
import config from '../../config';
import { LOGOUT, SAVE_USER_INFO, USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_SUCCESS } from '../constant/user';


const fetchUserList=(UserToken)=>async(dispatch)=>{
    try{
        dispatch({type:USER_LIST_REQUEST});
        const data=await Axios.get(`${config.API_URL}/msdash/getuserall/`,{headers:{Authorization:UserToken}});
        const users=Object.values(data.data);
        dispatch({type:USER_LIST_SUCCESS, payload:users});
    }catch(err){
        dispatch({type:USER_LIST_FAIL, payload:err});
    }
}
const saveUserInfo=(user)=>async(dispatch)=>{
    
    dispatch({type:SAVE_USER_INFO, payload:user});
   
}
const logOut=()=>async(dispatch)=>{
    localStorage.removeItem('token')
    dispatch({type:LOGOUT})
}


export {fetchUserList,saveUserInfo,logOut}