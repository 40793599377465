import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "../../dashboard/shared/controller/TextError";

function Radio(props) {
  const { label, options,type, name, wrapperClass, ...rest } = props;
  return (
    <>
      <label className="d-block font-weight-bold">
      {label}
      </label>
        {options.map((item,index) => (
            <div className="form-check form-check-inline">
            <Field 
              className="form-check-input"
              type="radio"
              name={name}
              id={name+"-"+index}
              {...rest}
              value={item.value}
            />
            <label className="form-check-label m-auto" htmlFor={name+"-"+index}>
              {item.label}
            </label>
          </div>
        ))}
      <ErrorMessage name={name} component={TextError} />

      
    </>
  );
}

export default Radio;
