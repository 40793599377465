import React from "react";
import { FieldArray} from "formik";

function InputArray(props) {
  const { legend, name,defaultValue, arrayField, formik, buttonText,inputType,isStringType, ...rest } = props;
  let value=''
  switch (defaultValue && defaultValue.length){
    case 1:
      value=formik.values[defaultValue[0]];
      break;
    case 2:
      value=formik.values[defaultValue[0]][defaultValue[1]];
      break;
    case 3:
      value=formik.values[defaultValue[0]][defaultValue[1]][defaultValue[2]];
      break;
    case 4:
      value=formik.values[defaultValue[0]][defaultValue[1]][defaultValue[2]][defaultValue[3]];
      break;
    case 5:
      value=formik.values[defaultValue[0]][defaultValue[1]][defaultValue[2]][defaultValue[3]][defaultValue[4]];
      break;
    
  }
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => {
        return (
          <>
            {value.length > 0 ? (
              <fieldset>
                <legend> {legend}</legend>

                {value.map((icon, index) => (
                  <div key={index}>
                    {arrayField.map(({Control, ...item}) => (
                      <Control
                        name={isStringType?`${name}[${index}]`:`${name}[${index}][${item.name}]`}
                        label={item.label}
                        placeholder={item.label}
                        value={isStringType?value[index]:value[index][item.name]}
                      />
                    ))}
                    <div className="box-footer text-right">
                      <button
                        type="button"
                        className="btn btn-rounded btn-secondary btn-outline mr-1"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <i className="ti-cut"></i> Remove
                      </button>
                      <button
                        type="button"
                        className="btn btn-rounded btn-dark btn-outline"
                        onClick={() => arrayHelpers.insert(index + 1, "")}
                      ><i className="ti-plus"></i>Add
                      </button>
                    </div>
                    <hr></hr>
                  </div>
                ))}
              </fieldset>
            ) : (
              <button
                type="button"
                className="btn btn-rounded btn-dark btn-outline mr-1 mb-5"
                onClick={() => arrayHelpers.push("")}
              >
               <i className="ti-plus"></i> {buttonText}
              </button>
            )}
          </>
        );
      }}
    />
  );
}

export default InputArray;
