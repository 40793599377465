import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { hash } from "bcryptjs";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Controller from "../shared/controller/Controller";
import config from "../../config";

function ChangePassword() {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { userid, UserToken } = useSelector((state) => state.userInfo);

  const initialValues = {
    oldpwd: "",
    userpwd: "",
    confirmpwd: "",
    pwdtype: "CHANGE_PWD",
  };

  const validationSchema = Yup.object({
    oldpwd: Yup.string()
      .required("Old Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    userpwd: Yup.string()
      .required("Old Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmpwd: Yup.string()
      .required("Old Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .oneOf([Yup.ref("userpwd"), null], "Passwords must match"),
  });
  const onSubmit = async (values, { resetForm }) => {
    const { confirmpwd, ...data } = values;
    data.userid = userid;
    data.userpwd = await hash(data.userpwd, 10);
    try {
      axios
        .post(`${config.API_URL}/msdash/managepassword/`, data, {
          headers: { Authorization: UserToken },
        })
        .then((res) => {
          setSuccess(res.data.message);
          setTimeout(() => {
            setSuccess("");
          }, 4000);
        })
        .catch((err) => {
          console.log(err);
          setWarning(err.message);
          setTimeout(() => {
            setWarning("");
          }, 4000);
        });
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setTimeout(() => {
        setWarning("");
      }, 4000);
    }
  };

  return (
    <div className="col-md-8 mx-auto">
      <div className="card">
        <div className="card-body">
          <div className="card-header">
            <h6 className="card-title">Change Password</h6>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form className="forms-sample">
                  {success && (
                    <div className="alert alert-success">{success}</div>
                  )}
                  {warning && (
                    <div className="alert alert-warning">{warning}</div>
                  )}
                  <Controller
                    control="input"
                    type="text"
                    name="oldpwd"
                    label="Old Password"
                    placeholder="Enter Old Password"
                    value={formik.values.oldpwd}
                    wrapperClass={
                      formik.errors.oldpwd && formik.touched.oldpwd
                        ? "has-error"
                        : null
                    }
                  />
                  <Controller
                    control="input"
                    type="text"
                    name="userpwd"
                    label="Password"
                    placeholder="Enter Password"
                    value={formik.values.userpwd}
                    wrapperClass={
                      formik.errors.userpwd && formik.touched.userpwd
                        ? "has-error"
                        : null
                    }
                  />
                  <Controller
                    control="input"
                    type="password"
                    name="confirmpwd"
                    label="Confirm Password"
                    placeholder="Enter Confirm Password"
                    value={formik.values.confirmpwd}
                    wrapperClass={
                      formik.errors.confirmpwd && formik.touched.confirmpwd
                        ? "has-error"
                        : null
                    }
                  />
                  <div className="card-footer text-right">
                    <button
                      type="button"
                      className="btn btn-rounded btn-warning btn-outline mr-1"
                      onClick={() => history.goBack()}
                    >
                      <i className="fa fa-trash-o"></i> Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-rounded btn-primary btn-outline"
                    >
                      {formik.isSubmitting ? (
                        <i className="fa fa-spin fa-refresh"></i>
                      ) : (
                        <i className="fa fa-fw fa-save"></i>
                      )}{" "}
                      Save
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
