import React, { useEffect, useState } from "react";
import Video from "./Component/Video";

function TabPreview({ ui }) {
  const [currentTab, setCurrentTab] = useState({});
  useEffect(() => {
    setCurrentTab(ui.tabs[0]);
  }, [ui]);

  // console.log("UI", currentTab);
  return (
    <>
      {currentTab && currentTab.navText && (
        <>
          <div className="card" style={{ width: "100%" }}>
            <div className="card-body" style={{ padding: "0.5rem" }}>
              <ul className="nav">
                {ui.tabs.length > 0 &&
                  ui.tabs.map((item, index) => (
                    <li
                      key={`navTab${index}`}
                      className="nav-item"
                      onClick={() => setCurrentTab(item)}
                    >
                      <span className="nav-link text-center">
                        <img
                          src={item.icon}
                          className="mb-2"
                          alt=""
                          height="34px"
                          width="34px"
                          style={{
                            border: "1px solid black",
                            borderRadius: "50%",
                            padding: "4px",
                            display: "inline",
                            margin: "3px",
                          }}
                        ></img>
                        <h6 style={{ display: "inline" }}>{item.navText}</h6>
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {currentTab &&
          currentTab.control &&
          currentTab.control === "policy" ? (
            <div className="card mt-4">
              <div className="card-header">
                <h4>{currentTab.navText}</h4>
              </div>
              <div className="card-body">{currentTab.navText} conten will be here...</div>
            </div>
          ) : currentTab &&
            currentTab.control &&
            currentTab.control === "why-renew" ? (
            <div className="card mt-4">
              <div className="card-header">
                <h4>{currentTab.navText}</h4>
              </div>
              <div className="card-body">{currentTab.navText} conten will be here...</div>
            </div>
          ) : currentTab &&
            currentTab.control &&
            currentTab.control === "video" ? (
            <Video data={currentTab} />
          ) : (
            <div className="card mt-4">
              <div className="card-header">
                <h4>{currentTab.navText}</h4>
              </div>
              <div className="card-body">
                <p>{currentTab.content}</p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default TabPreview;
