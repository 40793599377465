import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../config";
import { errLog, eventLog } from "../utils/function";
import Error from "./Error";

function Otp({randKey,setUser,ui,userInfo}) {
  const [error,setError]=useState("")
  const [message,setMessage]=useState("")
  const [submitting,setSubmitting]=useState(false)
  const token=JSON.parse(localStorage.getItem('token'))
  console.log(ui)
  useEffect(()=>{
    axios
      .post(`${config.API_URL}/ms/otp`,
      {
        action:"SET_OTP",
        radkey:randKey.slice(2),
        camp:ui.camp_name,
        otp:"",
	      senderid:"ATSSER",
	      smstext:"opt message"
      },{headers:{Authorization:`Bearer ${token}`}})
      .then((res) => {
        const data = [
          {
            RAND_KEY: randKey,
            CUST_ID: ui.cust_id,
            CAMP_ID: ui.campName,
            STATUS: "Otp Component Loaded.",
          },
        ];
        eventLog(data);
      })
      .catch((err) => {
        const data = {
          sNo: "9999",
          taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/otp/`,
          errMessage: {
            Message: err.message,
          },
          errRemarks: "ERROR OCCURED WHILE LOADING OTP COMPONENT",
        };
        errLog(data);
        setError(()=>err.response && err.response.data ? err.response.data.message : err.message)

      });
  },[])
  const submit=(e)=>{
    e.preventDefault();
    setSubmitting(true);
    const otp=document.getElementById('exampleInputOtp').value;
    axios
      .post(`${config.API_URL}/ms/otp`,
      {
        action:"VERIFY_OTP",
        radkey:randKey.slice(2),
        camp:ui.camp_name,
        otp:otp,
      },{headers:{Authorization:`Bearer ${token}`}})
      .then((res) => {
        setUser(userInfo);
        localStorage.setItem("user", JSON.stringify(userInfo));
        setSubmitting(false)
      })
      .catch((err) => {
        setMessage(err.response.data.message)
        setSubmitting(false)
        setTimeout(()=>{
          setMessage('')
        },3000)
      });
  }
  return (
    <>
    {error ? <Error msg={error}/>:
    <div className="main-wrapper">
      <div
        className="page-wrapper full-page"
        style={{
          backgroundImage: "url('Flat-Mountains.svg')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
          <div className="page-content d-flex align-items-center justify-content-center">
            <div className="row w-100 mx-0 auth-page">
              <div className="col-md-3 col-xl-3 mx-auto col-lg-3">
                <div
                  className="card"
                  style={{ backgroundColor: "rgba(69, 0, 87, 0.8)" }}
                >
                  <div className="row">
                    <div className="col-md-12 pl-md-0">
                      <div className="auth-form-wrapper px-5 py-4">
                        <div
                          className="d-block mb-4 mx-auto d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "white",
                            width: "120px",
                            height: "120px",
                            borderRadius: "50%",
                          }}
                        >
                          <img src="images/icici.png" width="90px" alt=""></img>
                        </div>
                        <h5 className="font-weight-normal mb-4 text-white" style={{color:"white"}}>
                          We have sent an OTP. Please Insert here.
                        </h5>
                        {message && (
                              <p className="alert alert-danger">
                                {message}
                              </p>
                            )}
                        <form className="forms-sample" onSubmit={submit}>
                          <div className="form-group">
                            
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputOtp"
                              placeholder="Enter OTP here..."
                            />
                          </div>
                          <div className="mt-3" onClick={submit}>
                            <span
                              className="btn btn-primary mr-2 mb-2 mb-md-0 text-white"
                            >
                            {submitting && (
                                    <i className="fa fa-spinner fa-spin"></i>
                                  )}{" "}  Login
                            </span>
                          </div>
                          <a
                            href="tel:+919971081358"
                            className="d-block mt-3" style={{color:"white"}}
                          >
                            Need Help?
                          </a>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
      </div>
    </div>}
    </>
  );
}

export default Otp;