import {combineReducers, createStore, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk';
import { campaignListReducer, campNameListReducer } from './reducer/campaign';
import { userInfoReducer, userListReducer } from './reducer/user';

const initialState={};
const reducer=combineReducers({
    userList:userListReducer,
    campList:campaignListReducer,
    campName:campNameListReducer,
    userInfo:userInfoReducer,
})

const composeEnhancer= process.env.NODE_ENV=='development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||compose;
const store=createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;