import React, {  useState } from "react";

function DialerPreview({ui}) {
    const [date, setDate] = useState({ err: false, value: "" });
    const [formatedDate, setFormatedDate] = useState("");
    const [time, setTime] = useState({ err: false, value: "" });
    const [submit, setSubmit] = useState({ loading: "", message: "" });
    const [btnMsg, setBtnMsg] = useState("");
    const [scheduleShow, setScheduleShow] = useState(true);
    const [cancleError, setCancleError] = useState("");
    const [callPopup, setcallPopup] = useState(false);
    const [currWindow,setCurrwindow]=useState("")
    

    function popup() {
      document.getElementById("tb-cont").style.left = !callPopup ? "4%" : "102%";
      setcallPopup(!callPopup);
    }
    const payNow = (url) => {
      
    };
    const callMeNow = (msg) => {
     
    };
    const scheduleSubmit = () => {
     
    };
    const cancleSchedule = () => {
      
    };
    if (submit.message) {
      
    }
    return (
        <div>
          { !currWindow && <><div
            className="tab-content shadow-sm"
            id="tb-cont"
            // style={{ zIndex: "999" }}
          >
            <div
              className="card pb-0 tab-pane active"
              // style={{
              //   borderBottomLeftRadius: "0",
              //   borderBottomRightRadius: "0",
              // }}
              id="call"
              style={{ ...ui.dialer.upSection.style }}
            >
              <div className="card-body text-center">
                <h5 className="card-title"> 
                  {ui.dialer.upSection.content.label}
                </h5>
                <p className="card-text mb-3">
                  {ui.dialer.upSection.content.value}
                </p>
                <button
                  className="btn btn-success"
                  style={ui.dialer.upSection.action.style}
                  onClick={() => payNow(ui.dialer.upSection.action.link)}
                  title={ui.dialer.upSection.action.tooltip}
                >
                  <i className="fa fa-money"></i>{" "}
                  {ui.dialer.upSection.action.value}
                </button>
              </div>
              <div
                className="jumbotron text-center"
                style={{
                  ...ui.dialer.downSection.style,
                  marginBottom: "initial",
                }}
              >
                {!scheduleShow ? (
                  <>
                    <h5>
                      {cancleError
                        ? cancleError
                        : `You have scheduled a call for ${formatedDate} at ${time.value}`}
                    </h5>
                    <br />
                    <button
                      className="btn btn-primary mb-2 mt-2"
                      type="button"
                      style={ui.dialer.downSection.btn2.style}
                      title="Click here to cancle your Schedule"
                      onClick={cancleSchedule}
                    >
                      <i className="fa fa-clock-o"></i> Cancel Schedule
                    </button>
                  </>
                ) : (
                  <>
                    
                    <button
                      className="btn btn-primary mb-2 mt-2"
                      
                      style={ui.dialer.downSection.btn1.style}
                      title={ui.dialer.downSection.btn1.tooltip}
                      onClick={() =>{
                        callMeNow(ui.dialer.downSection.btn1.popUp.content)
                        setCurrwindow(1)
                      }
                      }
                    >
                      <i className="fa fa-phone"></i>{" "}
                      {ui.dialer.downSection.btn1.value}
                    </button>
                    <br></br>
                    <h5>OR</h5>
                    <button
                      className="btn btn-primary mb-2 mt-2"
                      type="button"
                      style={ui.dialer.downSection.btn2.style}
                      title={ui.dialer.downSection.btn2.tooltip}
                      onClick={()=>setCurrwindow(2)}
                    >
                      <i className="fa fa-clock-o"></i>{" "}
                      {ui.dialer.downSection.btn2.value}
                    </button>
                    
                    <h5 style={{marginTop:"3rem",marginBottom:"-3rem"}}>{ui.dialer.downSection.helpText}</h5>
                  </>
                )}
              </div>
            </div>

            <div
              className="card pb-0 tab-pane fade"
              style={{
                ...ui.dialer.upSection.style,
                borderBottomLeftRadius: "0",
                borderBottomRightRadius: "0",
              }}
              id="whatsappTab"
            >
              <div className="card-body text-center">
                <h5 className="card-title">
                  {ui.dialer.upSection.content.label}
                </h5>
                <p className="card-text mb-3">
                  {ui.dialer.upSection.content.value}
                </p>
              </div>

              <div
                className="jumbotron text-white "
                style={{
                  ...ui.dialer.downSection.style,
                  marginBottom: "initial",
                }}
              >
                <form ondSubmit="return false">
                  <div className="form-group">
                    <label
                      title={ui.dialer.whatsAppLabel.tooltip}
                      style={ui.dialer.whatsAppLabel.style}
                    >
                      {ui.dialer.whatsAppLabel.text}
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Type a message and click on the send button"
                    ></textarea>
                  </div>

                  <div className="form-group text-right">
                    <button
                      className="btn btn-danger"
                      title={ui.dialer.whatsAppSendButton.tooltip}
                      style={ui.dialer.whatsAppSendButton.style}
                    >
                      {ui.dialer.whatsAppSendButton.text}{" "}
                      <img src="/assets/icons/arrow-right.svg"></img>
                    </button>
                  </div>
                </form>
                <h5 style={{marginTop:"3rem",marginBottom:"-3rem"}}>{ui.dialer.downSection.helpText}</h5>
              </div>
            </div>

            <div
              className="card pb-0 tab-pane fade"
              style={{
                ...ui.dialer.upSection.style,  
                borderBottomLeftRadius: "0",
                borderBottomRightRadius: "0",
              }}
              id="emailTab"
            >
              <div className="card-body text-center">
                <h5 className="card-title">
                  {ui.dialer.upSection.content.label}
                </h5>
                <p className="card-text mb-3">
                  {ui.dialer.upSection.content.value}
                </p>
              </div>
              <div
                className="jumbotron text-white"
                style={{
                  ...ui.dialer.downSection.style,
                  marginBottom: "initial",
                }}
              >
                <form ondSubmit="return false">
                  <div className="form-group">
                  <label
                      title={ui.dialer.emailLabel.tooltip}
                      style={ui.dialer.emailLabel.style}
                    >
                      {ui.dialer.emailLabel.text}
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Type a message and click on the send button"
                    ></textarea>
                  </div>

                  <div className="form-group text-right">
                  <button
                      className="btn btn-danger"
                      title={ui.dialer.emailSendButton.tooltip}
                      style={ui.dialer.emailSendButton.style}
                    >
                      {ui.dialer.emailSendButton.text}{" "}
                      <img src="/assets/icons/arrow-right.svg"></img>
                    </button>
                  </div>
                </form>
                <h5 style={{marginTop:"3rem",marginBottom:"-3rem"}}>{ui.dialer.downSection.helpText}</h5>
              </div>
            </div>
          </div>

          <ul
            className={ "nav nav-tabs callDesktop"}
            id="popupUl"
            style={{
              display:ui.dialer.whatsappApi ||
              ui.dialer.emailApi?"flex":"none",
              justifyContent: "space-around",
              width: "100%",
              padding: "0.5rem 0",
              background: "#fff",
              ...ui.dialer.footerStyle
            }}
          >
           
                <li onClick={popup} className="active">
                  <a data-toggle="tab" href="#call">
                    <img src={ui.dialer.footer_call_icon}></img>
                  </a>
                </li>
                {ui.dialer.whatsappApi && (
                  <li onClick={popup}>
                    <a data-toggle="tab" href="#whatsappTab">
                      <img src={ui.dialer.footer_whatsapp_icon}></img>
                    </a>
                  </li>
                )}
                {ui.dialer.emailApi && (
                  <li onClick={popup}>
                    <a data-toggle="tab" href="#emailTab">
                      <img src={ui.dialer.footer_email_icon}></img>
                    </a>
                  </li>
                )}
          </ul>

               </> }

          
            {/* Button trigger modal */}
            {/* Modal for Call me now*/}
            {currWindow===1 && <div
              
            >
               <div className="modal-shadow"
            style={{
              position:"absolute",
              top:0,
              left:0,
              background:"#00000017",
              width:"100%",
              height:"100%"
            }}
            ></div>
              <div className="modal-dialog" role="document" style={{
              boxShadow:"0px 0px 15px #00000038"
            }}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {ui.dialer.downSection.btn1.value}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={()=>setCurrwindow("")}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body text-center">
                    <p>{ui.dialer.downSection.btn1.popUp.content}</p>
                    {/* <p>{ui.dialer.downSection .btn1.popUp.content}</p> */}
                    <h5 className="m-4">
                      {" "}
                      {ui.dialer.downSection.btn1.popUp.number}
                    </h5>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={()=>setCurrwindow("")}
                      style={ui.dialer.downSection.btn1.popUp.closeBtn.style}
                      title={ui.dialer.downSection.btn1.popUp.closeBtn.tooltip}
                    >
                      {ui.dialer.downSection.btn1.popUp.closeBtn.value}
                    </button>
                  </div>
                  <div className="modal-footer">
                    <small>
                      {ui.dialer.downSection.btn1.popUp.bottomContent}
                    </small>
                  </div>
                </div>
              </div>
            </div>}
         

          {/* Modal for Call me Later */}

         {currWindow===2 && <div
            
          >
               <div className="modal-shadow"
            style={{
              position:"absolute",
              top:0,
              left:0,
              background:"#00000017",
              width:"100%",
              height:"100%"
            }}
            ></div>
            <div className="modal-dialog" role="document" style={{
              boxShadow:"0px 0px 15px #00000038"
            }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {ui.dialer.downSection.btn2.value}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={()=>setCurrwindow("")}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <p className="text-center">
                    {ui.dialer.downSection.btn2.popUp.content}
                  </p>
                  {submit.message && (
                    <div className="alert alert-warning">{submit.message}</div>
                  )}
                  <div className="row mt-4">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Enter Date"
                          id="schedule-date"
                          style={date.err ? { borderColor: "red" } : null}
                          value={date.value}
                          
                        />
                        {date.err && (
                          <span style={{ color: "red" }}>
                            Date is required.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="control-label">Time</label>
                        <input
                          type="time"
                          className="form-control"
                          placeholder="Enter Time"
                          id="schedule-time"
                          style={time.err ? { borderColor: "red" } : null}
                          value={time.value}
                          onChange={(e) =>
                            setTime({ err: false, value: e.target.value })
                          }
                        />
                        {time.err && (
                          <span style={{ color: "red" }}>
                            Time is required.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="m-4">
                    {ui.dialer.downSection.btn1.popUp.number}
                  </p>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={ui.dialer.downSection.btn2.popUp.scheduleBtn.style}
                    title={ui.dialer.downSection.btn2.popUp.scheduleBtn.tooltip}
                    onClick={scheduleSubmit}
                  >
                    {submit.loading ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}{" "}
                    {ui.dialer.downSection.btn2.popUp.scheduleBtn.value}
                  </button>
                </div>
                <div className="modal-footer">
                  <small>
                    {ui.dialer.downSection.btn2.popUp.bottomContent}
                  </small>
                </div>
              </div>
            </div>
          </div>}
        </div>
    )
}

export default DialerPreview
