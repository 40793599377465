import React from "react";

function Footer({ ui }) {
  return (
    <footer
      className="footer d-flex flex-column flex-md-row align-items-center justify-content-center"
      style={ui.style}
    >
      <p className="text-center text-md-left" style={ui.text.style}>{ui.text.value}</p>
    </footer>
  );
}

export default Footer;
