import React, { useEffect } from "react";

function LoginPreview({ ui }) {
  // const campId = props.match.params.campid;
  // const compData = JSON.parse(localStorage.getItem("loginComp"));
  const submit = (e) => {
    e.preventDefault();
  };
  const login = () => { };
  // console.log("UI",ui.cardStyle)
  // useEffect(() => {
  //   document.querySelector("nav.sidebar").style.display = "none";
  //   document.querySelector("nav.navbar").style.display = "none";
  //   const pageWrapper=document.querySelector("div.page-wrapper")
  //   pageWrapper.style.width = "100%";
  //   pageWrapper.style.marginLeft = "0px";
  //   pageWrapper.classList.add("full-page");
  //   pageWrapper.style.backgroundImage=compData.API_DATA.style.backgroundImage;
  //   pageWrapper.style.backgroundSize="cover"
  //   pageWrapper.style.backgroundRepeat="no-repeat"
  // }, []);
  // if (compData.CAMP_ID === campId) {
  //   const ui = compData.API_DATA;
  return (
    // <div className="page-content d-flex align-items-center justify-content-center">
    //   <div className="row w-100 mx-0 auth-page">
    //     <div className="col-md-3 col-xl-3 mx-auto col-lg-3">
    <div
      className="page-wrapper full-page"
      style={{
        ...ui.style,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "80vh"
      }}
    >
      <div className="card" style={{ ...ui.cardStyle, margin: "auto 1rem" }}>
        <div className="row">
          <div className="col-md-12 pl-md-0">
            <div className="auth-form-wrapper px-5 py-4">
              <div
                className="d-block mb-4 mx-auto d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "white",
                  width: "120px",
                  height: "120px",
                  borderRadius: "50%",
                }}
              >
                <img src={ui.vendorLogo} width="90px" alt=""></img>
              </div>
              <h5
                className="text font-weight-normal mb-4"
                style={ui.logInMsg.style}
                title={ui.logInMsg.tooltip}
              >
                {ui.logInMsg.value}
              </h5>
              {submit === "fail" && (
                <p className="alert alert-danger">
                  You credential does not found.
                </p>
              )}
              <form className="forms-sample">
                <div className="form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    style={ui.userName.style}
                  >
                    {ui.userName.label}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    defaultValue={ui.userName.value}
                    disabled={true}
                    title={ui.userName.tooltip}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="exampleInputPassword1"
                    style={ui.password.style}
                    title={ui.password.tooltip}
                  >
                    {ui.password.label}
                  </label>
                  {ui.password.controlType === "date" ? (
                    <input
                      type="date"
                      className="form-control"
                      id="exampleInputPassword1"
                      autoComplete="current-password"
                      placeholder="Date of Birth"
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      autoComplete="current-password"
                    />
                  )}
                </div>
                {ui.captcha.visible === 'true' && <div className="form-group">
                  <div className="mb-2" style={{ ...ui.captcha.style, fontFamily: "Georgia, 'Times New Roman', Times, serif", fontSize: "20px", textAlign: "center", filter: "drop-shadow" }} >
                    <span className="font-weight-bold">
                      <svg xmlns="" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                      </svg>
                    </span>
                    <span className="m-2">xdwerdffd</span>
                  </div>
                  <input
                    className="form-control"
                    placeholder={ui.captcha.captchaBox.label}
                    type="text"
                    title={ui.captcha.captchaBox.tooltip}
                  />
                </div>}

                <div className="mt-3">
                  <button
                    className="btn btn-primary mr-2 mb-2 mb-md-0 text-white"
                    onClick={login}
                    style={ui.submitBtn.style}
                    title={ui.submitBtn.tooltip}
                  >
                    {submit === "loading" && (
                      <i className="fa fa-spinner fa-spin"></i>
                    )}{" "}
                    {ui.submitBtn.value}
                  </button>
                </div>
                <a
                  href={`tel:${ui.help.tollfree}`}
                  className="d-block mt-3 text"
                  style={ui.help.style}
                  title={ui.help.tooltip}
                >
                  {ui.help.value}
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>

  );

}

export default LoginPreview;
