import React from "react";

function HeaderPreview({ ui }) {
  return (
    <nav
      className=""
      style={{ ...ui.header.style, display: "flex" }}
    >
      <span className="navbar-brand">
        <img src={ui.header.vendorLogo} width="80px" />
      </span>
      <ul className="ml-auto my-auto">
        <span
          style={{ marginRight: "50px", fontSize: "18px", fontWeight: "500" }}
          className="d-xs-none"
        >
          Welcome
        </span>
        {ui.header.payNowBtn &&
          <button
            className="btn btn-primary mr-2"
            style={{ ...ui.header.payNowBtn.style, fontSize: "12px" }}
            title={ui.header.payNowBtn.tooltip}
          >
            {ui.header.payNowBtn.value}
          </button>
        }
        {/* {
          ui.header.payEmiBtn.value && <button
            className="btn btn-primary mr-2"
            style={{ ...ui.header.payEmiBtn.style, fontSize: "12px" }}
            title={ui.header.payEmiBtn.tooltip}
          >
            {ui.header.payEmiBtn.value}
          </button>
        } */}
        <button
          className="btn btn-primary"
          style={{ ...ui.header.exitBtn.style, fontSize: "10px" }}
          title={ui.header.exitBtn.tooltip}
        >
          <i className="fa fa-sign-out"></i> {ui.header.exitBtn.value}
        </button>

      </ul>
    </nav>
  );
}

export default HeaderPreview;
