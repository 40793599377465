import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import config from "../../config";
import { logOut } from "../../redux/action/user";

function TopHeader() {
  const {userid, UserToken,userlevel,username}=useSelector(state=>state.userInfo);
  const history=useHistory();
  const dispatch=useDispatch();
  const logout=()=>{
    axios.post(`${config.API_URL}/msdash/userlogout/`,{userid},
    {headers:{Authorization:UserToken}})
    .then(res=>{
      dispatch(logOut())
      history.push('/login')
    })
    .catch(err=>{
      console.log(err)
    })
  }
  return (
    <nav className="navbar">
      <a href="#" className="sidebar-toggler">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-menu"
        >
          <line x1={3} y1={12} x2={21} y2={12} />
          <line x1={3} y1={6} x2={21} y2={6} />
          <line x1={3} y1={18} x2={21} y2={18} />
        </svg>
      </a>
      <div className="navbar-content">
        <ul className="navbar-nav">
          <li className="nav-item dropdown nav-profile d-xs-none" style={{marginRight:"100px"}}>
            <span style={{fontSize:"23px"}}>Welcome {username}</span>
          </li>
          <li className="nav-item dropdown nav-profile">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="profileDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="https://cdnlib.a10s.in/cdndata/Male.png" alt="profile" />
            </a>
            <div className="dropdown-menu" aria-labelledby="profileDropdown">
              
              <div className="dropdown-body">
                <ul className="profile-nav p-0 pt-3">
                  
                  <li className="nav-item" onClick={()=>history.push("/dashboard/change-password")}>
                    <a href="javascript:;" className="nav-link">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit"
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                      </svg>
                      <span>Change Password</span>
                    </a>
                  </li>
                  
                  <li className="nav-item" onClick={logout}>
                    <a href="javascript:;" className="nav-link">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-log-out"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span>Log Out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default TopHeader;
