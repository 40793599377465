import axios from "axios";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import { fetchCampNameList } from "../../redux/action/campaign";
import Error404 from "./ErrorPages/Error404";
import Table from "../shared/Table";
import { downloadIntoCSV } from "../../utils/function";

function Index() {
  const [loading, setLoading] = useState("");
  const [dashData, setDashData] = useState([]);
  const [errData, setErrorData] = useState([]);
  const [formData, setFormData] = useState({
    type: "click",
    campName: "ALL",
    period: "month",
  });
  const viewPeriod = {
    click: {today: "VW_TODAY_EVENT",month:"VW_MONTH_EVENT",year:"VW_YEAR_EVENT" },
    error: {today: "VW_TODAY_ERROR",month:"VW_MONTH_ERROR",year:"VW_YEAR_ERROR" },
    ctc: {today: "VW_TODAY_CTC",month:"VW_MONTH_CTC",year:"VW_YEAR_CTC" },
  };
  const downloadPeriod = {
    click: {today: "VW_TODAY_EVENT_DETAILS",month:"VW_MONTH_EVENT_DETAILS",year:"VW_YEAR_EVENT_DETAILS" },
    ctc: {today: "VW_TODAY_CTC_DETAILS",month:"VW_MONTH_CTC_DETAILS",year:"VW_YEAR_CTC_DETAILS" },
  };
  const { campNames } = useSelector((state) => state.campName);
  const { UserToken } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();

  const fetchData = (url, error) => {
    axios
      .get(url, { headers: { Authorization: UserToken } })
      .then((res) => {
        const arr = Object.values(res.data);
        if (error) {
          setDashData([]);
          setErrorData(arr);
        } else {
          setErrorData([]);
          setDashData(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (campNames && campNames.length === 0) {
      dispatch(fetchCampNameList(UserToken));
    }
    fetchData(
      `${config.API_URL}/msdash/geteventdashboard/${formData.campName}/${viewPeriod[formData.type][formData.period]}/${formData.type}`
    );
  }, []);

  const typeChangeHandler = (e) => {
    const type = e.target.value;
    setFormData({ ...formData, type });
      const error=type === "error"
      fetchData(
        `${config.API_URL}/msdash/geteventdashboard/${error ? "ALL":formData.campName}/${
          viewPeriod[type][formData.period]
        }/${type}`,error
      );
    
  };
  const campChangeHandler = (e) => {
    const campName = e.target.value;
    setFormData({ ...formData, campName });
    fetchData(
      `${config.API_URL}/msdash/geteventdashboard/${campName}/${
        viewPeriod[formData.type][formData.period]
      }/click`
    );
  };
  const periodChangeHandler = (e) => {
    const period = e.target.value;
    const error=formData.type === "error"
    setFormData({ ...formData, period });
    fetchData(
      `${config.API_URL}/msdash/geteventdashboard/${error?"ALL":formData.campName}/${viewPeriod[formData.type][period]}/click`,
      error
    );
   
  };
  const exportInCSV = (campName) => {
    setLoading(campName);
    axios
      .get(
        `${config.API_URL}/msdash/geteventdashboard/${campName}/${downloadPeriod[formData.type][formData.period]}/${formData.type}`,
        { headers: { Authorization: UserToken } }
      )
      .then((res) => {
        const data = Object.values(res.data);
        const col=Object.keys(data[0])
        var Results = [
          col,
        ];
        downloadIntoCSV(Results, data);

        setLoading("");
      })
      .catch((err) => {
        console.log(err);
        setLoading("");
      });
  };
  const column = [
    { name: "ACT SL NO", value: "ACT_SL_NO" },
    { name: "TASK NAME", value: "TASK_NAME" },
    { name: "ACTIVITY DESC", value: "ACTIVITY_DESC" },
    { name: "EVENT DATE", value: "EVENT_DATE" },
    { name: "EVENT TIME", value: "EVENT_TIME" },
    { name: "REMARKS", value: "REMARKS" },
  ];
  const exportToCSVFromTable = () => {
    const Results = [
      [
        "ACT_SL_NO",
        "TASK_NAME",
        "ACTIVITY_DESC",
        "EVENT_DATE",
        "EVENT_TIME",
        "REMARKS",
      ],
    ];
    downloadIntoCSV(Results, errData);
  };
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center grid-margin w-100 p-2 bg-white"
        style={{ borderRadius: "5px" }}
      >
        <div>
          <div className="input-inline mr-2 mb-md-0 d-flex text-white">
            <span
              className="input-group-addon bg-secondary font-weight-bold d-xs-none"
              style={{ padding: "6px" }}
            >
              Type
            </span>
            <select
              className="form-control"
              style={{ width: "100px" }}
              value={formData.type}
              onChange={typeChangeHandler}
            >
              <option value="click">Clicks</option>
              <option value="error">Errors</option>
              <option value="ctc">CTC</option>
            </select>
          </div>
        </div>
        <div className="d-flex align-items-center">
          {/* {formData && formData.type=== "click" &&  */}
          <div className="input-inline mr-4 mb-md-0 d-flex bg-secondary text-white">
            <span
              className="input-group-addon bg-transparent font-weight-bold d-xs-none"
              style={{ padding: "6px" }}
            >
              Campaign
            </span>
            <select
              className="form-control"
              value={formData.campName}
              onChange={campChangeHandler}
              disabled={formData.type === "error" ? true : false}
            >
              <option value="ALL">All</option>
              {campNames &&
                campNames.map((item) => (
                  <option value={item.campname} key={item.campName}>
                    {item.campname}
                  </option>
                ))}
            </select>
          </div>
          {/* } */}
          <div className="input-inline mb-md-0 bg-dark text-white">
            {/* <span className="input-group-addon bg-transparent font-weight-bold" style={{ padding: "6px" }}></span> */}
            <select
              className="form-control"
              value={formData.period}
              onChange={periodChangeHandler}
            >
              <option value="today">Today</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </div>
        </div>
      </div>
      <div
        className="col-12 col-xl-12"
        style={window.screen.width > 900 ? { width: "600px" } : null}
      >
        <div className="row flex-grow">
          {dashData && dashData.length > 0 ? (
            dashData.map((item, index) => (
              <div className="col-md-4 grid-margin" key={`dashboard-${index}`}>
                <div className="card">
                  <div className="card-header">
                    <h5>
                      {item.CAMP_ID} -{" "}
                      <span style={{ color: "green" }}>
                        {item["Till Date"]}
                      </span>
                    </h5>
                  </div>
                  <div className="card-body">
                    {Object.entries(item)
                      .filter(
                        ([key, value]) =>
                          key !== "CAMP_ID" && key !== "Till Date"
                      )
                      .map(([key, value]) => (
                        <div
                          className="d-flex justify-content-between mb-2"
                          key={item.CAMP_ID + "-" + key}
                        >
                          <h6>
                            <span className="badge badge-info text-white">
                              <i className="fa fa-chevron-right"></i>
                            </span>{" "}
                            {key}
                          </h6>
                          <p className="text-success font-weight-bold">
                            {value}
                          </p>
                        </div>
                      ))}
                    <div className="text-right">
                      <button
                        className="btn btn-danger"
                        onClick={() => exportInCSV(item.CAMP_ID)}
                      >
                        {item.CAMP_ID === loading ? (
                          <i className="fa fa-spin fa-refresh"></i>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-download-cloud btn-icon-prepend"
                          >
                            <polyline points="8 17 12 21 16 17" />
                            <line x1={12} y1={12} x2={12} y2={21} />
                            <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29" />
                          </svg>
                        )}{" "}
                        Download
                      </button>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Pie
                      data={{
                        labels: Object.keys(item).filter(
                          (child) =>
                            child !== "CAMP_ID" && child !== "Till Date"
                        ),
                        datasets: [
                          {
                            label: "Clicks",
                            data: Object.keys(item)
                              .filter(
                                (child) =>
                                  child !== "CAMP_ID" && child !== "Till Date"
                              )
                              .map((child) => item[child]),
                            backgroundColor: [
                              "#e41a1c",
                              "#377eb8",
                              "#ff7f00",
                              "#4daf4a",
                            ],
                            hoverOffset: 4,
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : errData && errData.length > 0 ? (
            <>
              <Table
                column={column}
                data={errData}
                title="Error Logs"
                downloadFunc={exportToCSVFromTable}
              />
            </>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}

export default Index;
