import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import Header from './Header'
import Feature from './Feature'
import FeatureImage from './FeatureImage'
import Plans from './Plans'
import Details from './Details'
import Footer from './Footer'
import data from './data'
import { errLog, eventLog, fetchCompUi, getCampDetails, getToken, transformForm } from '../../utils/function'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import Error from '../../Component/Error'
import Loader from '../../Component/Loader'
function Landing() {
    data.hero.forms = transformForm(data.hero.forms);
    data.feature.cards=Object.values(data.feature.cards);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [formSuccess, setFormSuccess] = useState("");
    const [formError, setFormError] = useState("");
    const [landingData, setlandingData] = useState({});
    const { randstr } = useParams();

    const setData = (data) => {
      const forms = Object.values(data.hero.forms).map((item) => ({
        ...item,
        fields: transformForm(item.fields),
      }));
      const cards=Object.values(data.feature.cards)
      
     setlandingData({ ...data, hero: {...data.hero,forms:forms},feature:{...data.feature,cards} });
    // console.log("forms",forms)
      setLoading(false);
    };
    const obj = {
      msg: "Landing Page Loaded.",
      onError: (msg) => {
        setError(msg);
      },
    };
    useEffect(async () => {
      const token = await getToken();
      if (token) {
        fetchCompUi(7, setData, randstr, token.slice(7), obj);
      } else {
        setError("Something went wrong.");
      }
    }, []);
  const onSubmit = async (values) => {
    // console.log(values);
    try {
      const campInfo = await getCampDetails(randstr);
      if (campInfo) {
        const data = {
          ...values,
          randkey: randstr,
          table: "LANDING",
          req_unique_id: campInfo.CUST_UNK_ID,
          campid: campInfo.CAMP_ID,
          campname: campInfo.CAMP_NAME,
          
        };
        const result = await axios.post(
          `${config.API_URL}/ms/setFormSubmit/`,
          data
        );
        if (result.data.status === "200") {
          setFormSuccess(result.data.message);
          const log = [
            {
              RAND_KEY: randstr,
              CUST_ID: landingData.cust_id,
              CAMP_ID: landingData.campName,
              STATUS: "Landing Form Submitted Successfully.",
            },
          ];
          await eventLog(log);
        } else {
          setFormError("Something went wrong.");
          const log = {
            sNo: "9999",
            taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/setFormSubmit/`,
            errMessage: {
              Message: result.message,
            },
            errRemarks: "ERROR OCCURED while Landing Form Submitting.",
          };
          errLog(log);
        }
      }
    } catch (err) {
      setFormError(err.message);
      const log = {
        sNo: "9999",
        taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/setFormSubmit/`,
        errMessage: {
          Message: err.message,
        },
        errRemarks: "ERROR OCCURED while landing Form Submitting.",
      };
      errLog(log);
    }
  };

    return (
      <>
      {error ? (
        <Error msg={error} />
      ) : loading ? (
        <Loader />
      ) : (
        <div className="main-wrapper">
            <div className="page-wrapper bg-white ml-0">
                <Header logo={landingData.logo}/>
                <Banner ui={landingData.hero} onSubmit={onSubmit}/>
                <Feature ui={landingData.feature}/>
                {/* <FeatureImage/>
                <Plans /> */}
                <Details ui={landingData.bottom}/>
                <Footer ui={landingData.footer}/>
            
            </div>
        </div>)}
        </>
    )
}

export default Landing
