import { LOGOUT, SAVE_USER_INFO, USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_SUCCESS } from "../constant/user";


const userListReducer=(state={users:[]},action)=>{
    switch(action.type){
        case USER_LIST_REQUEST:
            return {loading:true};
        case USER_LIST_SUCCESS:
            return {loading:false, users:action.payload};
        case USER_LIST_FAIL:
            return {loading:false, error:action.payload};
        default:
            return state;
    }
}
const UserToken=localStorage.getItem('token')
const userInfoReducer=(state={UserToken},action)=>{
    switch(action.type){
        case SAVE_USER_INFO:
            return action.payload;
        case LOGOUT:
            return {};
        default:
            return state;
    }
}

export {userListReducer,userInfoReducer}