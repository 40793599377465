import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Controller from "../../shared/controller/Controller";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampNameList } from "../../../redux/action/campaign";
import { useHistory } from "react-router";
import Style from "../../shared/controller/Style";
import config from "../../../config";
import DialerPreview from "../preview/DialerPreview";

function Dialer() {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [data, setData] = useState({
    loaded: false,
    initialValues: {
      ACTION: "ADD",
      CAMP_ID: "",
      CAMP_NAME: "",
      COMP_ID: 5,
      ACTIVE: 1,
      DB_OBJECT: "",
      HUNTGROUP: "",
      LIST_ID: "",
      DIALER_IP: "",
      OTP_ENABLE: 0,
      API_DATA: {
        dialer: {
          isSundayWorking: true,
          timeStart: "",
          timeEnd: "",
          upSection: {
            style: { backgroundColor: "", color: "" },
            content: { label: "" },
            action: {
              value: "",
              link: "",
              style: {
                color: "",
                backgroundColor: "",
                borderColor: "",
              },
              tooltip: "",
            },
          },
          downSection: {
            style: { backgroundColor: "", color: "" },
            helpText: "",
            btn1: {
              value: "",
              style: {
                color: "",
                backgroundColor: "",
                borderColor: "",
              },
              tooltip: "",
              apiLink: "",
              popUp: {
                content: "",
                number: "",
                closeBtn: {
                  value: "",
                  style: {
                    color: "",
                    backgroundColor: "",
                    borderColor: "",
                  },
                  tooltip: "",
                },
                bottomContent: "",
              },
            },
            btn2: {
              value: "",
              style: {
                color: "",
                backgroundColor: "",
                borderColor: "",
              },
              tooltip: "",
              popUp: {
                content: "",
                number: "",
                scheduleBtn: {
                  value: "",
                  apiLink: "",
                  style: {
                    color: "",
                    backgroundColor: "",
                    borderColor: "",
                  },
                  tooltip: "",
                },
                bottomContent: "",
              },
            },
          },
          emailApi: "",
          whatsappApi: "",
          emailLabel: {
            text: "",
            tooltip: "",
            style: {
              color: "",
              fontSize: "",
            },
          },
          emailSendButton: {
            text: "",
            tooltip: "",
            style: {
              backgroundColor: "",
              color: "",
              borderColor: "",
            },
          },
          whatsAppLabel: {
            text: "",
            tooltip: "",
            style: {
              color: "",
              fontSize: "",
            },
          },
          whatsAppSendButton: {
            text: "",
            tooltip: "",
            style: {
              backgroundColor: "",
              color: "",
              borderColor: "",
            },
          },
          footerStyle: {
            backgroundColor: "",
            borderColor: "",
          },
        },
      },
    },
  });
  const { campNames } = useSelector((state) => state.campName);
  const { UserToken } = useSelector((state) => state.userInfo);
  const history = useHistory();
  const dispatch = useDispatch();
  let campOption = [];
  useEffect(() => {
    if (campNames && campNames.length === 0) {
      dispatch(fetchCampNameList(UserToken));
    }
  }, []);

  if (campNames && campNames.length > 0) {
    campOption = campNames.map((item) => {
      return {
        label: item.campname,
        value: item.campid,
      };
    });
  }

  const validationSchema = Yup.object({
    CAMP_ID: Yup.string(),
    API_DATA: Yup.object(),
  });

  const onSubmit = (values, actions) => {
    values.API_DATA.campName = values.CAMP_NAME;
    values.API_DATA.campId = values.CAMP_ID;
    axios
      .post(`${config.API_URL}/msdash/managecomponent/`, values, {
        headers: { Authorization: UserToken },
      })
      .then((res) => {
        setSuccess(res.data.message);
        window.scroll(0, 0);
        actions.setSubmitting(false);
      })
      .catch((err) => {
        setWarning(err.message);
        actions.setSubmitting(false);
        console.log(err);
      });
  };

  return (
    <Formik
      initialValues={data.initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { setFieldValue } = formik;
        const getCompData = (e) => {
          const campid = e.value;
          axios
            .get(`${config.API_URL}/ms/getcompdata/${campid}/5`)
            .then((result) => {
              axios
                .get(`${config.API_URL}/msdash/gethuntgroup/${campid}/5`, {
                  headers: { Authorization: UserToken },
                })
                .then((huntData) => {
                  if (result.data.footerStyle && result.data.footerStyle.backgroundColor) {
                    setData({
                      loaded: true,
                      initialValues: {
                        ...data.initialValues,
                        ...result.data,
                        ...huntData.data,
                        ACTION: "UPDATE",
                        CAMP_ID: campid,
                        CAMP_NAME: e.label,
                      },
                    });
                  } else {
                    // ----------------------------------------------------------------------------
                    result.data.dialer.downSection.style = data.initialValues.API_DATA.dialer.downSection.style;
                    result.data.dialer.upSection.style = data.initialValues.API_DATA.dialer.upSection.style;
                    // ---------------------------------------------------------------------------
                    setFieldValue("API_DATA", result.data);
                    setFieldValue("ACTION", "UPDATE");
                    setFieldValue("CAMP_ID", campid);
                    setFieldValue("CAMP_NAME", e.label);
                    setFieldValue("HUNTGROUP", huntData.data.HUNTGROUP);
                    setFieldValue("LIST_ID", huntData.data.LIST_ID);
                    setFieldValue("DIALER_IP", huntData.data.DIALER_IP);
                    setFieldValue("API_DATA.dialer.footerStyle", {
                      backgroundColor: "",
                      borderColor: "",
                    });
                    setData({
                      loaded: true,
                      initialValues: {
                        ...data.initialValues,
                        ...result.data,
                        ...huntData.data,
                        API_DATA: {
                          dialer: {
                            ...data.initialValues.API_DATA.dialer,
                            ...result.data.dialer,
                            footerStyle: {
                              backgroundColor: "",
                              borderColor: "",
                            },
                          },
                        },
                        ACTION: "UPDATE",
                        CAMP_ID: campid,
                        CAMP_NAME: e.label,
                      },
                    });
                  }

                });
            })
            .catch((err) => {
              formik.resetForm();
              setData({ ...data, loaded: true });
              setFieldValue("CAMP_ID", campid);
              setFieldValue("CAMP_NAME", e.label);
              console.log("formik from error", formik);
            });
        };
        return (
          <>
            <div
              className={data.loaded ? "col-md-8 mx-auto" : "col-md-12 mx-auto"}
            >
              <div className="card shadow-lg">
                <div className="card-body">
                  <h6 className="card-title">Customer profile Page - Dialer</h6>
                  <Form className="forms-sample">
                    {success && (
                      <div className="alert alert-success">{success}</div>
                    )}
                    {warning && (
                      <div className="alert alert-warning">{warning}</div>
                    )}
                    <Controller
                      control="multiSelect"
                      label="Campaign Name"
                      value={formik.values.campid}
                      name="campid"
                      isMulti={false}
                      formik={formik}
                      options={campOption}
                      onChange={getCompData}
                      wrapperClass={
                        formik.errors.campid && formik.touched.campid
                          ? "has-error"
                          : null
                      }
                    />
                    {data.loaded && (
                      <>
                        <fieldset>
                          <legend>Upper Section</legend>
                          <Style
                            defaultValue={[
                              "API_DATA",
                              "dialer",
                              "upSection",
                              "style",
                            ]}
                            name="API_DATA.dialer.upSection.style"
                            styleName="Style"
                            formik={formik}
                          />
                          <fieldset>
                            <legend>Content</legend>
                            <Controller
                              control="input"
                              label="Label"
                              value={
                                formik.values.API_DATA.dialer.upSection.content
                                  .label
                              }
                              name="API_DATA.dialer.upSection.content.label"
                            />
                          </fieldset>
                          <fieldset>
                            <legend>Pay Now Button</legend>
                            <Controller
                              control="input"
                              label="Text"
                              value={
                                formik.values.API_DATA.dialer.upSection.action
                                  .value
                              }
                              name="API_DATA.dialer.upSection.action.value"
                            />
                            {/* <Controller
                              control="input"
                              label="Link"
                              value={
                                formik.values.API_DATA.dialer.upSection.action
                                  .link
                              }
                              name="API_DATA.dialer.upSection.action.link"
                            /> */}
                            <Controller
                              control="input"
                              label="Tooltip"
                              value={
                                formik.values.API_DATA.dialer.upSection.action
                                  .tooltip
                              }
                              name="API_DATA.dialer.upSection.action.tooltip"
                            />
                            <Style
                              defaultValue={[
                                "API_DATA",
                                "dialer",
                                "upSection",
                                "action",
                                "style",
                              ]}
                              name="API_DATA.dialer.upSection.action.style"
                              styleName="Style"
                              formik={formik}
                            />
                          </fieldset>
                        </fieldset>
                        <fieldset>
                          <legend>Down Section</legend>
                          <Style
                            defaultValue={[
                              "API_DATA",
                              "dialer",
                              "downSection",
                              "style",
                            ]}
                            name="API_DATA.dialer.downSection.style"
                            styleName="Style"
                            formik={formik}
                          />
                          <Controller
                            control="input"
                            label="Help Text"
                            value={
                              formik.values.API_DATA.dialer.downSection.helpText
                            }
                            name="API_DATA.dialer.downSection.helpText"
                          />
                          <fieldset>
                            <legend>Button 1</legend>
                            <Controller
                              control="input"
                              label="Text"
                              value={
                                formik.values.API_DATA.dialer.downSection.btn1
                                  .value
                              }
                              name="API_DATA.dialer.downSection.btn1.value"
                            />
                            <Controller
                              control="input"
                              label="Tooltip"
                              value={
                                formik.values.API_DATA.dialer.downSection.btn1
                                  .tooltip
                              }
                              name="API_DATA.dialer.downSection.btn1.tooltip"
                            />
                            <Controller
                              control="input"
                              label="Api Url"
                              value={
                                formik.values.API_DATA.dialer.downSection.btn1
                                  .apiLink
                              }
                              name="API_DATA.dialer.downSection.btn1.apiLink"
                            />
                            <Style
                              defaultValue={[
                                "API_DATA",
                                "dialer",
                                "downSection",
                                "btn1",
                                "style",
                              ]}
                              name="API_DATA.dialer.downSection.btn1.style"
                              styleName="Style"
                              formik={formik}
                            />
                            <fieldset>
                              <legend>Confirm Popup</legend>
                              <Controller
                                control="textArea"
                                label="Body Content"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn1
                                    .popUp.content
                                }
                                name="API_DATA.dialer.downSection.btn1.popUp.content"
                              />
                              <Controller
                                control="input"
                                label="Toll Free Number"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn1
                                    .popUp.number
                                }
                                name="API_DATA.dialer.downSection.btn1.popUp.number"
                              />
                              <Controller
                                control="textArea"
                                label="Footer Content"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn1
                                    .popUp.bottomContent
                                }
                                name="API_DATA.dialer.downSection.btn1.popUp.bottomContent"
                              />

                              <Controller
                                control="input"
                                label="Close Button Text"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn1
                                    .popUp.closeBtn.value
                                }
                                name="API_DATA.dialer.downSection.btn1.popUp.closeBtn.value"
                              />
                              <Controller
                                control="input"
                                label=" Close Button Tooltip"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn1
                                    .popUp.closeBtn.tooltip
                                }
                                name="API_DATA.dialer.downSection.btn1.popUp.closeBtn.tooltip"
                              />
                              <Style
                                defaultValue={[
                                  "API_DATA",
                                  "dialer",
                                  "downSection",
                                  "btn1",
                                  "popUp",
                                  "closeBtn",
                                  "style",
                                ]}
                                name="API_DATA.dialer.downSection.btn1.popUp.closeBtn.style"
                                styleName="Style"
                                formik={formik}
                              />
                            </fieldset>
                          </fieldset>
                          <fieldset>
                            <legend>Button 2</legend>
                            <Controller
                              control="input"
                              label="Text"
                              value={
                                formik.values.API_DATA.dialer.downSection.btn2
                                  .value
                              }
                              name="API_DATA.dialer.downSection.btn2.value"
                            />
                            <Controller
                              control="input"
                              label="Tooltip"
                              value={
                                formik.values.API_DATA.dialer.downSection.btn2
                                  .tooltip
                              }
                              name="API_DATA.dialer.downSection.btn2.tooltip"
                            />
                            <Style
                              defaultValue={[
                                "API_DATA",
                                "dialer",
                                "downSection",
                                "btn2",
                                "style",
                              ]}
                              name="API_DATA.dialer.downSection.btn2.style"
                              styleName="Style"
                              formik={formik}
                            />
                            <fieldset>
                              <legend>confirm Pop Up</legend>
                              <Controller
                                control="textArea"
                                label="Body Content"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn2
                                    .popUp.content
                                }
                                name="API_DATA.dialer.downSection.btn2.popUp.content"
                              />
                              <Controller
                                control="simpleSelect"
                                label="Is Sunday Working"
                                value={
                                  formik.values.API_DATA.dialer.isSundayWorking
                                }
                                name="API_DATA.dialer.isSundayWorking"
                                options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                              />
                              <Controller
                                control="input"
                                type="time"
                                label="Start Time"
                                value={
                                  formik.values.API_DATA.dialer.timeStart
                                }
                                name="API_DATA.dialer.timeStart"
                              />
                              <Controller
                                control="input"
                                type="time"
                                label="End Time"
                                value={
                                  formik.values.API_DATA.dialer.timeEnd
                                }
                                name="API_DATA.dialer.timeEnd"
                              />
                              <Controller
                                control="textArea"
                                label="Footer Content"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn2
                                    .popUp.bottomContent
                                }
                                name="API_DATA.dialer.downSection.btn2.popUp.bottomContent"
                              />
                              <Controller
                                control="input"
                                label="Toll Free Number"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn2
                                    .popUp.number
                                }
                                name="API_DATA.dialer.downSection.btn2.popUp.number"
                              />
                              <Controller
                                control="input"
                                label="Schedule Button Text"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn2
                                    .popUp.scheduleBtn.value
                                }
                                name="API_DATA.dialer.downSection.btn2.popUp.scheduleBtn.value"
                              />
                              <Controller
                                control="input"
                                label="Schedule API Link"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn2
                                    .popUp.scheduleBtn.apiLink
                                }
                                name="API_DATA.dialer.downSection.btn2.popUp.scheduleBtn.apiLink"
                              />
                              <Controller
                                control="input"
                                label="Schedule Tooltip"
                                value={
                                  formik.values.API_DATA.dialer.downSection.btn2
                                    .popUp.scheduleBtn.tooltip
                                }
                                name="API_DATA.dialer.downSection.btn2.popUp.scheduleBtn.tooltip"
                              />
                              <Style
                                defaultValue={[
                                  "API_DATA",
                                  "dialer",
                                  "downSection",
                                  "btn2",
                                  "popUp",
                                  "scheduleBtn",
                                  "style",
                                ]}
                                name="API_DATA.dialer.downSection.btn2.popUp.scheduleBtn.style"
                                styleName="Style"
                                formik={formik}
                              />
                            </fieldset>
                          </fieldset>
                        </fieldset>
                        <fieldset>
                          <legend>Email</legend>
                          <Controller
                            control="input"
                            label="Label "
                            value={
                              formik.values.API_DATA.dialer.emailLabel.text
                            }
                            name="API_DATA.dialer.emailLabel.text"
                          />
                          <Controller
                            control="input"
                            label="Tooltip "
                            value={
                              formik.values.API_DATA.dialer.emailLabel.tooltip
                            }
                            name="API_DATA.dialer.emailLabel.tooltip"
                          />
                          <Controller
                            control="input"
                            label="Email Api"
                            value={formik.values.API_DATA.dialer.emailApi}
                            name="API_DATA.dialer.emailApi"
                          />
                          <Style
                            defaultValue={[
                              "API_DATA",
                              "dialer",
                              "emailLabel",
                              "style",
                            ]}
                            name="API_DATA.dialer.emailLabel.style"
                            styleName="Style"
                            formik={formik}
                          />
                          <fieldset>
                            <legend>Send Button</legend>
                            <Controller
                              control="input"
                              label="Label "
                              value={
                                formik.values.API_DATA.dialer.emailSendButton
                                  .text
                              }
                              name="API_DATA.dialer.emailSendButton.text"
                            />
                            <Controller
                              control="input"
                              label="Tooltip "
                              value={
                                formik.values.API_DATA.dialer.emailSendButton
                                  .tooltip
                              }
                              name="API_DATA.dialer.emailSendButton.tooltip"
                            />
                            <Style
                              defaultValue={[
                                "API_DATA",
                                "dialer",
                                "emailSendButton",
                                "style",
                              ]}
                              name="API_DATA.dialer.emailSendButton.style"
                              styleName="Style"
                              formik={formik}
                            />
                          </fieldset>
                        </fieldset>
                        <fieldset>
                          <legend>Whatsapp</legend>
                          <Controller
                            control="input"
                            label="Label "
                            value={
                              formik.values.API_DATA.dialer.whatsAppLabel.text
                            }
                            name="API_DATA.dialer.whatsAppLabel.text"
                          />
                          <Controller
                            control="input"
                            label="Tooltip "
                            value={
                              formik.values.API_DATA.dialer.whatsAppLabel
                                .tooltip
                            }
                            name="API_DATA.dialer.whatsAppLabel.tooltip"
                          />
                          <Controller
                            control="input"
                            label="Whatsapp Api"
                            value={formik.values.API_DATA.dialer.whatsappApi}
                            name="API_DATA.dialer.whatsappApi"
                          />
                          <Style
                            defaultValue={[
                              "API_DATA",
                              "dialer",
                              "whatsAppLabel",
                              "style",
                            ]}
                            name="API_DATA.dialer.whatsAppLabel.style"
                            styleName="Style"
                            formik={formik}
                          />
                          <fieldset>
                            <legend>Send Button</legend>
                            <Controller
                              control="input"
                              label="Label "
                              value={
                                formik.values.API_DATA.dialer.whatsAppSendButton
                                  .text
                              }
                              name="API_DATA.dialer.whatsAppSendButton.text"
                            />
                            <Controller
                              control="input"
                              label="Tooltip "
                              value={
                                formik.values.API_DATA.dialer.whatsAppSendButton
                                  .tooltip
                              }
                              name="API_DATA.dialer.whatsAppSendButton.tooltip"
                            />
                            <Style
                              defaultValue={[
                                "API_DATA",
                                "dialer",
                                "whatsAppSendButton",
                                "style",
                              ]}
                              name="API_DATA.dialer.whatsAppSendButton.style"
                              styleName="Style"
                              formik={formik}
                            />
                          </fieldset>
                        </fieldset>
                        <fieldset>
                          <legend>Footer section</legend>
                          <Controller
                            control="input"
                            label="Call Label"
                            value={
                              formik.values.API_DATA.dialer.footer_call_label
                            }
                            name="API_DATA.dialer.footer_call_label"
                          />
                          <Controller
                            control="input"
                            label="Call icon"
                            value={
                              formik.values.API_DATA.dialer.footer_call_icon
                            }
                            name="API_DATA.dialer.footer_call_icon"
                          />
                          <Controller
                            control="input"
                            label="WhatsApp Label "
                            value={
                              formik.values.API_DATA.dialer
                                .footer_whatsapp_label
                            }
                            name="API_DATA.dialer.footer_whatsapp_label"
                          />
                          <Controller
                            control="input"
                            label="Whatsapp Icon"
                            value={
                              formik.values.API_DATA.dialer.footer_whatsapp_icon
                            }
                            name="API_DATA.dialer.footer_whatsapp_icon"
                          />
                          <Controller
                            control="input"
                            label="Email Label "
                            value={
                              formik.values.API_DATA.dialer.footer_email_label
                            }
                            name="API_DATA.dialer.footer_email_label"
                          />
                          <Controller
                            control="input"
                            label="email icon"
                            value={formik.values.API_DATA.footer_email_icon}
                            name="API_DATA.dialer.footer_email_icon"
                          />
                          <Style
                            defaultValue={["API_DATA", "dialer", "footerStyle"]}
                            name="API_DATA.dialer.footerStyle"
                            styleName="Style"
                            formik={formik}
                            defaultObj={{
                              backgroundColor: "",
                              borderColor: "",
                            }}
                          />
                        </fieldset>
                        <fieldset>
                          <legend>List Configuration</legend>
                          <Controller
                            control="input"
                            label="HUNTGROUP "
                            value={formik.values.HUNTGROUP}
                            name="HUNTGROUP"
                          />
                          <Controller
                            control="input"
                            label="LIST_ID "
                            value={formik.values.LIST_ID}
                            name="LIST_ID"
                          />
                          <Controller
                            control="input"
                            label="DIALER_IP "
                            value={formik.values.DIALER_IP}
                            name="DIALER_IP"
                          />
                        </fieldset>

                        <div className="box-footer text-right">
                          <button
                            type="button"
                            className="btn btn-rounded btn-warning btn-outline mr-1"
                            onClick={() => history.goBack()}
                          >
                            <i className="fa fa-trash-o"></i> Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-rounded btn-primary btn-outline"
                          >
                            {formik.isSubmitting ? (
                              <i className="fa fa-spin fa-refresh"></i>
                            ) : (
                              <i className="fa fa-fw fa-save"></i>
                            )}{" "}
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>
            {data.loaded && (
              <div
                className="col-md-4"
                style={{ position: "relative", display: "block" }}
              >
                <div className="card position-fixed w-25 shadow-lg p-3">
                  <DialerPreview ui={formik.values.API_DATA} />
                </div>
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
}

export default Dialer;
