import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import Header from "../../dashboard/shared/Header";
import TopHeader from "../../dashboard/shared/TopHeader";
import AddCampaign from "./campaign/AddCampaign";
import EditCampaign from "./campaign/EditCampaign";
import ViewCampaigns from "./campaign/ViewCampaigns";
import AddUser from "./user/AddUser";
import EditUser from "./user/EditUser";
import ViewUsers from "./user/ViewUsers";
import Error404 from "./ErrorPages/Error404";
import ServerError from "./ErrorPages/ServerError";
import Unauthorize from "./ErrorPages/Unauthorize";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import config from "../../config";
import { logOut, saveUserInfo } from "../../redux/action/user";
import Login from "./component/LogIn";
import HeaderComponent from "./component/Header";
import Slider from "./component/Slider";
import Tab from "./component/Tab";
import Dialer from "./component/Dialer";
import LoginPreview from "./preview/LoginPreview";
import Index from "./Index";
import ChangePassword from "./ChangePassword";
import ResetPassword from "./ResetPassword";
// import Footer from "../../Component/Footer";
import IpList from "./component/IpList";
import Loader from "../../Component/Loader";
import Feedback from "./component/Feedback";
import LandingPage from "./component/LandingPage";
import UserDocs from "./UserDocs";
import ErrorBoundary from "../../ErrorBoundry";
import Footer from "./component/Footer";


function Dashbboard() {
  const [loading, setLoading] = useState(false);
  const { message, UserToken, userid } = useSelector((state) => state.userInfo);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (UserToken && !userid) {
      setLoading(true);
      axios
        .post(`${config.API_URL}/msdash/varifytoken`, {
          token: UserToken.slice(7),
        })
        .then((result) => {
          if (!result.data.expired) {
            axios
              .get(
                `${config.API_URL}/msdash/getuserbyid/${result.data.userid}`,
                { headers: { Authorization: UserToken } }
              )
              .then((result) => {
                const data = { ...result.data, UserToken };
                dispatch(saveUserInfo(data));
                setLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
              });
          } else {
            dispatch(logOut());
            history.push("/login");
          }
        })
        .catch((err) => {
          dispatch(logOut());
          history.push("/login");
        });
    } else if (userid) {
    } else {
      history.push("/login");
    }
  }, []);
  // if(!message || !message==="SUCCESS"){
  //   history.push("/login")
  // }
  return (
    <>
      <div className="main-wrapper">
        <Header />
        <div
          className="page-wrapper"
          style={{
            background: "linear-gradient(180deg, #031a61, rgb(9 119 204))",
          }}
        >
          <TopHeader />
          <div className="page-content">
            <div className="row">
              <Switch>
                <Route
                  path="/dashboard/feedback"
                  render={() => (
                    <ErrorBoundary>
                      <Feedback />
                    </ErrorBoundary>
                  )}
                />
                <Route path="/dashboard/landing-page" render={() => (
                  <ErrorBoundary>
                    <LandingPage />
                  </ErrorBoundary>
                )} />
                <Route path="/dashboard/404" render={() => (
                  <ErrorBoundary>
                    <Error404 />
                  </ErrorBoundary>
                )} />
                <Route path="/dashboard/error" render={() => (
                  <ErrorBoundary>
                    <ServerError />
                  </ErrorBoundary>
                )} />
                <Route path="/dashboard/unauthorize" render={() => (
                  <ErrorBoundary>
                    <Unauthorize />
                  </ErrorBoundary>
                )} />
                <Route path="/dashboard/add-user" render={() => (
                  <ErrorBoundary>
                    <AddUser />
                  </ErrorBoundary>
                )} />
                <Route path="/dashboard/view-users" render={() => (
                  <ErrorBoundary>
                    <ViewUsers />
                  </ErrorBoundary>
                )} />
                <Route path="/dashboard/edit-user/:_id" render={() => (
                  <ErrorBoundary>
                    <EditUser />
                  </ErrorBoundary>
                )} />
                <Route path="/dashboard/add-campaign" render={() => (
                  <ErrorBoundary>
                    <AddCampaign />
                  </ErrorBoundary>
                )} />
                <Route
                  path="/dashboard/view-campaign"
                  render={() => (
                    <ErrorBoundary>
                      <ViewCampaigns />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path="/dashboard/edit-campaign/:_id"
                  render={() => (
                    <ErrorBoundary>
                      <EditCampaign />
                    </ErrorBoundary>
                  )}
                />
                <Route path="/dashboard/login-component" render={() => (
                  <ErrorBoundary>
                    <Login />
                  </ErrorBoundary>
                )} />
                <Route
                  path="/dashboard/header-component"
                  render={() => (
                    <ErrorBoundary>
                      <HeaderComponent />
                    </ErrorBoundary>
                  )}
                />
                <Route path="/dashboard/slider-component" render={() => (
                  <ErrorBoundary>
                    <Slider />
                  </ErrorBoundary>
                )} />

                <Route path="/dashboard/footer" render={() => (
                  <ErrorBoundary>
                    <Footer />
                  </ErrorBoundary>
                )} />


                <Route path="/dashboard/tab-component" render={() => (
                  <ErrorBoundary>
                    <Tab />
                  </ErrorBoundary>
                )} />
                <Route path="/dashboard/dialer-component" render={() => (
                  <ErrorBoundary>
                    <Dialer />
                  </ErrorBoundary>
                )} />
                <Route
                  path="/dashboard/change-password"
                  render={() => (
                    <ErrorBoundary>
                      <ChangePassword />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path="/dashboard/reset-password"
                  render={() => (
                    <ErrorBoundary>
                      <ResetPassword />
                    </ErrorBoundary>
                  )}
                />
                <Route path="/dashboard/ip-list" render={() => (
                  <ErrorBoundary>
                    <IpList />
                  </ErrorBoundary>
                )} />
                <Route path="/dashboard/userdocs" render={() => (
                  <ErrorBoundary>
                    <UserDocs />
                  </ErrorBoundary>
                )} />
                <Route path="/dashboard/" render={() => (
                  <ErrorBoundary>
                    <Index />
                  </ErrorBoundary>
                )} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashbboard;
