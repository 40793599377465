import React from 'react'
import { Link } from 'react-router-dom'

function ServerError() {
    return (
        <div className="col-md-12 mx-auto">
            <div className="card shadow-lg">
                <div className="card-body text-center">
<h1 style={{fontSize:"100px"}}>ERROR</h1>
<h4>INTERNAL SERVER ERROR</h4>
<Link to="/dasboard/add-camp"><button className="btn btn-primary my-4"><i className="fa fa-arrow-left"></i> Back to Home</button></Link>
                </div>
            </div>
        </div>
    )
}

export default ServerError
