import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Controller from "../../shared/controller/Controller";
import Style from "../../shared/controller/Style";
import config from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { fetchCampNameList } from "../../../redux/action/campaign";
import HeaderPreview from "../preview/HeaderPreview";

const initialValues = {
  ACTION: "ADD",
  CAMP_ID: "",
  CAMP_NAME: "",
  COMP_ID: 2,
  ACTIVE: 1,
  DB_OBJECT: "",
  LIST_ID: "null",
  HUNTGROUP: "null",
  DIALER_IP: "null",
  OTP_ENABLE: 0,
  API_DATA: {
    header: {
      vendorLogo: "",
      logoLink: "",
      style: { backgroundColor: "#000", color: "#000" },
      payNowBtn: {
        value: "",
        link: "",
        style: {
          color: "",
          backgroundColor: "",
          borderColor: "",
        },
        tooltip: "",
      },
      exitBtn: {
        value: "",
        style: {
          color: "",
          backgroundColor: "",
          borderColor: "",
        },
        tooltip: "",
        url: "",
      },
      bodyStyle: {
        backgroundImage: "",
      },
    },
  },
}

function Header() {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [data, setData] = useState({
    loaded: false,
    initialValues
  });
  const { campNames } = useSelector((state) => state.campName);
  const { UserToken } = useSelector((state) => state.userInfo);
  const history = useHistory();
  const dispatch = useDispatch();
  let campOption = [];
  useEffect(() => {
    if (campNames && campNames.length === 0) {
      dispatch(fetchCampNameList(UserToken));
    }
  }, []);

  if (campNames && campNames.length > 0) {
    campOption = campNames.map((item) => {
      return {
        label: item.campname,
        value: item.campid,
      };
    });
  }

  const validationSchema = Yup.object({
    CAMP_ID: Yup.string(),
    API_DATA: Yup.object().shape({
      header: Yup.object().shape({
        vendorLogo: Yup.string().required("Campaign logo is reqired."),
        exitBtn: Yup.object().shape({
          value: Yup.string().required("Exit button's value is reuired."),
        }),
      }),
    }),
  });

  const onSubmit = (values, actions) => {
    values.API_DATA.campName = values.CAMP_NAME;
    values.API_DATA.campId = values.CAMP_ID;
    axios
      .post(`${config.API_URL}/msdash/managecomponent/`, values, {
        headers: { Authorization: UserToken },
      })
      .then((res) => {
        setSuccess(res.data.message);
        window.scroll(0, 0);
        actions.setSubmitting(false);
      })
      .catch((err) => {
        setWarning(err.message);
        actions.setSubmitting(false);
        console.log(err);
      });
  };

  return (
    <Formik
      initialValues={data.initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const { setFieldValue } = formik;
        const getCompData = (e) => {
          const campid = e.value;
          setData({
            loaded: false,
            initialValues
          })
          axios
            .get(`${config.API_URL}/ms/getcompdata/${campid}/2`)
            .then((result) => {
              setFieldValue("API_DATA", result.data);
              setFieldValue("ACTION", "UPDATE");
              setFieldValue("CAMP_ID", campid);
              setFieldValue("CAMP_NAME", e.label);
              if(result.data.header && !result.data.header.payNowBtn){
                console.log("setField")
                setFieldValue("API_DATA.header.payNowBtn", {
                  value: "",
                  link: "",
                  style: {
                    color: "",
                    backgroundColor: "",
                    borderColor: "",
                  },
                  tooltip: "",
                });
                
                setData({
                  loaded: true,
                  initialValues: {
                    ...data.initialValues,
                    ...result.data,
                    API_DATA: {
                      header: {
                        ...data.initialValues.API_DATA.header,
                        ...result.data.header,
                        payNowBtn: {
                          value: "",
                          link: "",
                          style: {
                            color: "",
                            backgroundColor: "",
                            borderColor: "",
                          },
                          tooltip: "",
                        }
                      }
                    },
                    ACTION: "UPDATE",
                    CAMP_ID: campid,
                    CAMP_NAME: e.label,
                  },
                });
              } else {
             
                // ----------------------------------------------------------------------------
                result.data.header.style = initialValues.API_DATA.header.style
                // ---------------------------------------------------------------------------
                setData({
                  loaded: true,
                  initialValues: {
                    ...data.initialValues,
                    ...result.data,
                    ACTION: "UPDATE",
                    CAMP_ID: campid,
                    CAMP_NAME: e.label,
                  },
                });
              }


            })
            .catch((err) => {
              formik.resetForm();
              setData({ ...data, loaded: true });
              setFieldValue("CAMP_ID", campid);
              setFieldValue("CAMP_NAME", e.label);
              console.log(err);
            });
        };
        return (
          <>
            <div
              className={data.loaded ? "col-md-8 mx-auto" : "col-md-12 mx-auto"}
            >
              <div className="card shadow-lg">
                <div className="card-body">
                  <h6 className="card-title">Customer profile Page - Header</h6>
                  <Form className="forms-sample">
                    {success && (
                      <div className="alert alert-success">{success}</div>
                    )}
                    {warning && (
                      <div className="alert alert-warning">{warning}</div>
                    )}
                    <Controller
                      control="multiSelect"
                      label="Campaign Name"
                      value={formik.values.campid}
                      name="campid"
                      isMulti={false}
                      formik={formik}
                      options={campOption}
                      onChange={getCompData}
                      wrapperClass={
                        formik.errors.campid && formik.touched.campid
                          ? "has-error"
                          : null
                      }
                    />
                    {data.loaded && (
                      <>
                        <fieldset>
                          <legend>Vendor</legend>
                          <Controller
                            control="input"
                            label="Campaign Logo"
                            value={formik.values.API_DATA.header.vendorLogo}
                            name="API_DATA.header.vendorLogo"
                            wrapperClass={
                              formik.errors.API_DATA &&
                              formik.errors.API_DATA.header &&
                              formik.errors.API_DATA.header.vendorLogo &&
                              formik.touched.API_DATA &&
                              formik.touched.API_DATA.header &&
                              formik.touched.API_DATA.header.vendorLogo
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="input"
                            label="Campaign Logo Link"
                            value={formik.values.API_DATA.header.logoLink}
                            name="API_DATA.header.logoLink"
                            wrapperClass={
                              formik.errors.API_DATA &&
                              formik.errors.API_DATA.header &&
                              formik.errors.API_DATA.header.logoLink &&
                              formik.touched.API_DATA &&
                              formik.touched.API_DATA.header &&
                              formik.touched.API_DATA.header.logoLink
                                ? "has-error"
                                : null
                            }
                          />

                          <Style
                            defaultValue={["API_DATA", "header", "style"]}
                            name="API_DATA.header.style"
                            styleName="Header Style"
                            formik={formik}
                          />
                        </fieldset>
                        <fieldset>
                          <legend>Exit Button</legend>
                          <Controller
                            control="input"
                            label="Button Text"
                            value={formik.values.API_DATA.header.exitBtn.value}
                            name="API_DATA.header.exitBtn.value"
                            wrapperClass={
                              formik.errors.API_DATA &&
                                formik.errors.API_DATA.header &&
                                formik.errors.API_DATA.header.exitBtn &&
                                formik.errors.API_DATA.header.exitBtn.value &&
                                formik.touched.API_DATA &&
                                formik.touched.API_DATA.header &&
                                formik.touched.API_DATA.header.exitBtn &&
                                formik.touched.API_DATA.header.exitBtn.value
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="input"
                            label="Tooltip"
                            value={
                              formik.values.API_DATA.header.exitBtn.tooltip
                            }
                            name="API_DATA.header.exitBtn.tooltip"
                            wrapperClass={
                              formik.errors.API_DATA &&
                              formik.errors.API_DATA.header &&
                              formik.errors.API_DATA.header.exitBtn &&
                              formik.errors.API_DATA.header.exitBtn.tooltip &&
                              formik.touched.API_DATA &&
                              formik.touched.API_DATA.header &&
                              formik.touched.API_DATA.header.exitBtn &&
                              formik.touched.API_DATA.header.exitBtn.tooltip
                                ? "has-error"
                                : null
                            }
                          />
                          <Controller
                            control="input"
                            label="Url"
                            value={formik.values.API_DATA.header.exitBtn.url}
                            name="API_DATA.header.exitBtn.url"
                            wrapperClass={
                              formik.errors.API_DATA &&
                              formik.errors.API_DATA.header &&
                              formik.errors.API_DATA.header.exitBtn &&
                              formik.errors.API_DATA.header.exitBtn.url &&
                              formik.touched.API_DATA &&
                              formik.touched.API_DATA.header &&
                              formik.touched.API_DATA.header.exitBtn &&
                              formik.touched.API_DATA.header.exitBtn.url
                                ? "has-error"
                                : null
                            }
                          />
                          <Style
                            defaultValue={[
                              "API_DATA",
                              "header",
                              "exitBtn",
                              "style",
                            ]}
                            name="API_DATA.header.exitBtn.style"
                            styleName="Style"
                            formik={formik}
                          />
                        </fieldset>

                        <Style
                          defaultValue={["API_DATA", "header", "bodyStyle"]}
                          name="API_DATA.header.bodyStyle"
                          styleName="Body Style"
                          formik={formik}
                        />
                          <fieldset>
                            <legend>Pay Now Button</legend>
                            <Controller
                              control="input"
                              label="Text"
                              value={
                                formik.values.API_DATA.header.payNowBtn ? formik.values.API_DATA.header.payNowBtn
                                  .value : ""
                              }
                              name="API_DATA.header.payNowBtn.value"
                            />
                            <Controller
                              control="input"
                              label="Link"
                              value={
                                formik.values.API_DATA.header.payNowBtn ?formik.values.API_DATA.header.payNowBtn
                                  .link : ""
                              }
                              name="API_DATA.header.payNowBtn.link"
                            />
                            <Controller
                              control="input"
                              label="Tooltip"
                              value={
                                formik.values.API_DATA.header.payNowBtn ? formik.values.API_DATA.header.payNowBtn
                                  .tooltip : ""
                              }
                              name="API_DATA.header.payNowBtn.tooltip"
                            />
                            
                            <Style
                              defaultValue={[
                                "API_DATA",
                                "header",
                                "payNowBtn",
                                "style",
                              ]}
                              name="API_DATA.header.payNowBtn.style"
                              styleName="Style"
                              formik={formik}
                            />
                          </fieldset>
                        <div className="box-footer text-right">
                          <button
                            type="button"
                            className="btn btn-rounded btn-warning btn-outline mr-1"
                            onClick={() => history.goBack()}
                          >
                            <i className="fa fa-trash-o"></i> Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-rounded btn-primary btn-outline"
                          >
                            {formik.isSubmitting ? (
                              <i className="fa fa-spin fa-refresh"></i>
                            ) : (
                              <i className="fa fa-fw fa-save"></i>
                            )}{" "}
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>
            {data.loaded && (
              <div
                className="col-md-4"
                style={{ position: "relative", display: "block" }}
              >
                <div className="card position-fixed w-25 shadow-lg p-3">
                  <HeaderPreview ui={formik.values.API_DATA} />
                </div>
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
}

export default Header;
