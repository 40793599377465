import React from "react";

function Feature({ ui,preview }) {
  return (
    <div className="container-fluid py-5 px-5 text-center" style={ui.style}>
      <h3 style={ui.header.style}>{ui.header.text}</h3>
      <p style={ui.content.style}>{ui.content.text}</p>
      <div className="row pt-5">
        {ui.cards.map((item, index) => (
          <div className={preview?"col-6":"col-sm-3 col-6 p-3 col-lg-3 col-md-3 col-xl-3"}>
            <div className="card shadow-sm">
              <div className="card-body text-center">
                <img
                  className="w-50 py-2"
                  src={item.image}
                ></img>
                <h4 style={item.heading.style}>{item.heading.text}</h4>
                <p style={item.content.style}>
                {item.content.text}
                </p>
              </div>
            </div>
          </div>
        ))}
        
        
      </div>
    </div>
  );
}

export default Feature;
