import React, { useState } from "react";
import { useEffect } from "react";
import Controller from "../../Component/controller/Controller";
import * as Yup from "yup";
import { Formik } from "formik";
import { createYupSchema} from "../../utils/function";

function Banner({ ui, preview,onSubmit}) {
  const [forms, setforms] = useState([]);
  const [validateSchema, setSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const createParentYupSchema = (schema, object) => {
    const yepSchema = object.fields.reduce(createYupSchema, {});
     schema[object.sectionName] = Yup.object(yepSchema);
     return schema;
  };

  useEffect(() => {
    const initValues = {};
    if (ui.forms.length > 0) {
      ui.forms.forEach((section) => {
        initValues[section.sectionName] = {};
        section.fields.forEach((item) => {
          initValues[section.sectionName][item.name] = item.value || "";
        });
      });
    }
    const yepSchema = ui.forms.reduce(createParentYupSchema, {});
    setInitialValues(initValues);
    setSchema(Yup.object().shape(yepSchema));
    setforms(ui.forms);
  }, [ui]);
  
  return (
    <div
      className="container-fuild w-100"
      style={{
        ...ui.style,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="container bg-overlay">
        <div className="row align-items-center p-2">
          <div
            className={
              preview
                ? "col-12 p-1"
                : "col-12 col-lg-7 col-xl-7 col-sm-7 text-white p-1"
            }
          >
            <h1 className="valign-middle" style={ui.heading.style}>
              {ui.heading.text}
            </h1>
            <p style={ui.description.style}>{ui.description.text}</p>
          </div>
          {forms.length > 0 && (
            <div
              className={
                preview ? "col-12 p-1" : "col-12 col-lg-5 col-xl-5 col-sm-5 p-1"
              }
            >
              <div className="card" style={ui.formStyle}>
                <div className="card-body">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validateSchema}
                    onSubmit={onSubmit}
                  >
                    {(formik) => {
                      console.log("formik from preview", formik);
                      console.log("initial Vale", initialValues);
                      return (
                        <form
                          onSubmit={formik.handleSubmit}
                          style={{ borderRadius: "10px" }}
                        >
                          <div
                            className="form-wrapper"
                            style={{ height: "500px", overflowY: "auto" }}
                          >
                            {forms.map((section, sectionIndex) => (
                              <fieldset>
                                <legend>{section.sectionTitle}</legend>
                                {section.fields.map((item, index) => (
                                  <React.Fragment
                                    key={"form" + sectionIndex + index}
                                  >
                                    <Controller
                                      {...item}
                                      name={`${section.sectionName}[${item.name}]`}
                                      value={ formik.values[section.sectionName][
                                              item.name
                                            ]
                                      }
                                    />
                                  </React.Fragment>
                                ))}
                              </fieldset>
                            ))}
                          </div>
                          <div className="form-group text-right">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              style={ui.submitBtn.style}
                            >
                              {ui.submitBtn.text}
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Banner;
