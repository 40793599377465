import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios"
import Controller from "../shared/controller/Controller";
import { useHistory } from "react-router";
import config from "../../config";
import { useDispatch } from "react-redux";
import { saveUserInfo } from "../../redux/action/user";
import { ipStringToNum } from "../../utils/function";

function AdminLogin() {
  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState("");
  const dispatch = useDispatch();

  const initialValues = {
    userid: "",
    password: "",
  };
  const validationSchema = Yup.object({
    userid: Yup.string().required("user Id is required"),
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    axios
      .get(
        `https://ipapi.co/json/`
      )
      .then(({ data }) => {
        const ip = data.ip;
        const key = ipStringToNum(ip);
        axios
          .post(`${config.API_URL}/msdash/usersignIn/`, {...values,key})
          .then(({ data }) => {
            if (data.status === "Fail") {
              setWarning(data.message);
              setTimeout(() => {
                setWarning("");
              }, 3000);
            } else {
              dispatch(saveUserInfo(data));
              if (data.message === "User first Login") {
                window.location.replace("/dashboard/change-password");
              } else {
                window.location.replace("/dashboard");
              }
              localStorage.setItem("token", data.UserToken);
            }
            setLoading(false);
          })
          .catch((err) => {
            // console.log("ERRORRRRR: ", err)
            const msg =
              err.response && err.response.data
                ? err.response.data.message
                : err.message;
            setWarning(msg);
            setTimeout(() => {
              setWarning("");
            }, 5000);
            setLoading(false);
          });
      });
  };
  return (
    <div className="main-wrapper">
      <div
        className="page-wrapper full-page"
        style={{
          background:
            "linear-gradient(179deg, rgb(49, 164, 229), rgb(18, 95, 154))",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-3 col-xl-3 mx-auto col-lg-3">
              <div
                className="card shadow-lg"
                style={{ backgroundColor: "#f1f1f1" }}
              >
                <div className="row">
                  <div className="col-md-12 pl-md-0">
                    <div className="auth-form-wrapper px-5 py-4">
                      {warning && (
                        <div className="alert alert-danger">{warning}</div>
                      )}
                      <div
                        className="d-block mb-4 mx-auto d-flex justify-content-center align-items-center"
                        style={{
                          backgroundColor: "white",
                          width: "120px",
                          height: "120px",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src="../../images/lock.jpg"
                          width="120px"
                          alt=""
                        ></img>
                      </div>
                      <h5 className="text-muted font-weight-normal mb-4 text-white">
                        Hello Admin! Login in to your Account.
                      </h5>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {(formik) => {
                          return (
                            <Form className="forms-sample">
                              <Controller
                                control="input"
                                type="text"
                                name="userid"
                                label="User Id"
                                col="12"
                                placeholder="Enter User Id"
                                value={formik.values.userid}
                                wrapperClass={
                                  formik.errors.userid && formik.touched.userid
                                    ? "has-error"
                                    : null
                                }
                              />
                              <Controller
                                control="input"
                                type="password"
                                name="password"
                                label="Password"
                                placeholder="Enter Password"
                                col="12"
                                value={formik.values.password}
                                wrapperClass={
                                  formik.errors.password &&
                                  formik.touched.password
                                    ? "has-error"
                                    : null
                                }
                              />
                              <div className="mt-3">
                                <button
                                  className="btn btn-warning mr-2 mb-2 mb-md-0 text-white"
                                  type="submit"
                                >
                                  {loading ? (
                                    <i className="fa fa-spin fa-refresh"></i>
                                  ) : null}{" "}
                                  Login
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
