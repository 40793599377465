import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import config from "../../../config";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import InputArray from "../../shared/controller/InputArray";
import Input from "../../shared/controller/Input";
import { ipNumToString, ipStringToNum } from "../../../utils/function";

function IpList() {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [data, setData] = useState({
    loaded: false,
    initialValues: {
      ipList: [""],
    },
  });
  const { UserToken } = useSelector((state) => state.userInfo);
  const history = useHistory();
  useEffect(() => {
    axios
      .get(`${config.API_URL}/ms/getParamdata/MS_IP/IPCHECK`, {
        headers: { Authorization: UserToken },
      })
      .then((res) => {
        if (res.data.param_data) {
          setData({
            loaded: true,
            initialValues: {
              ipList: JSON.parse(res.data.param_data[0].PARA_VALUE).map((num) =>
                ipNumToString(num)
              ),
            },
          });
        } else {
          setData({ ...data, loaded: true });
        }
      })
      .catch((err) => {
        setData({ ...data, loaded: true });
      });
  }, []);

  const validationSchema = Yup.object({
    ipList: Yup.array(),
  });
  const arrayField = [{ label: "Ip", name: "ip", Control: Input }];

  const onSubmit = (values, actions) => {
    const data = JSON.stringify(
      values.ipList.map((dot) => 
        ipStringToNum(dot)
      )
    );
    axios
      .post(
        `${config.API_URL}/msdash/manageparameter`,
        {
          paracode: "MS_IP",
          paradescription: "IP SECURITY TEST",
          paravalue: data,
          paraprop: "IPCHECK",
          action_name: "UPDATE",
        },
        { headers: { Authorization: UserToken } }
      )
      .then((res) => {
        if (res.data && res.data.message && res.data.message === "SUCCESS") {
          setSuccess(res.data.message);
        } else {
          setWarning(res.data.message);
        }
        setTimeout(() => {
          setSuccess("");
          setWarning("");
        }, 5000);
        actions.setSubmitting(false);
      })
      .catch((err) => {
        const msg =
          err.resonse && err.resonse.data
            ? err.response.data.message
            : err.message;
        setWarning(msg);
        setTimeout(() => {
          setWarning("");
        }, 5000);
        actions.setSubmitting(false);
      });
  };
  // console.log(data);
  return (
    <>
      {data.loaded && (
        <Formik
          initialValues={data.initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            console.log(formik);
            return (
              <>
                <div className="col-md-8 mx-auto">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <h6 className="card-title">Ip List</h6>
                      <Form className="forms-sample">
                        {success && (
                          <div className="alert alert-success">{success}</div>
                        )}
                        {warning && (
                          <div className="alert alert-warning">{warning}</div>
                        )}

                        <>
                          <InputArray
                            legend="Ip List"
                            name="ipList"
                            arrayField={arrayField}
                            defaultValue={["ipList"]}
                            formik={formik}
                            buttonText="Add Ip"
                            isStringType={true}
                          />

                          <div className="box-footer text-right">
                            <button
                              type="button"
                              className="btn btn-rounded btn-warning btn-outline mr-1"
                              onClick={() => history.goBack()}
                            >
                              <i className="fa fa-trash-o"></i> Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-rounded btn-primary btn-outline"
                            >
                              {formik.isSubmitting ? (
                                <i className="fa fa-spin fa-refresh"></i>
                              ) : (
                                <i className="fa fa-fw fa-save"></i>
                              )}{" "}
                              Save
                            </button>
                          </div>
                        </>
                      </Form>
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
}

export default IpList;
