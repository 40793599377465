export default {
  hero: {
    style: {
      background:
        "linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.7)), url('https://www.traviss.com/img/about-banner.jpg')",
    },
    heading: {
      text: "Hello Microsite",
      style: {
        color: "",
        fontSize: "",
      },
    },
    description: {
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
        enim ad minim veniam, quis nostrud exercitation ullamco laboris
        nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
        reprehenderit in voluptate velit esse cillum dolore eu fugiat
        nulla pariatur. Excepteur sint occaecat cupidatat non proident,
        sunt in culpa qui officia deserunt mollit anim id est laborum.`,
      style: {
        color: "",
        fontSize: "",
      },
    },
    forms: {
      0: {
        control: "input",
        type: "text",
        label: "name",
        options: {},
        placeholder: "name Placeholder",
        name: "name ",
        value: "",
        validationType: "string",
        validations: {
          0: { type: "required", params: { 0: "name is required." } },
        },
      },
      1: {
        control: "input",
        type: "date",
        label: "Date",
        placeholder: "name Placeholder",
        options: {},
        name: "Date ",
        value: "",
        validationType: "string",
        validations: {
          0: { type: "required", params: { 0: "Date is required." } },
        },
      },
    },
    formStyle: {
      background: "",
    },
    submitBtn: {
      text: "Send Now",
      style: {
        background: "#4250f2",
        borderRadius: "#4250f2",
      },
    },
  },
  feature: {
    style: {
      background: "",
    },
    header: {
      text: "FEATURES AND SPECIFICATIONS",
      style: {
        color: "",
        fontSize: "",
      },
    },
    content: {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
      style: {
        color: "",
        fontSize: "",
      },
    },
    cards: {
      0: {
        style: {
          background: "",
        },
        image:
          "https://i2.wp.com/innov8edu.me/wp-content/uploads/2019/10/lightbulb-icon.png?ssl=1",
        heading: {
          text: "Heading",
          style: {
            color: "",
            fontSize: "",
          },
        },
        content: {
          text: "Hello this is the paragraph line. Please edit it ccording to you.",
          style: {
            color: "",
            fontSize: "",
          },
        },
      },
      1: {
        style: {
          background: "",
        },
        image:
          "https://i2.wp.com/innov8edu.me/wp-content/uploads/2019/10/lightbulb-icon.png?ssl=1",
        heading: {
          text: "Heading",
          style: {
            color: "",
            fontSize: "",
          },
        },
        content: {
          text: "Hello this is the paragraph line. Please edit it ccording to you.",
          style: {
            color: "",
            fontSize: "",
          },
        },
      },
      2: {
        style: {
          background: "",
        },
        image:
          "https://i2.wp.com/innov8edu.me/wp-content/uploads/2019/10/lightbulb-icon.png?ssl=1",
        heading: {
          text: "Heading",
          style: {
            color: "",
            fontSize: "",
          },
        },
        content: {
          text: "Hello this is the paragraph line. Please edit it ccording to you.",
          style: {
            color: "",
            fontSize: "",
          },
        },
      },
      3: {
        style: {
          background: "",
        },
        image:
          "https://i2.wp.com/innov8edu.me/wp-content/uploads/2019/10/lightbulb-icon.png?ssl=1",
        heading: {
          text: "Heading",
          style: {
            color: "",
            fontSize: "",
          },
        },
        content: {
          text: "Hello this is the paragraph line. Please edit it ccording to you.",
          style: {
            color: "",
            fontSize: "",
          },
        },
      },
    },
  },
  bottom: {
    style: {
      background: "",
    },
    leftSide:{
        style:{
            background:""
        },
        img: "https://i.pinimg.com/originals/77/75/5e/77755e565ef7ddbff2546231cd8732bf.png"
    },
    rightSide: {
        style:{
            background:""
        },
      header: {
        text: "Heading",
        style: {
          color: "",
          fontSize: "",
        },
      },
      content: {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        style: {
          color: "",
          fontSize: "",
        },
      },
    },
  },
};
