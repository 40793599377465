import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Controller from "../../shared/controller/Controller";
import Style from "../../shared/controller/Style";
import config from "../../../config";
import FeedbackPreview from "../preview/FeedbackPreview";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { fetchCampNameList } from "../../../redux/action/campaign";
import FieldComponent from "./feedbackComponents/FieldComponent";
import Card from "./landingPageComponent/Card";
import LandingPagePreview from "../preview/LandingPagePreview";
import { transformForm } from "../../../utils/function";

function LandingPage() {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [data, setData] = useState({
    loaded: false,
    initialValues: {
      ACTION: "ADD",
      CAMP_ID: "",
      CAMP_NAME: "",
      COMP_ID: 7,
      ACTIVE: 1,
      DB_OBJECT: "",
      LIST_ID: "null",
      HUNTGROUP: "null",
      DIALER_IP: "null",
      OTP_ENABLE: 0,
      API_DATA: {
        logo: "",
        footer: {
          style: {
            backgroundColor: "",
          },
          text: {
            value: "",
            style: {
              fontSize: "",
              color: "",
            },
          },
        },

        hero: {
          style: {
            background:
              "linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.7)), url('https://www.traviss.com/img/about-banner.jpg')",
          },
          heading: {
            text: "",
            style: {
              color: "",
              fontSize: "",
            },
          },
          description: {
            text: ``,
            style: {
              color: "",
              fontSize: "",
            },
          },
          forms: [],
          formStyle: {
            backgroundColor: "",
          },
          submitBtn: {
            text: "",
            style: {
              backgroundColor: "",
              borderColor: "",
              borderRadius: "",
            },
          },
        },
        feature: {
          style: {
            backgroundColor: "",
          },
          header: {
            text: "",
            style: {
              color: "",
              fontSize: "",
            },
          },
          content: {
            text: "",
            style: {
              color: "",
              fontSize: "",
            },
          },
          cards: [],
        },
        bottom: {
          style: {
            backgroundColor: "",
          },
          leftSide: {
            style: {
              backgroundColor: "",
            },
            img: "",
          },
          rightSide: {
            style: {
              backgroundColor: "",
            },
            header: {
              text: "",
              style: {
                color: "",
                fontSize: "",
              },
            },
            content: {
              text: "",
              style: {
                color: "",
                fontSize: "",
              },
            },
          },
        },
      },
    },
  });
  const { campNames } = useSelector((state) => state.campName);
  const { UserToken } = useSelector((state) => state.userInfo);
  const history = useHistory();
  const dispatch = useDispatch();
  let campOption = [];
  useEffect(() => {
    if (campNames && campNames.length === 0) {
      dispatch(fetchCampNameList(UserToken));
    }
  }, []);
  if (campNames && campNames.length > 0) {
    campOption = campNames.map((item) => {
      return {
        label: item.campname,
        value: item.campid,
      };
    });
  }
  const validationSchema = Yup.object({
    API_DATA: Yup.object(),
  });

  const onSubmit = (values, actions) => {
    // console.log(values);
    axios
      .post(`${config.API_URL}/msdash/managecomponent/`, values, {
        headers: { Authorization: UserToken },
      })
      .then((res) => {
        setSuccess(res.data.message);
        window.scroll(0, 0);
        actions.setSubmitting(false);
        console.log(res);
      })
      .catch((err) => {
        setWarning(err.message);
        actions.setSubmitting(false);
        console.log(err);
      });
  };

  return (
    <Formik
      initialValues={data.initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        console.log("values", formik.values);
        const { setFieldValue } = formik;
        const getCompData = (e) => {
          const campid = e.value;
          axios
            .get(`${config.API_URL}/ms/getcompdata/${campid}/7`)
            .then((result) => {
              const apiData = {
                ...result.data,
                hero: {
                  ...result.data.hero,
                  forms: Object.values(result.data.hero.forms).map((item) => ({
                    ...item,
                    fields: transformForm(item.fields),
                  })),
                },
                feature: {
                  ...result.data.feature,
                  cards: Object.values(result.data.feature.cards),
                },
              };
              setData({
                loaded: true,
                initialValues: {
                  ...data.initialValues,
                  ...result.data,
                  API_DATA: apiData,
                  ACTION: "UPDATE",
                  CAMP_ID: campid,
                  CAMP_NAME: e.label,
                },
              });
              setFieldValue("API_DATA", apiData);
              setFieldValue("ACTION", "UPDATE");
              setFieldValue("CAMP_ID", campid);
              setFieldValue("CAMP_NAME", e.label);
            })
            .catch((err) => {
              formik.resetForm();
              setData({ ...data, loaded: true });
              setFieldValue("CAMP_ID", campid);
              setFieldValue("CAMP_NAME", e.label);
              console.log(err);
            });
        };
        return (
          <>
            <div className={"col-md-8 mx-auto"}>
              <div className="card shadow-lg">
                <div className="card-body">
                  <h6 className="card-title"> Landing Page</h6>
                  <Form className="forms-sample">
                    {success && (
                      <div className="alert alert-success">{success}</div>
                    )}
                    {warning && (
                      <div className="alert alert-warning">{warning}</div>
                    )}
                    <Controller
                      control="multiSelect"
                      label="Campaign Name"
                      value={formik.values.campid}
                      name="campid"
                      isMulti={false}
                      formik={formik}
                      options={campOption}
                      onChange={getCompData}
                      wrapperClass={
                        formik.errors.campid && formik.touched.campid
                          ? "has-error"
                          : null
                      }
                    />
                    {data.loaded && (
                      <>
                        <Controller
                          control="input"
                          label="Logo"
                          value={formik.values.API_DATA.logo}
                          name="API_DATA.logo"
                        />
                        <fieldset>
                          <legend>Top Section</legend>
                          <Style
                            defaultValue={["API_DATA", "hero", "style"]}
                            name="API_DATA.hero.style"
                            styleName="Section Background Style"
                            formik={formik}
                          />
                          <Controller
                            control="input"
                            label="Heading Text"
                            value={formik.values.API_DATA.hero.heading.text}
                            name="API_DATA.hero.heading.text"
                          />
                          <Style
                            defaultValue={[
                              "API_DATA",
                              "hero",
                              "heading",
                              "style",
                            ]}
                            name="API_DATA.hero.heading.style"
                            styleName="Heading Style"
                            formik={formik}
                          />
                          <Controller
                            control="textArea"
                            label="Description Text"
                            value={formik.values.API_DATA.hero.description.text}
                            name="API_DATA.hero.description.text"
                          />
                          <Style
                            defaultValue={[
                              "API_DATA",
                              "hero",
                              "description",
                              "style",
                            ]}
                            name="API_DATA.hero.description.style"
                            styleName="Description Style"
                            formik={formik}
                          />
                          <FieldArray
                            name="API_DATA.hero.forms"
                            render={(parentArrayHelper) => {
                              const forms = formik.values.API_DATA.hero.forms;
                              return (
                                <>
                                  {forms.length > 0 ? (
                                    <fieldset>
                                      <legend>Form Section</legend>
                                      {forms.map((section, sectionIndex) => (
                                        <>
                                          <Controller
                                            control="input"
                                            label="Section TItle"
                                            value={section.sectionTitle}
                                            name={`API_DATA.hero.forms[${sectionIndex}].sectionTitle`}
                                          />
                                          <Controller
                                            control="input"
                                            label="Section Name"
                                            value={section.sectionName}
                                            name={`API_DATA.hero.forms[${sectionIndex}].sectionName`}
                                          />

                                          <FieldArray
                                            name={`API_DATA.hero.forms[${sectionIndex}].fields`}
                                            render={(arrayHelper) => {
                                              return (
                                                <>
                                                  {section.fields.length > 0 ? (
                                                    section.fields.map(
                                                      (item, index) => {
                                                        return (
                                                          <FieldComponent
                                                            {...item}
                                                            name={`API_DATA.hero.forms[${sectionIndex}].fields[${index}]`}
                                                            formIndex={index}
                                                            formik={formik}
                                                            arrayHelper={
                                                              arrayHelper
                                                            }
                                                            form={
                                                              formik.values
                                                                .API_DATA.hero
                                                                .forms[
                                                                sectionIndex
                                                              ].fields[index]
                                                            }
                                                          />
                                                        );
                                                      }
                                                    )
                                                  ) : (
                                                    <button
                                                      type="button"
                                                      className="btn btn-rounded btn-dark btn-outline mr-1 mb-5"
                                                      onClick={() =>
                                                        arrayHelper.push({
                                                          control: "input",
                                                          type: "text",
                                                          label: "",
                                                          options: [],
                                                          placeholder: "",
                                                          name: "",
                                                          value: "",
                                                          validationType: "",
                                                          validations: [],
                                                        })
                                                      }
                                                    >
                                                      <i className="ti-plus"></i>{" "}
                                                      Add Field
                                                    </button>
                                                  )}
                                                </>
                                              );
                                            }}
                                          />
                                          <div className="row text-center">
                                            <div className="col-12">
                                              <div
                                                className="btn-group"
                                                role="group"
                                                aria-label="Basic example"
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-secondary mr-1"
                                                  onClick={() =>
                                                    parentArrayHelper.remove(
                                                      sectionIndex
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-trash" />{" "}
                                                  Delete Section
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-dark"
                                                  onClick={() =>
                                                    parentArrayHelper.insert(
                                                      sectionIndex + 1,
                                                      {
                                                        sectionTitle: "",
                                                        sectionName: "",
                                                        fields: [],
                                                      }
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-plus" />{" "}
                                                  Add Section
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                    </fieldset>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-rounded btn-dark btn-outline mr-1 mb-5"
                                      onClick={() =>
                                        parentArrayHelper.push({
                                          sectionName: "",
                                          sectionTitle: "",
                                          fields: [],
                                        })
                                      }
                                    >
                                      <i className="ti-plus"></i> Add Form Section
                                    </button>
                                  )}
                                </>
                              );
                            }}
                          />

                          <Style
                            defaultValue={["API_DATA", "hero", "formStyle"]}
                            name="API_DATA.hero.formStyle"
                            styleName="Form Style"
                            formik={formik}
                          />
                          <Controller
                            control="input"
                            label="Submit Button Text"
                            value={formik.values.API_DATA.hero.submitBtn.text}
                            name="API_DATA.hero.submitBtn.text"
                          />
                          <Style
                            defaultValue={[
                              "API_DATA",
                              "hero",
                              "submitBtn",
                              "style",
                            ]}
                            name="API_DATA.hero.submitBtn.style"
                            styleName="submit Button Style"
                            formik={formik}
                          />
                        </fieldset>
                        <fieldset>
                          <legend>Feature Section</legend>
                          <Style
                            defaultValue={["API_DATA", "feature", "style"]}
                            name="API_DATA.feature.style"
                            styleName="Section Background Style"
                            formik={formik}
                          />
                          <Controller
                            control="input"
                            label="Heading Text"
                            value={formik.values.API_DATA.feature.header.text}
                            name="API_DATA.feature.header.text"
                          />
                          <Style
                            defaultValue={[
                              "API_DATA",
                              "feature",
                              "header",
                              "style",
                            ]}
                            name="API_DATA.feature.header.style"
                            styleName="Header Style"
                            formik={formik}
                          />
                          <Controller
                            control="textArea"
                            label="Content Text"
                            value={formik.values.API_DATA.feature.content.text}
                            name="API_DATA.feature.content.text"
                          />
                          <Style
                            defaultValue={[
                              "API_DATA",
                              "feature",
                              "content",
                              "style",
                            ]}
                            name="API_DATA.feature.content.style"
                            styleName="Content Style"
                            formik={formik}
                          />
                          <FieldArray
                            name="API_DATA.feature.cards"
                            render={(arrayHelper) => {
                              const cards =
                                formik.values.API_DATA.feature.cards;
                              return (
                                <>
                                  {cards.length > 0 ? (
                                    cards.map((item, index) => {
                                      return (
                                        <Card
                                          {...item}
                                          name={`API_DATA.feature.cards[${index}]`}
                                          cardIndex={index}
                                          formik={formik}
                                          arrayHelper={arrayHelper}
                                          card={
                                            formik.values.API_DATA.feature
                                              .cards[index]
                                          }
                                        />
                                      );
                                    })
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-rounded btn-dark btn-outline mr-1 mb-5"
                                      onClick={() =>
                                        arrayHelper.push({
                                          style: {
                                            backgroundColor: "",
                                          },
                                          image: "",
                                          heading: {
                                            text: "",
                                            style: {
                                              color: "",
                                              fontSize: "",
                                            },
                                          },
                                          content: {
                                            text: "",
                                            style: {
                                              color: "",
                                              fontSize: "",
                                            },
                                          },
                                        })
                                      }
                                    >
                                      <i className="ti-plus"></i> Add Card
                                    </button>
                                  )}
                                </>
                              );
                            }}
                          />
                        </fieldset>
                        <fieldset>
                          <legend>Bottom Section</legend>
                          <Style
                            defaultValue={["API_DATA", "bottom", "style"]}
                            name="API_DATA.bottom.style"
                            styleName="Section Background Style"
                            formik={formik}
                          />
                          <fieldset>
                            <legend>Left Section</legend>
                            <Style
                              defaultValue={[
                                "API_DATA",
                                "bottom",
                                "leftSide",
                                "style",
                              ]}
                              name="API_DATA.bottom.leftSide.style"
                              styleName="Section Background Style"
                              formik={formik}
                            />
                            <Controller
                              control="input"
                              label="Left Side Image"
                              value={formik.values.API_DATA.bottom.leftSide.img}
                              name="API_DATA.bottom.leftSide.img"
                            />
                          </fieldset>
                          <fieldset>
                            <legend>Right Section</legend>
                            <Style
                              defaultValue={[
                                "API_DATA",
                                "bottom",
                                "rightSide",
                                "style",
                              ]}
                              name="API_DATA.bottom.rightSide.style"
                              styleName="Section Background Style"
                              formik={formik}
                            />
                            <Controller
                              control="input"
                              label="Heading Text"
                              value={
                                formik.values.API_DATA.bottom.rightSide.header
                                  .text
                              }
                              name="API_DATA.bottom.rightSide.header.text"
                            />
                            <Style
                              defaultValue={[
                                "API_DATA",
                                "bottom",
                                "rightSide",
                                "header",
                                "style",
                              ]}
                              name="API_DATA.bottom.rightSide.header.style"
                              styleName="Header Style"
                              formik={formik}
                            />
                            <Controller
                              control="textArea"
                              label="Content Text"
                              value={
                                formik.values.API_DATA.bottom.rightSide.content
                                  .text
                              }
                              name="API_DATA.bottom.rightSide.content.text"
                            />
                            <Style
                              defaultValue={[
                                "API_DATA",
                                "bottom",
                                "rightSide",
                                "content",
                                "style",
                              ]}
                              name="API_DATA.bottom.rightSide.content.style"
                              styleName="Content Style"
                              formik={formik}
                            />
                          </fieldset>
                        </fieldset>
                        <fieldset>
                          <legend>Footer</legend>
                          <Style
                            defaultValue={["API_DATA", "footer", "style"]}
                            name="API_DATA.footer.style"
                            styleName="Footer Style"
                            formik={formik}
                          />
                          <fieldset>
                            <legend>Footer Text</legend>
                            <Controller
                              control="input"
                              label="Text"
                              value={
                                formik.values.API_DATA.footer.text.value
                              }
                              name="API_DATA.footer.text.value"
                            />
                            <Style
                            defaultValue={["API_DATA", "footer","text", "style"]}
                            name="API_DATA.footer.text.style"
                            styleName="Text Style"
                            formik={formik}
                          />
                          </fieldset>
                        </fieldset>
                        <div className="box-footer text-right">
                          <button
                            type="button"
                            className="btn btn-rounded btn-warning btn-outline mr-1"
                            onClick={() => history.goBack()}
                          >
                            <i className="fa fa-trash-o"></i> Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-rounded btn-primary btn-outline"
                          >
                            {formik.isSubmitting ? (
                              <i className="fa fa-spin fa-refresh"></i>
                            ) : (
                              <i className="fa fa-fw fa-save"></i>
                            )}{" "}
                            Save
                          </button>
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </div>

            {data.loaded && (
              <div className="col-md-4" style={{ display: "block" }}>
                <div
                  className="card position-fixed w-25 shadow-lg p-3 "
                  style={{ margin: "auto 0px", height: "700px" }}
                >
                  <LandingPagePreview data={formik.values.API_DATA} />
                </div>
              </div>
            )}
          </>
        );
      }}
    </Formik>
  );
}

export default LandingPage;
