import React from 'react'
import Footer from '../component/Footer'



function FooterPreview({ui}) {
    return (
        <footer
            className="footer d-flex flex-column flex-md-row align-items-center justify-content-center"
            style={ui.footer.style}
        >            
            <p className="text-center text-md-left" style={ui.footer.text.style}>{ui.footer.text.value}</p>
        </footer>
    )
}

export default FooterPreview
// 9560474433