import Axios from "axios";
import config from "../../config";
import {
    CAMPNAME_LIST_FAIL,
    CAMPNAME_LIST_REQUEST,
    CAMPNAME_LIST_SUCCESS,
  CAMP_LIST_FAIL,
  CAMP_LIST_REQUEST,
  CAMP_LIST_SUCCESS,
} from "../constant/campaign";

const fetchCampList = (UserToken) => async (dispatch) => {
  try {
    dispatch({ type: CAMP_LIST_REQUEST });
    const data = await Axios.get(`${config.API_URL}/msdash/getcampall/`,{
      headers: { Authorization: UserToken },
    });
    const stores = Object.values(data.data);
    dispatch({ type: CAMP_LIST_SUCCESS, payload: stores });
  } catch (err) {
    dispatch({ type: CAMP_LIST_FAIL, payload: err });
    // console.log(err);
  }
};

const fetchCampNameList = (token) => async (dispatch) => {
  try {
    dispatch({ type: CAMPNAME_LIST_REQUEST });
    const data = await Axios.get(`${config.API_URL}/msdash/getcamp/`, {
      headers: { Authorization: token },
    });
    const campNames = Object.values(data.data);
    dispatch({ type: CAMPNAME_LIST_SUCCESS, payload: campNames });
  } catch (err) {
    dispatch({ type: CAMPNAME_LIST_FAIL, payload: err });
    // console.log(err);
  }
};

export { fetchCampList,fetchCampNameList };
