import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Loader from "../../../Component/Loader";
import config from "../../../config";
import { fetchCampList } from "../../../redux/action/campaign";
import Table from "../../shared/Table";
import Error404 from "../ErrorPages/Error404";

function ViewCampaigns() {
  const editLink = "/edit-campaign/";
  const [message,setMessage]=useState("")
  const { loading, camps, error } = useSelector((state) => state.campList);
  const {UserToken,userlevel}=useSelector(state=>state.userInfo);
  const dispatch = useDispatch();
  const history=useHistory()
  if(userlevel && userlevel!=="103"){
    history.push("/dashboard/unauthorize")
  }
  const column = [
    { name: "Campid", value: "campid" },
    { name: "Camp Name", value: "campname" },
    {
      name: "isActive",
      value: "isactive",
      func: (col) => (col === "Y" ? "Yes" : "No"),
    },
    { name: "Edit", value: "userid", button: ["edit", "delete"] },
  ];
  useEffect(() => {
    if ((camps && camps.length === 0) || error) {
      dispatch(fetchCampList(UserToken));
    }
  }, []);
  const refreshData = () => {
    dispatch(fetchCampList(UserToken));
  };
  const deleteCamp = (camp) => {
    console.log(camp)
    axios.post(`${config.API_URL}/msdash/managecamp/`, {
      action_name: "DELETE",
      campid:camp.campid,
      campname:camp.campname
    },{headers:{Authorization:UserToken}})
    .then(res=>{
      setMessage(res.data.message)
      dispatch(fetchCampList(UserToken))
      setTimeout(()=>{setMessage("")},4000)
    })
    .catch(err=>{
      setMessage(()=>err.response && err.response.data ? err.response.data.message : err.message)
      setTimeout(()=>{setMessage("")},4000)
    })
  };
  return (
    <>
      {/* {!loading && camps && camps.length===0 && <NotFound msg="No camps Found."/>} */}
      {/* {loading && <Loader />} */}
      {message && <div className="alert alert-success">{message}</div>}
      {camps && camps.length > 0 ? (
        <Table
          column={column}
          data={camps}
          editLink={editLink}
          refresh={refreshData}
          title="View Campaign"
          idField="campid"
          deleteFunc={deleteCamp}
        />
      ) :loading? <Loader/>: (
        <Error404 />
      )}
    </>
  );
}

export default ViewCampaigns;
