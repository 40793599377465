import React, { useEffect, useState } from "react";
import Header from "../Component/Header";
import BodyWrapper from "../Component/BodyWrapper";
import Slider from "../Component/Slider";
import { fetchCompUi } from "../utils/function";
import Error from "../Component/Error";
import Call from "../Component/Call";
import Footer from "../Component/Footer";

function Home({ randKey, logOut, setMainError }) {
  const [header, setHeader] = useState({});
  const [footer, setFooter] = useState({});

  const [error, setError] = useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const setData = (data) => {
    setHeader(data);
  };
  const setfooterData = (data) => {
    setFooter(data);
  };
  // setMainError={setError}
  useEffect(() => {
    if (token) {
      fetchCompUi(2, setData, randKey, token, obj);
      fetchCompUi(8, setfooterData, randKey, token, obj);
    }
  }, []);
  const obj = {
    msg: "Header Component Loaded.",
    onError: (msg) => {
      setError(msg);
    },
  };
  return (
    <>
      {header && header.header && !error && (
        <div className="App" className="main-wrapper">
          <div className="page-wrapper" style={{ width: "100%", marginLeft: "0" }}>
            <Header randKey={randKey} ui={header} setMainError={setMainError} />
            <div className="page-content">
              <div className="grid-margin">
                <div className="row">
                  <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 grid-margin">
                    <Slider randKey={randKey} setError={setError} />
                    <BodyWrapper randKey={randKey} setError={setError} />
                  </div>

                  <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 grid-margin">
                    <Call randKey={randKey} setError={setError} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="area"
            style={{ ...header.header.bodyStyle, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
          >
            {/* <ul className="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li> 
              <li></li>
              <li></li>
              <li></li>
            </ul> */}
          </div>
        </div>
      )}
      {error && <Error msg={error} btn="reload" logOut={logOut} />}
      {footer && footer.footer && <Footer ui={footer.footer} />}
      {/* <Footer/> */}
    </>
  );
}

export default Home;
