import React from "react";

function Details({ ui,preview }) {
  // console.log("details", ui);
  return (
    <div className="container mb-3" style={ui.style}>
      <div className="row d-flex align-items-center">
        <div
          className={preview?"col-12":"col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6"}
          style={ui.leftSide.style}
        >
          <img src={ui.leftSide.img} className="w-100"></img>
        </div>
        <div
          className={preview?"col-12":"col-12 col-sm-6 col-xl-6 col-lg-6 col-md-6 "}
          style={ui.rightSide.style}
        >
          <h3 style={ui.rightSide.header.style}>{ui.rightSide.header.text}</h3>
          <p style={ui.rightSide.content.style}>{ui.rightSide.content.text}</p>
        </div>
      </div>
    </div>
  );
}

export default Details;
