import React, { useEffect } from "react";
import { eventLog } from "../utils/function";

function Viedeo({ ui,data,randKey, videoFirst, setVideoFirst }) {
  const videoArr=data.content.split(",");
  let arr=[];
  videoArr.forEach(item=>{
    const childArr=item.split("~");
    arr.push({name:childArr[0],link:childArr[1]})
  })
  // const arr = Object.keys(data.content);
  const arrLen = arr.length;
  const lg = arrLen === 1 ? "12" : arrLen === 2 ? "6" : "4";
  const md = arrLen === 1 ? "12" : "6";
  useEffect(()=>{
    if(videoFirst){
      const data = [
        {
          RAND_KEY: randKey,
          CUST_ID: ui.cust_id,
          CAMP_ID:ui.camp_id,
          STATUS: "Video Tab Clicked.",
        },
      ];

      eventLog(data);
      setVideoFirst(false)
    }
  },[])
  return (
    <div>
      <div className="card mt-4">
        <div className="card-body">
          <div className="row">
            {arr && arr.length>0 && arr.map((item,index) => (
              <div className={`col-lg-${lg} col-md-${md} col-12`} KEY={index}>
                <p className="text-center">{item.name}</p>
                {item.link.startsWith("https://www.youtube.com/") ? (
                    <iframe
                      width="100%"
                      height="231"
                      style={{borderRadius:"8px"}}
                      src={item.link}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  ) : (
                    <video style={cardStyle} controls>
                      <source src={item.link} />
                    </video>
                  )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Viedeo;

const cardStyle = {
  position: "relative",
  width: "100%",
  overflow: "hidden",
  borderRadius: "8px",
};
