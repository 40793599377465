import React from 'react'

function ValidationType(props) {
    const {value,setFieldValue,name, formIndex}=props;
    const changeHandler=(e)=>{
        setFieldValue(`${name}.validationType`,e.target.value)
    }
    return (
        <div className="form-group row">
        <div className="col-sm-3 col-lg-3 col-xl-3 col-12">
            <label>validation Type</label></div>
        <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
            <select value={value} onChange={changeHandler}>
                <option value="">None</option>
                <option value="string">String</option>
                <option value="number">Number</option>
                <option value="array">Array</option>
                <option value="object">Object</option>
            </select>
        </div>
        </div>
    )
}

export default ValidationType
