import React from "react";
import { FieldArray, Field } from "formik";

function Validations(props) {
  const { name, value, formIndex, setFieldValue, ...rest } = props;
  const validations = {
    required: [{ placeholder: "Error Message" }],
    email: [{ placeholder: "Error Message" }],
    max: [{ placeholder: "Length" }, { placeholder: "Error Message" }],
    min: [{ placeholder: "Length" }, { placeholder: "Error Message" }],
    matches: [{ placeholder: "Expression" }, { placeholder: "Error Message" }],
  };
  const onChangeValue = (e, index) => {
    const value = e.target.value;
    setFieldValue(`${name}[${index}].type`, value);
    setFieldValue(
      `${name}[${index}].params`,
      validations[value].map(() => "")
    );
  };
  return (
    <FieldArray
      name={name}
      render={(arrayHelper) => {
        return (
          <>
            {value.length > 0 ? (
              value.map((item, index) => (
                <div
                  className="form-group row bg-light my-3"
                  key={"validations" + formIndex + index}
                >
                  <div className="col-3">
                    <select
                      onChange={(e) => onChangeValue(e, index)}
                      value={item.type}
                    >
                      <option value="required">Required</option>
                      <option value="max">Max</option>
                      <option value="min">Min</option>
                      <option value="matches">Regex</option>
                    </select>
                  </div>
                  <div
                    className="col-9"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    {validations[item.type].map((paramItem, paramIndex) => (
                      <div
                        className="inline-form w-30"
                        key={`validations${formIndex}-${paramIndex}`}
                      >
                        <Field
                          className="form-control"
                          name={`${name}[${index}].params[${paramIndex}]`}
                          value={item.params[paramIndex]}
                          placeholder={paramItem.placeholder}
                        />
                      </div>
                    ))}

                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        className="btn btn-secondary mr-1"
                        onClick={() => arrayHelper.remove(index)}
                      >
                        <i className="fa fa-trash" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() =>
                          arrayHelper.insert(index + 1, {
                            type: "required",
                            params: [""],
                          })
                        }
                      >
                        <i className="fa fa-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="form-group row text-right">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-rounded btn-light btn-outline mr-1 mb-5"
                    onClick={() =>
                      arrayHelper.push({
                        type: "required",
                        params: [""],
                      })
                    }
                  >
                    <i className="ti-plus"></i> Add Validation
                  </button>
                </div>
              </div>
            )}
          </>
        );
      }}
    />
  );
}

export default Validations;
