import React from 'react'
import Banner from '../../../Pages/landingPage/Banner'
import Details from '../../../Pages/landingPage/Details'
import Feature from '../../../Pages/landingPage/Feature'
import Footer from '../../../Pages/landingPage/Footer'
import Header from '../../../Pages/landingPage/Header'
import LandingHeroPreview from './LandingHeroPreview'

function LandingPagePreview({data}) {
    // console.log("data",data.hero)
    return (
        <div className="card" style={{ width: "100%",height:"700px",overflowY:"scroll"}}>
            <div className="card-body" style={{ padding: "0.5rem" }}>   
                <Header  preview={true} logo={data.logo}/>
                <LandingHeroPreview ui={data.hero} preview={true}/>
                {/* <Banner ui={data.hero} preview={true}/> */}
                <Feature ui={data.feature} preview={true} />
                <Details ui={data.bottom} preview={true} />
                <Footer preview={true} ui={data.footer}/>
            </div>
        </div>
    )
}

export default LandingPagePreview
