
import React,{ useEffect } from "react";
import "./toggle.css";

function Toggle(props) {
  const { label,formik, value,name, ...rest } = props;

  useEffect(()=>{
    formik.setFieldValue(name,false)
  },[])
  const onChangeHandler=(e)=>{
      // console.log("checkbox",e.target.checked);
      formik.setFieldValue(name,e.target.checked)
      
  }
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name} className="d-block font-weight-bold">
          {label}
        </label>
      )}
      <div className="row" style={{display:"table-cell"}}>
        <div className="toggle-button-cover">
          <div className="button-cover">
            <div className="button r" id="button-1">
            {/* <Field  name={name} {...rest}
              value={false} type="checkbox" className="checkbox"/> */}
              <input type="checkbox" className="checkbox" onChange={onChangeHandler}/>
              <div className="knobs"></div>
              <div className="layer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Toggle;
