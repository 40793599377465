import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Controller from "../../shared/controller/Controller";
import { useHistory } from "react-router";
import config from "../../../config";
import { fetchCampList } from "../../../redux/action/campaign";
import { useDispatch, useSelector } from "react-redux";

function Campaign({ initialValues, type, _id }) {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { UserToken, userid } = useSelector((state) => state.userInfo);
  const validationSchema = Yup.object({
    campid: Yup.string().when("idRequire", {
      is: true,
      then: Yup.string().required("Id is required."),
    }),
    campname: Yup.string().required("Name is required"),
    isactive: Yup.string().required("Status is required"),
  });
  const onSubmit = async (values, { resetForm }) => {
    try {
      if (type === "post") {
        const {
          data: { message },
        } = await axios.post(`${config.API_URL}/msdash/managecamp/`, {...values,userid}, {
          headers: { Authorization: UserToken },
        });
        setSuccess(message);
        resetForm("");
        dispatch(fetchCampList(UserToken));
        window.scroll(0, 0);
      } else {
        const {
          data: { message },
        } = await axios.post(`${config.API_URL}/msdash/managecamp/`, {...values,userid}, {
          headers: { Authorization: UserToken },
        });
        setSuccess(message);
        dispatch(fetchCampList(UserToken));
        window.scroll(0, 0);
      }
      setTimeout(() => {
        setSuccess("");
      }, 4000);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setTimeout(() => {
        setWarning("");
      }, 4000);
    }
  };
  const options = [
    { value: "Y", label: "Yes" },
    { value: "N", label: "No" },
  ];

  return (
    <>
    <div className="col-md-8 mx-auto my-auto">
      <div className="card">
        <div className="card-body">
          <h6 className="card-title">Add Your Campaign Here</h6>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form className="forms-sample">
                  {success && (
                    <div className="alert alert-success">{success}</div>
                  )}
                  {warning && (
                    <div className="alert alert-warning">{warning}</div>
                  )}
                  <Controller
                    control="input"
                    type="text"
                    name="campid"
                    label="campaign Id"
                    placeholder="Enter campid"
                    value={formik.values.campid}
                    disabled={_id ? true : false}
                    wrapperClass={
                      formik.errors.campid && formik.touched.campid
                        ? "has-error"
                        : null
                    }
                  />

                  <Controller
                    control="input"
                    type="text"
                    name="campname"
                    label="Campaign Name"
                    placeholder="Enter campname"
                    value={formik.values.campname}
                    wrapperClass={
                      formik.errors.campname && formik.touched.campname
                        ? "has-error"
                        : null
                    }
                  />
                  <Controller
                    control="simpleSelect"
                    label="isactive"
                    name="isactive"
                    defaultValue={formik.values.isactive}
                    options={options}
                    wrapperClass={
                      formik.errors.isactive && formik.touched.isactive
                        ? "has-error"
                        : null
                    }
                  />
                  <div className="box-footer text-right">
                    <button
                      type="button"
                      className="btn btn-rounded btn-warning btn-outline mr-1"
                      onClick={() => history.goBack()}
                    >
                      <i className="fa fa-trash-o"></i> Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-rounded btn-primary btn-outline"
                    >
                      {formik.isSubmitting ? (
                        <i className="fa fa-spin fa-refresh"></i>
                      ) : (
                        <i className="fa fa-fw fa-save"></i>
                      )}{" "}
                      Save
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
   
    </>
  );
}

export default Campaign;
