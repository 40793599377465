import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { hash } from "bcryptjs";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Controller from "../shared/controller/Controller";
import { fetchUserList } from "../../redux/action/user";
import config from "../../config";

function ResetPassword() {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { userid, UserToken,userlevel } = useSelector((state) => state.userInfo);
  const userList = useSelector((store) => store.userList);
  const { loading, users, error } = userList;
  if(userlevel!=="103"){
    history.push("/dashboard/unauthorize")
  }
  let userOption=[];
  useEffect(() => {
    
    if ((users && users.length === 0)) {
      dispatch(fetchUserList(UserToken));
    }
  }, []);
  if(users && users.length>0){
    userOption=users.map(item=>{
        return {
            label:item.username,
            value:item.userid
        }
    })
  }
  const initialValues = {
    userid:"",
    userpwd: "",
    confirmpwd: "",
    pwdtype: "RESET_PASSWORD",
  };

  const validationSchema = Yup.object({

    userid: Yup.object().required("User Id is required"),
    userpwd: Yup.string()
      .required("Old Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmpwd: Yup.string()
      .required("Old Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .oneOf([Yup.ref("userpwd"), null], "Passwords must match"),
  });
  const onSubmit = async (values, { resetForm }) => {
      try {
        const { confirmpwd, ...data } = values;
        data.userid = data.userid.value;
        data.userpwd = await hash(data.userpwd, 10);
      axios
      .post(`${config.API_URL}/msdash/managepassword/`, data, {
        headers: { Authorization: UserToken },
      })
      .then((res) =>{ 
          // console.log(res)
          setSuccess(res.data.message);
          setTimeout(()=>{setSuccess("")},4000)
      })
      .catch((err) => {
          console.log(err)
          setWarning(err.message);
          setTimeout(()=>{setWarning("")},4000)
      });
    } catch (err) {
        console.log(err);
        setWarning(err.message);
        setTimeout(()=>{setWarning("")},4000)
    }
  };

  return (
    <div className="col-md-8 mx-auto">
      <div className="card">
        <div className="card-body">
          <h6 className="card-title">Reset Password</h6>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              console.log(formik);

              return (
                <Form className="forms-sample">
                  {success && (
                    <div className="alert alert-success">{success}</div>
                  )}
                  {warning && (
                    <div className="alert alert-warning">{warning}</div>
                  )}
                  <Controller
                      control="multiSelect"
                      label="User Name"
                      value={formik.values.userid}
                      name="userid"
                      isMulti={false}
                      formik={formik}
                      options={userOption}
                      wrapperClass={
                        formik.errors.userid && formik.touched.userid
                          ? "has-error"
                          : null
                      }
                    />
                  <Controller
                    control="input"
                    type="text"
                    name="userpwd"
                    label="Password"
                    placeholder="Enter Password"
                    value={formik.values.userpwd}
                    wrapperClass={
                      formik.errors.userpwd && formik.touched.userpwd
                        ? "has-error"
                        : null
                    }
                  />
                  <Controller
                    control="input"
                    type="password"
                    name="confirmpwd"
                    label="Confirm Password"
                    placeholder="Enter Confirm Password"
                    value={formik.values.confirmpwd}
                    wrapperClass={
                      formik.errors.confirmpwd && formik.touched.confirmpwd
                        ? "has-error"
                        : null
                    }
                  />
                  <div className="box-footer text-right">
                    <button
                      type="button"
                      className="btn btn-rounded btn-warning btn-outline mr-1"
                      onClick={() => history.goBack()}
                    >
                      <i className="fa fa-trash-o"></i> Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-rounded btn-primary btn-outline"
                    >
                      {formik.isSubmitting ? (
                        <i className="fa fa-spin fa-refresh"></i>
                      ) : (
                        <i className="fa fa-fw fa-save"></i>
                      )}{" "}
                      Save
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
