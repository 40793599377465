import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { hash } from "bcryptjs";
import Controller from "../../shared/controller/Controller";
import { useHistory } from "react-router";
import config from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserList } from "../../../redux/action/user";
import { fetchCampList } from "../../../redux/action/campaign";

function User({ initialValues, type, title, _id }) {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { camps } = useSelector((state) => state.campList);
  const {userid,UserToken}=useSelector(state=>state.userInfo)
  let allCamps = [];
  let campid = [];
  useEffect(() => {
    if (camps && camps.length === 0) {
      dispatch(fetchCampList(UserToken));
    }
  }, []);
  if (_id && camps && camps.length > 0) {
    campid = camps.filter((item) => initialValues.campid.includes(item.campid));
    if (campid.length > 0) {
      initialValues.campid = campid.map((item) => {
        return {
          value: item.campid,
          label: item.campname,
        };
      });
    }
  }

  const validationSchema = Yup.object({
    userid: Yup.string().required("user Id is required"),
    username: Yup.string().required("user Name is required"),
    userpwd: Yup.string().when("userpwdRequired", {
      is: true,
      then: Yup.string()
        .required("Password is required.")
        .required("Please Enter your password")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
          "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    }),
    campid: Yup.array(),
    verifier: Yup.string(),
    usertype: Yup.string(),
    remarks: Yup.string().max(100),
    createdby: Yup.string(),
    empno: Yup.string().required("Employee Number is required."),
    active: Yup.string().required("Active status is required."),
    lockstatus: Yup.string().required("Lock status is required."),
  });
  const onSubmit = async (values, { resetForm }) => {
    // const campid
    try {
      values.createdby=userid;
      values.empno=values.empno.toUpperCase();
      let campid = values.campid.map((item) => parseInt(item.value))
      campid=JSON.stringify(campid)
      if (type === "post") {
        const userpwd = await hash(values.userpwd, 10);
        const data={ ...values, userpwd, campid }
        const {
          data: { message },
        } = await axios.post(`${config.API_URL}/msdash/manageuser/`, data,{headers:{Authorization:UserToken}});
        setSuccess(message);
        resetForm("");
        dispatch(fetchUserList(UserToken));
        window.scroll(0, 0);
      } else {
        const data = { ...values, campid };
        const {
          data: { message },
        } = await axios.post(`${config.API_URL}/msdash/manageuser/`, data,{headers:{Authorization:UserToken}});
        setSuccess(message);
        dispatch(fetchUserList(UserToken));
        window.scroll(0, 0);
      }
      setTimeout(() => {
        setSuccess("");
      }, 4000);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setTimeout(() => {
        setWarning("");
      }, 4000);
    }
  };

  if (camps && camps.length > 0) {
    allCamps = camps.map((item) => {
      return {
        value: item.campid,
        label: item.campname,
      };
    });
  }

  const statusOptions = [
    { value: "Y", label: "Yes" },
    { value: "N", label: "No" },
  ];
  const lockOptions = [
    { value: "UNLOCK", label: "Unlock" },
    { value: "LOCK", label: "Lock" },
  ];
  const userTypeOption = [
    { value: "ADMIN", label: "Admin" }, //USER LEVEL ===102
    { value: "SUPER_ADMIN", label: "Super Admin" }, // USER LEVEL === 103
  ];

  console.log(initialValues);
  return (
    <div className="col-md-8 mx-auto">
      <div className="card">
        <div className="card-body">
          <h6 className="card-title">{title}</h6>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              console.log(formik);

              return (
                <Form className="forms-sample">
                  {success && (
                    <div className="alert alert-success">{success}</div>
                  )}
                  {warning && (
                    <div className="alert alert-warning">{warning}</div>
                  )}
                  <Controller
                    control="input"
                    type="text"
                    name="userid"
                    label="User Id"
                    asteric={true}
                    placeholder="Enter User Id"
                    disabled={_id ? true : false}
                    value={formik.values.userid}
                    wrapperClass={
                      formik.errors.userid && formik.touched.userid
                        ? "has-error"
                        : null
                    }
                  />
                  <Controller
                    control="input"
                    type="text"
                    name="empno"
                    label="Emp No."
                    asteric={true}
                    placeholder="Enter Emp No."
                    value={formik.values.empno}
                    wrapperClass={
                      formik.errors.empno && formik.touched.empno
                        ? "has-error"
                        : null
                    }
                  />

                  <Controller
                    control="input"
                    type="text"
                    name="username"
                    label="User Name"
                    placeholder="Enter User Name"
                    value={formik.values.username}
                    wrapperClass={
                      formik.errors.username && formik.touched.username
                        ? "has-error"
                        : null
                    }
                  />

                  {type === "post" && (
                    <Controller
                      control="input"
                      type="text"
                      asteric={true}
                      name="userpwd"
                      label="Password"
                      placeholder="Enter Password"
                      value={formik.values.userpwd}
                      wrapperClass={
                        formik.errors.userpwd && formik.touched.userpwd
                          ? "has-error"
                          : null
                      }
                    />
                  )}
                  <Controller
                    control="multiSelect"
                    label="Campaign Name"
                    value={formik.values.campid}
                    name="campid"
                    isMulti={true}
                    formik={formik}
                    options={allCamps}
                    wrapperClass={
                      formik.errors.campid && formik.touched.campid
                        ? "has-error"
                        : null
                    }
                  />

                  {/* <Controller
                    control="input"
                    type="text"
                    name="verifier"
                    label="Verifier"
                    placeholder="Enter Verifier"
                    value={formik.values.verifier}
                    wrapperClass={
                      formik.errors.verifier && formik.touched.verifier
                        ? "has-error"
                        : null
                    }
                  /> */}

                  <Controller
                    control="simpleSelect"
                    name="usertype"
                    label="User Type"
                    placeholder="Enter User Type"
                    value={formik.values.usertype}
                    options={userTypeOption}
                    wrapperClass={
                      formik.errors.usertype && formik.touched.usertype
                        ? "has-error"
                        : null
                    }
                  />
                  {/* <Controller
                    control="input"
                    type="text"
                    name="createdby"
                    label="Created By"
                    placeholder="Enter Created By"
                    value={formik.values.createdby}
                    wrapperClass={
                      formik.errors.createdby && formik.touched.createdby
                        ? "has-error"
                        : null
                    }
                  /> */}
                  <Controller
                    control="simpleSelect"
                    name="active"
                    label="Active"
                    options={statusOptions}
                    value={formik.values.active}
                    wrapperClass={
                      formik.errors.active && formik.touched.active
                        ? "has-error"
                        : null
                    }
                  />
                  <Controller
                    control="simpleSelect"
                    name="lockstatus"
                    label="Lock Status"
                    options={lockOptions}
                    value={formik.values.lockstatus}
                    wrapperClass={
                      formik.errors.lockstatus && formik.touched.lockstatus
                        ? "has-error"
                        : null
                    }
                  />
                  <Controller
                    control="textArea"
                    name="remarks"
                    label="Remarks"
                    placeholder="Enter Remarks"
                    value={formik.values.remarks}
                    wrapperClass={
                      formik.errors.remarks && formik.touched.remarks
                        ? "has-error"
                        : null
                    }
                  />

                  <div className="box-footer text-right">
                    <button
                      type="button"
                      className="btn btn-rounded btn-warning btn-outline mr-1"
                      onClick={() => history.goBack()}
                    >
                      <i className="fa fa-trash-o"></i> Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-rounded btn-primary btn-outline"
                    >
                      {formik.isSubmitting ? (
                        <i className="fa fa-spin fa-refresh"></i>
                      ) : (
                        <i className="fa fa-fw fa-save"></i>
                      )}{" "}
                      Save
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default User;
