import React from 'react'

function Footer({ui}) {
    return (
        <div className="container-fluid p-4 text-center" style={ui.style}>
            <p style={ui.text.style}>{ui.text.value}</p>
        </div>
    )
}

export default Footer
