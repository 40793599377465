import React from "react";
import { Field, FieldArray } from "formik";

function Options(props) {
  const { name, options, formIndex, ...rest } = props;
  return (
    <FieldArray
      name={name}
      render={(arrayHelper) => (
        <>
          {options.length > 0 ? (
            options.map((option, optionIndex) => (
              <>
                <div className="form-group row">
                  <label className="col-sm-3 col-lg-3 col-xl-3 col-12 col-form-label">
                    Option
                  </label>
                  <div className="col-sm-3 col-lg-3 col-xl-3 col-12">
                    <Field
                      type="text"
                      name={`${name}[${optionIndex}].label`}
                      placeholder="Field Label"
                      className="form-control w-100"
                    />
                  </div>
                  <div className="col-sm-3 col-lg-3 col-xl-3 col-12">
                    <Field
                      type="text"
                      name={`${name}[${optionIndex}].value`}
                      placeholder="Field Value"
                      className="form-control w-100"
                    />
                  </div>
                  <div className="col-sm-3 col-lg-3 col-xl-3 col-12">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        className="btn btn-secondary mr-1"
                        onClick={() => arrayHelper.remove(optionIndex)}
                      >
                        <i className="fa fa-trash" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() =>
                          arrayHelper.insert(optionIndex + 1, {
                            label: "",
                            value: "",
                          })
                        }
                      >
                        <i className="fa fa-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div className="form-group row">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-rounded btn-light btn-outline mr-1 mb-5"
                  onClick={() =>
                    arrayHelper.push({
                      label: "",
                      value: "",
                    })
                  }
                >
                  <i className="ti-plus"></i> Add Option
                </button>
              </div>
            </div>
          )}
        </>
      )}
    />
  );
}

export default Options;
