import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "../../dashboard/shared/controller/TextError";

function Input(props) {
  const { label, type,value, name, ...rest } = props;
  
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name} className="d-block font-weight-bold">
          {label}
        </label>
      )}

      <Field id={name} type={type} value={value} {...rest} className="form-control"/>
      {type==="range" && <span>{value}</span>}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default Input;
