import axios from "axios";
import React from "react";
import fileDownload from "js-file-download";
import { useState } from "react";
import { useSelector } from "react-redux";
import config from "../../config";

function UserDocs() {
  const [search, setSearch] = useState("");
  const [error, seterror] = useState("");
  const [data, setData] = useState([]);
  const { UserToken } = useSelector((state) => state.userInfo);
  const submitHandler = (e) => {
    try {
      e.preventDefault();
      if (search.length > 0) {
        axios
          .get(`${config.API_URL}/ms/getdocdownloaddata/${search}`, {
            headers: { Authorization: UserToken },
          })
          .then((res) => {
            const data = Object.values(res.data);
            setData(data);
            if (data.length === 0) {
              seterror("No Data Found.");
            } else {
              seterror("");
            }
          })
          .catch((err) => {
            seterror(err.message);
            setData([]);
          });
      }
    } catch (err) {
      seterror(err.message);
    }
  };

  const downloadFile = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        const arr = url.split(".");
        const file = "document." + arr[arr.length - 1];
        fileDownload(res.data, file);
      });
  };
  // console.log(data);
  return (
    <div className="col-md-12 mx-auto">
      <div className="card">
        <div className="card-body">
          <form onSubmit={submitHandler}>
            <div className="row">
                <label htmlFor="search" className="font-weight-bold">
                  Serach
                </label>
              <div className="input-group">

                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="submit">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </form>
          {error && <div className="alert alert-danger"> {error}</div>}
          {data.length > 0 && (
            <>
              <h6 className="card-title mt-3">Document Data</h6>
              <div className="table-responsive">
                <table id="dataTableExample1" className="table table-bordered">
                  <thead className="bg-light">
                    <tr>
                      <th>CUST_ID</th>
                      <th>CUST_KEY</th>
                      <th>CAMP_ID</th>
                      <th>CAMP_NAME</th>
                      <th>DOC_TYPE</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr>
                        <th>{item.CUST_ID}</th>
                        <th>{item.CUST_KEY}</th>
                        <th>{item.CAMP_ID}</th>
                        <th>{item.CAMP_NAME}</th>
                        <th>{item.DOC_TYPE}</th>
                        <th>
                          <button
                            className="btn btn-primary"
                            onClick={() => downloadFile(item.DOC_PATH)}
                          >
                            Download
                          </button>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserDocs;
