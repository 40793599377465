import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import config from "../../config";
import { logOut } from "../../redux/action/user";

function Header() {
  const { userid, UserToken, userlevel } = useSelector(state => state.userInfo);
  const history = useHistory();
  const dispatch = useDispatch();
  // const toggleSideBar=()=>{
  //   const icon=document.querySelector('.sidebar-toggler');
  //   const body=document.body;
  //   if(body.classList.contains("sidebar-folded")){
  //     console.log(body.classList)
  //     body.classList.remove("sidebar-folded");
  //     icon.classList.remove("active");
  //     icon.classList.add("not-active");
  //   }else{
  //     body.classList.add("sidebar-folded");
  //     icon.classList.remove("not-active");
  //     icon.classList.add("active");
  //   }



  // }

  return (
    <>

      <nav className="sidebar">
        <div className="sidebar-header">
          <Link to="/dashboard" className="sidebar-brand">
            Mircrosite
          </Link>
          <div className="sidebar-toggler not-active">
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className="sidebar-body ps">
          <ul className="nav" style={{ overflow: "hidden" }}>
            <li className="nav-item">
              <Link
                to="/dashboard"
                className="nav-link"
              >
                <i className="fa fa-dashboard"></i>
                <span className="link-title">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item nav-category">
              WORK STARTS FROM HERE...
            </li>
            {userlevel === "103" && <>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#rudra"
                  role="button"
                  aria-expanded="false"
                  aria-controls="rudra"
                >
                  <i className="fa fa-users"></i>
                  <span className="link-title">User</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down link-arrow"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </a>
                <div className="collapse" id="rudra">
                  <ul className="nav sub-menu">
                    <li className="nav-item">
                      <Link
                        to="/dashboard/add-user"
                        className="nav-link"
                      >
                        Add User
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/dashboard/view-users"
                        className="nav-link"
                      >
                        View Users
                      </Link>
                    </li>

                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  href="#campaign"
                  role="button"
                  aria-expanded="false"
                  aria-controls="campaign"
                >
                  <i className="fa fa-cubes"></i>
                  <span className="link-title">Campaign</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down link-arrow"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </a>
                <div className="collapse" id="campaign">
                  <ul className="nav sub-menu">
                    <li className="nav-item">
                      <Link
                        to="/dashboard/add-campaign"
                        className="nav-link"
                      >
                        Add Campaign
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/dashboard/view-campaign"
                        className="nav-link"
                      >
                        View Campaign
                      </Link>
                    </li>

                  </ul>
                </div>
              </li></>}
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="collapse"
                href="#components"
                role="button"
                aria-expanded="false"
                aria-controls="components"
              >
                <i className="fa fa-object-group"></i>
                <span className="link-title">Components</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-chevron-down link-arrow"
                >
                  <polyline points="6 9 12 15 18 9" />
                </svg>
              </a>
              <div className="collapse" id="components">
                <ul className="nav sub-menu">
                  <li className="nav-item">
                    <Link
                      to="/dashboard/login-component"
                      className="nav-link"
                    >
                      Login Component
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/header-component"
                      className="nav-link"
                    >
                      Header Component
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/slider-component"
                      className="nav-link"
                    >
                      Slider Component
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/tab-component"
                      className="nav-link"
                    >
                      Tab Component
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/dialer-component"
                      className="nav-link"
                    >
                      Dialer Component
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/footer"
                      className="nav-link"
                    >
                      Footer
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/feedback"
                      className="nav-link"
                    >
                      Feedback
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/dashboard/landing-page"
                      className="nav-link"
                    >
                      Landing Page
                    </Link>
                  </li>


                </ul>
              </div>
            </li>

            <li className="nav-item">
              <Link
                to="/dashboard/userdocs"
                className="nav-link"
              >
                <i className="fa fa-key"></i>
                <span className="link-title">Document Download</span>
              </Link>
            </li>
            {userlevel && userlevel === "103" && <li className="nav-item">
              <Link
                to="/dashboard/reset-password"
                className="nav-link"
              >
                <i className="fa fa-key"></i>
                <span className="link-title">Reset password</span>
              </Link>
            </li>}


          </ul>
          <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
            <div
              className="ps__thumb-x"
              tabIndex={0}
              style={{ left: "0px", width: "0px" }}
            />
          </div>
          <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
            <div
              className="ps__thumb-y"
              tabIndex={0}
              style={{ top: "0px", height: "0px" }}
            />
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
