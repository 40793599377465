import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Campaign from './Campaign'

function AddCampaign() {
  const {userlevel} = useSelector(store=>store.userInfo);
  const history=useHistory()
  if(userlevel && userlevel!=="103"){
    history.push("/dashboard/unauthorize")
  }
    const initialValues={
        campid:"",
        idRequire:true,
        campname:"",
        action_name:"ADD",
        isactive:"Y",
        dbobject:""

    }
    return (
        <Campaign initialValues={initialValues} type="post" title="Add Campaign" />
    )
}

export default AddCampaign
