import React from 'react'

function Loader() {
    return (
        <div className="loaderWrapper" style={{ width: "100%", height: "100%", background: "#a00" }}>
            <div id="loader">
                <div id="shadow"></div>
                <div id="box"></div>
            </div>
        </div>
    )
}

export default Loader
