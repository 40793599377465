import React, { useState } from "react";
import { FieldArray } from "formik";
import Input from "./Input";
import TextArea from "./TextArea";
import InputArray from "./InputArray";
import SimpleSelect from "./SimpleSelect";

function TabArray(props) {
  const [control, setControl] = useState("");
  const { legend, name, defaultValue, formik, buttonText, inputType, ...rest } =
    props;

  let value = "";
  switch (defaultValue.length) {
    case 1:
      value = formik.values[defaultValue[0]];
      break;
    case 2:
      value = formik.values[defaultValue[0]][defaultValue[1]];
      break;
    case 3:
      value = formik.values[defaultValue[0]][defaultValue[1]][defaultValue[2]];
      break;
    case 4:
      value =
        formik.values[defaultValue[0]][defaultValue[1]][defaultValue[2]][
          defaultValue[3]
        ];
      break;
    case 5:
      value =
        formik.values[defaultValue[0]][defaultValue[1]][defaultValue[2]][
          defaultValue[3]
        ][defaultValue[4]];
      break;
  }
  let arrayField = [
    { label: "Nav Text", name: "navText", Control: Input },
    { label: "Icon", name: "icon", Control: Input },
  ];
  const fieldObj={
    "policy":[...arrayField,{ label: "Exclude", name: "exclude", Control: TextArea }],
    "why-renew":arrayField,
    "video":[...arrayField,{label:"Content",name:"content", Control:TextArea}],
    "common":[...arrayField,{label:"Content",name:"content", Control:TextArea}]
  }
  
  const controlType=[
    {label:"Other",value:"common"},
    {label:"Policy",value:"policy"},
    {label:"Why Renew",value:"why-renew"},
    {label:"Video",value:"video"},
  ]
  const controlChange=(e,index)=>{
    const value=e.target.value;
    setControl(value);
    formik.values.API_DATA.tabs[index].control=value;
    console.log(formik.values)
  }

console.log(arrayField)
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => {
        return (
          <>
            {value.length > 0 ? (
              <fieldset>
                <legend> {legend}</legend>

                {value.map((icon, index) =>{ 
                  console.log("tab arrray",icon)
                  return(
                  <div key={index}>
                    <SimpleSelect 
                      label="Type"
                      name={`${name}[${index}].control`}
                      options={controlType}
                      value={formik.values.API_DATA.tabs[index].control}
                      onChange={(e)=>controlChange(e,index)}
                    />
                    {fieldObj[icon.control].map(({ Control, ...item }) =>(
                          <Control
                            name={`${name}[${index}][${item.name}]`}
                            label={item.label}
                            placeholder={item.label}
                            value={value[index][item.name]}
                          />
                        )
                      
                     )}   
                    <div className="box-footer text-right">
                      <button
                        type="button"
                        className="btn btn-rounded btn-secondary btn-outline mr-1"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <i className="ti-cut"></i> Remove
                      </button>
                      <button
                        type="button"
                        className="btn btn-rounded btn-dark btn-outline"
                        onClick={() => arrayHelpers.insert(index + 1, {control:"common"})}
                      >
                        <i className="ti-plus"></i>Add
                      </button>
                    </div>
                    <hr></hr>
                  </div>
                )})}
              </fieldset>
            ) : (
              <button
                type="button"
                className="btn btn-rounded btn-dark btn-outline mr-1 mb-5"
                onClick={() => arrayHelpers.push({control:"policy"})}
              >
                <i className="ti-plus"></i> {buttonText}
              </button>
            )}
          </>
        );
      }}
    />
  );
}

export default TabArray;
