import React from "react";
import { Field } from "formik";
function FormTypes(props) {
  const { formIndex, setFieldValue,name, form, ...rest } = props;
  const typesObj = {
    text: { control: "input", type: "text" },
    number: { control: "input", type: "number" },
    range: { control: "input", type: "range" },
    textArea: { control: "textArea", type: "" },
    radio: { control: "radio", type: "" },
    checkBox: { control: "checkBox", type: "" },
    select: { control: "select", type: "" },
    date: { control: "input", type: "date" },
    time: { control: "input", type: "time" },
    email: { control: "input", type: "email" },
    color: { control: "input", type: "color" },
    emojiRating: { control: "emojiRating", type: "" },
    toggle: { control: "toggle", type: "" },
  };
  const typeCHangeHandler = (e) => {
    const value = e.target.value;
    setFieldValue(name, {
      ...form,
      ...typesObj[value],
    });
  };
  return (
    <div className="form-group row">
      <div className="col-3">
        <label>Field Type</label>
      </div>
      <div className="col-9">
        <select className="form-control" onChange={typeCHangeHandler}>
          <option value="text">Text</option>
          <option value="number">Number</option>
          <option value="textArea">Paragraph</option>
          <option value="radio">Radio</option>
          <option value="checkBox">Check box</option>
          <option value="select">Select</option>
          <option value="email">Email</option>
          <option value="date">Date</option>
          <option value="time">Time</option>
          <option value="color">Color</option>
          <option value="emojiRating">EmojiRating</option>
          <option value="range">Range</option>
          <option value="toggle">Toggle</option>
        </select>
      </div>
    </div>
  );
}

export default FormTypes;
