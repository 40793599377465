import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import User from "./User";

function AddUser() {
  const { userlevel } = useSelector((store) => store.userInfo);
  const history = useHistory();
  if (userlevel && userlevel !== "103") {
    history.push("/dashboard/unauthorize");
  }
  const initialValues = {
    userid: "",
    username: "",
    userpwd: "Ats@123",
    userpwdRequired: true,
    campid: [],
    verifier: "MS_ADMIN",
    usertype: "ADMIN",
    parentid: "",
    remarks: "",
    mode: "INSERT",
    createdby: "",
    empno: "",
    active: "Y",
    lockstatus: "UNLOCK",
  };
  return <User initialValues={initialValues} type="post" title="Add User" />;
}

export default AddUser;
