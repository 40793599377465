import React from 'react'

function Plans() {
    return (
        <div className="container py-5 text-center">
            <h3>PRICING</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
            <div className="row pt-5">
                <div className="col-sm-3 col-12 col-lg-3 col-md-3 col-xl-3 p-3">
                    <div className="card shadow-sm" style={{border:"1px solid #d3d3d3"}}>
                        <div className="card-body text-center">
                            <h4 className="mb-5">Basic</h4>
                            <h2 className="text-primary">$99.99</h2>
                            <small>billed monthly</small>
                            <ul className="pt-3 p-0 font-weight-bold" style={{textTransform:"uppercase",listStyle:"none",textAlign:"center",fontSize:"16px",color:"#414141"}}>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                            </ul>

                            <div className="buttonWrap">
                                <button className="btn btn-primary">Buy Know</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 col-12 col-lg-3 col-md-3 col-xl-3 p-3">
                    <div className="card shadow-sm" style={{border:"1px solid #d3d3d3"}}>
                        <div className="card-body text-center">
                            <h4 className="mb-5">Basic</h4>
                            <h2 className="text-primary">$99.99</h2>
                            <small>billed monthly</small>
                            <ul className="pt-3 p-0 font-weight-bold" style={{textTransform:"uppercase",listStyle:"none",textAlign:"center",fontSize:"16px",color:"#414141"}}>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                            </ul>

                            <div className="buttonWrap">
                                <button className="btn btn-primary">Buy Know</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 col-12 col-lg-3 col-md-3 col-xl-3 p-3">
                    <div className="card shadow-sm" style={{border:"1px solid #d3d3d3"}}>
                        <div className="card-body text-center">
                            <h4 className="mb-5">Basic</h4>
                            <h2 className="text-primary">$99.99</h2>
                            <small>billed monthly</small>
                            <ul className="pt-3 p-0 font-weight-bold" style={{textTransform:"uppercase",listStyle:"none",textAlign:"center",fontSize:"16px",color:"#414141"}}>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                            </ul>

                            <div className="buttonWrap">
                                <button className="btn btn-primary">Buy Know</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 col-12 col-lg-3 col-md-3 col-xl-3 p-3">
                    <div className="card shadow-sm" style={{border:"1px solid #d3d3d3"}}>
                        <div className="card-body text-center">
                            <h4 className="mb-5">Basic</h4>
                            <h2 className="text-primary">$99.99</h2>
                            <small>billed monthly</small>
                            <ul className="pt-3 p-0 font-weight-bold" style={{textTransform:"uppercase",listStyle:"none",textAlign:"center",fontSize:"16px",color:"#414141"}}>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                                <li>Feature 1</li>
                            </ul>

                            <div className="buttonWrap">
                                <button className="btn btn-primary">Buy Know</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans
