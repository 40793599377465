
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import config from "../../../config";
import { fetchUserList } from "../../../redux/action/user";
// import Loader from "../../shared/Loader";
import Table from "../../shared/Table";

function ViewUsers() {
  const deleteApi = `${config.API_URL}/notep/`;
  const editLink = "/edit-user/";
  const userList = useSelector((store) => store.userList);
  const { users, error } = userList;
  const dispatch = useDispatch();
  const userInfo = useSelector(store=>store.userInfo);
  const {loading, userlevel,UserToken} = userInfo
  const history=useHistory()
  if(!loading && userlevel && userlevel!=="103"){
    history.push("/dashboard/unauthorize")
  }
  const column = [
    { name: "UserId", value: "userid" },
    { name: "User Name", value: "username"},
    { name: "Lock Status", value: "lockstatus"},
    { name: "Campaign", value: "campid" ,func:(col)=>typeof col==="object"?"":JSON.parse(col).toString()},
    { name: "Active", value: "active",func:(col)=>col==="Y"?"Yes":"No"},
    { name: "Edit", value: "userid", button: ["edit",] }
  ];
  useEffect(() => {
    if ((users && users.length === 0)) {
      dispatch(fetchUserList(UserToken));
    }
  }, []);
  const refreshData = () => {
    dispatch(fetchUserList(UserToken));
  };
  
  return (
    <>
    {/* {!loading && users && users.length===0 && <NotFound msg="No Users Found."/>} */}
    {/* {loading && <Loader />} */}
    {users && users.length>0 && <Table
      column={column}
      data={users}
      deleteApi={deleteApi}
      editLink={editLink}
      refresh={refreshData}
      title="View Users"
      idField="userid"
    />}
    </>
  );
}

export default ViewUsers;
