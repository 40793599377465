import React from "react";
import { Field, FieldArray } from "formik";
import ValidationType from "./ValidationType";
import Validations from "./Validations";
import FormTypes from "./FormTypes";
import Options from "./Options";

function FieldComponent(props) {
  const {
    control,
    type,
    name,
    formik,
    formIndex,
    arrayHelper,
    form,
    provided,
    ...rest
  } = props;
  // const form = formik.values.API_DATA.forms[formIndex];
  const { setFieldValue } = formik;
  const optionVisible = ["select", "checkBox", "radio"];
  const placeholderVisible = ["input", "textArea"];
  const placeholderHideType = ["date", "time", "color","range"];

  return (
    <div
      className="form-group p-3  mb-4 shadow bg-white position-relative"
      style={{ borderLeft: "5px solid #2b96d6", borderRadius: "10px" }}
    >
      <FormTypes
        formIndex={formIndex}
        setFieldValue={setFieldValue}
        form={form}
        name={name}
      />
      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Label</label>
        <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
          <Field
            type="text"
            name={`${name}.label`}
            placeholder="Field Label"
            className="form-control w-100"
          />
        </div>
      </div>
      {placeholderVisible.some(
        (item) => item === control && placeholderHideType.indexOf(type) === -1
      ) && (
        <div className="form-group row">
          <label className="col-sm-3 col-form-label">Placeholder</label>
          <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
            <Field
              type="text"
              name={`${name}.placeholder`}
              placeholder="Field Placeholder"
              className="form-control w-100"
            />
          </div>
        </div>
      )}
      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Name</label>
        <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
          <Field
            type="text"
            name={`${name}.name`}
            placeholder="Field Name"
            className="form-control w-100"
          />
        </div>
      </div>
      {control!=="toggle" && <div className="form-group row">
        <label className="col-sm-3 col-form-label">Default Value</label>
        <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
          <Field
            type="text"
            name={`${name}.value`}
            placeholder="Default Value"
            className="form-control w-100"
          />
        </div>
      </div>}
      {type === "range" || type === "number" ? (
        <>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Min Value</label>
            <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
              <Field
                type="number"
                name={`${name}.min`}
                placeholder="Min Value"
                className="form-control w-100"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Max Value</label>
            <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
              <Field
                type="number"
                name={`${name}.max`}
                placeholder="Max Value"
                className="form-control w-100"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label">Steps</label>
            <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
              <Field
                type="number"
                name={`${name}.step`}
                placeholder="step"
                step={0.000001}
                className="form-control w-100"
              />
            </div>
          </div>
        </>
      ) :null}
      {optionVisible.some((item) => item === control) && (
        <Options
          name={`${name}.options`}
          options={form.options}
          formIndex={formIndex}
        />
      )}

      {control!=="toggle" && <ValidationType
        value={form.validationType}
        setFieldValue={formik.setFieldValue}
        formIndex={formIndex}
        name={name}
      />}
      {control!=="toggle" && form.validationType && (
        <Validations
          name={`${name}.validations`}
          value={form.validations}
          formIndex={formIndex}
          setFieldValue={formik.setFieldValue}
        />
      )}
      <div className="row text-center">
        <div className="col-12">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className="btn btn-secondary mr-1"
              onClick={() => arrayHelper.remove(formIndex)}
            >
              <i className="fa fa-trash" />
            </button>
            <button
              type="button"
              className="btn btn-dark"
              onClick={() =>
                arrayHelper.insert(formIndex + 1, {
                  control: "input",
                  type: "text",
                  label: "",
                  options: [],
                  placeholder: "",
                  name: "",
                  value: "",
                  validationType: "",
                  validations: [],
                })
              }
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FieldComponent;
