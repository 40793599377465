import React, { useEffect } from "react";
import { eventLog } from "../utils/function";

function Other({ data, ui, randKey, otherFirst, setOtherFirst }) {
  useEffect(() => {
    if (otherFirst) {
      const data = [
        {
          RAND_KEY: randKey,
          CUST_ID: ui.cust_id,
          CAMP_ID: ui.camp_id,
          STATUS: "Others Tab Clicked.",
        },
      ];

      eventLog(data);
      setOtherFirst(0);
    }
  }, []);
  return (
    <div>
      <div className="card mt-4">
        <div className="card-header">
          <h4>{data.navText}</h4>
        </div>
        <div className="card-body">
          <p>{data.content}</p>
        </div>
      </div>
    </div>
  );
}

export default Other;
