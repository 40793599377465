import React, { useEffect, useState } from "react";
import { fetchCompUi } from "../utils/function";
import MyPolicy from "./MyPolicy";
import Other from "./Other";
import Video from "./Video";
import WhyRenew from "./WhyRenew";

function BodyWrapper({ randKey, setError }) {
  const [currentTab, setCurrentTab] = useState({});
  const [ui, setUi] = useState(1);
  const [firstLoad, setfirstLoad] = useState(true);
  const [whyRenewFirst, setWhyRenewFirst] = useState(true);
  const [videoFirst, setVideoFirst] = useState(true);
  const [otherFirst, setOtherFirst] = useState(1);
  const token = JSON.parse(localStorage.getItem("token"));
  useEffect(() => {
    fetchCompUi(4, setData, randKey, token, obj);
  }, []);
  const obj = {
    msg: "Tab Loaded.",
    onSuccess: function (data) {
      setCurrentTab(data.tabs[0]);
    },
    onError: (msg) => {
      setError(msg);
    },
  };
  let excludeList = [];
  let arr = [];
  if (ui.tabs) {
    arr = Object.keys(ui.tabs);
    arr.forEach((_, index) => {
      if (ui.tabs[index].control === "policy") {
        excludeList = ui.tabs[index].exclude.split(",");
      }
    });
  }
  const setData = (data) => {
    setUi(data);
  };
  // console.log(ui);
  return (
    <>
      {ui.tabs && (
        <>
          <div className="card" style={{ width: "100%" }}>
            <div className="card-body" style={{ padding: "0.5rem" }}>
              <ul className="nav">
                {arr.length > 0 &&
                  arr.map((_, index) => (
                    <li
                      key={`navTab${index}`}
                      className="nav-item"
                      onClick={() => setCurrentTab(ui.tabs[index])}
                    >
                      <span className="nav-link text-center">
                        <img
                          src={ui.tabs[index].icon}
                          className="mb-2"
                          alt=""
                          height="34px"
                          width="34px"
                          style={{
                            border: "1px solid black",
                            borderRadius: "50%",
                            padding: "4px",
                            display: "inline",
                            margin: "3px",
                          }}
                        ></img>
                        <h6 style={{ display: "inline" }}>
                          {ui.tabs[index].navText}
                        </h6>
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          {currentTab &&
          currentTab.control &&
          currentTab.control === "policy" ? (
            <MyPolicy
              randKey={randKey}
              firstLoad={firstLoad}
              setfirstLoad={setfirstLoad}
              setError={setError}
              exclude={excludeList}
              navText={currentTab.navText}
            />
          ) : currentTab &&
            currentTab.control &&
            currentTab.control === "why-renew" ? (
            <WhyRenew
              product_code={ui.product_code}
              camp_id={ui.camp_id}
              campName={ui.campName}
              token={token}
              cust_id={ui.cust_id}
              randkey={randKey}
              whyRenewFirst={whyRenewFirst}
              setWhyRenewFirst={setWhyRenewFirst}
            />
          ) : currentTab &&
            currentTab.control &&
            currentTab.control === "video" ? (
            <Video
              ui={ui}
              randKey={randKey}
              data={currentTab}
              videoFirst={videoFirst}
              setVideoFirst={setVideoFirst}
            />
          ) : (
            <Other
              ui={ui}
              randKey={randKey}
              data={currentTab}
              otherFirst={otherFirst}
              setOtherFirst={setOtherFirst}
            />
          )}
        </>
      )}
    </>
  );
}

export default BodyWrapper;
