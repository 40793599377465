import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Controller from "../../shared/controller/Controller";
import Style from "../../shared/controller/Style";
import config from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { fetchCampNameList } from "../../../redux/action/campaign";
import FooterPreview from "../preview/FooterPreview";

function Footer() {
    const [success, setSuccess] = useState("");
    const [warning, setWarning] = useState("");
    const [data, setData] = useState({
        loaded: false,
        initialValues: {
            ACTION: "ADD",
            CAMP_ID: "",
            CAMP_NAME: "",
            COMP_ID: 8,
            ACTIVE: 1,
            DB_OBJECT: "",
            LIST_ID: "null",
            HUNTGROUP: "null",
            DIALER_IP: "null",
            OTP_ENABLE: 0,
            API_DATA: {
                footer: {
                    style: {
                        backgroundColor: "",
                    },
                    text: {
                        value: "",
                        style: {
                            fontSize: "",
                            color: "",
                        },
                    },
                },
            },
        },
    });
    const { campNames } = useSelector((state) => state.campName);
    const { UserToken } = useSelector((state) => state.userInfo);
    const history = useHistory();
    const dispatch = useDispatch();
    let campOption = [];
    useEffect(() => {
        if (campNames && campNames.length === 0) {
            dispatch(fetchCampNameList(UserToken));
        }
    }, []);
    if (campNames && campNames.length > 0) {
        campOption = campNames.map((item) => {
            return {
                label: item.campname,
                value: item.campid,
            };
        });
    }
    const validationSchema = Yup.object({
        API_DATA: Yup.object(),
    });

    const onSubmit = (values, actions) => {
        console.log(values);
        axios
            .post(`${config.API_URL}/msdash/managecomponent/`, values, {
                headers: { Authorization: UserToken },
            })
            .then((res) => {
                setSuccess(res.data.message);
                window.scroll(0, 0);
                actions.setSubmitting(false);
                console.log(res);
            })
            .catch((err) => {
                setWarning(err.message);
                actions.setSubmitting(false);
                console.log(err);
            });
    };

    return (
        <Formik
            initialValues={data.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {(formik) => {
                console.log("values", formik.values);
                const { setFieldValue } = formik;
                const getCompData = (e) => {
                    const campid = e.value;
                    axios
                        .get(`${config.API_URL}/ms/getcompdata/${campid}/8`)
                        .then((result) => {
                            console.log("RESULT: ", result)
                            const apiData = {
                                ...result.data,
                            };
                            setData({
                                loaded: true,
                                initialValues: {
                                    ...data.initialValues,
                                    ...result.data,
                                    API_DATA: apiData,
                                    ACTION: "UPDATE",
                                    CAMP_ID: campid,
                                    CAMP_NAME: e.label,
                                },
                            });
                            setFieldValue("API_DATA", apiData);
                            setFieldValue("ACTION", "UPDATE");
                            setFieldValue("CAMP_ID", campid);
                            setFieldValue("CAMP_NAME", e.label);
                        })
                        .catch((err) => {
                            formik.resetForm();
                            setData({ ...data, loaded: true });
                            setFieldValue("CAMP_ID", campid);
                            setFieldValue("CAMP_NAME", e.label);
                            console.log(err);
                        });
                };
                return (
                    <>
                        <div className={"col-md-8 mx-auto"}>
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <h6 className="card-title">Footer</h6>
                                    <Form className="forms-sample">
                                        {success && (
                                            <div className="alert alert-success">{success}</div>
                                        )}
                                        {warning && (
                                            <div className="alert alert-warning">{warning}</div>
                                        )}
                                        <Controller
                                            control="multiSelect"
                                            label="Campaign Name"
                                            value={formik.values.campid}
                                            name="campid"
                                            isMulti={false}
                                            formik={formik}
                                            options={campOption}
                                            onChange={getCompData}
                                            wrapperClass={
                                                formik.errors.campid && formik.touched.campid
                                                    ? "has-error"
                                                    : null
                                            }
                                        />
                                        {data.loaded && (
                                            <>
                                                <fieldset>
                                                    <legend>Footer</legend>
                                                    <Style
                                                        defaultValue={["API_DATA", "footer", "style"]}
                                                        name="API_DATA.footer.style"
                                                        styleName="Footer Style"
                                                        formik={formik}
                                                    />
                                                    <fieldset>
                                                        <legend>Footer Text</legend>
                                                        <Controller
                                                            control="input"
                                                            label="Text"
                                                            value={
                                                                formik.values.API_DATA.footer.text.value
                                                            }
                                                            name="API_DATA.footer.text.value"
                                                        />
                                                        <Style
                                                            defaultValue={["API_DATA", "footer", "text", "style"]}
                                                            name="API_DATA.footer.text.style"
                                                            styleName="Text Style"
                                                            formik={formik}
                                                        />
                                                    </fieldset>
                                                </fieldset>
                                                <div className="box-footer text-right">
                                                    <button
                                                        type="button"
                                                        className="btn btn-rounded btn-warning btn-outline mr-1"
                                                        onClick={() => history.goBack()}
                                                    >
                                                        <i className="fa fa-trash-o"></i> Cancel
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-rounded btn-primary btn-outline"
                                                    >
                                                        {formik.isSubmitting ? (
                                                            <i className="fa fa-spin fa-refresh"></i>
                                                        ) : (
                                                            <i className="fa fa-fw fa-save"></i>
                                                        )}{" "}
                                                        Save
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </Form>
                                </div>
                            </div>
                        </div>

                        {data.loaded && (
                            <div
                                className="col-md-4"
                                style={{ position: "relative", display: "block" }}
                            >
                                <div className="card position-fixed w-25 shadow-lg p-3">
                                    <FooterPreview ui={formik.values.API_DATA} />
                                </div>
                            </div>
                        )}
                    </>
                );
            }}
        </Formik>
    );
}

export default Footer;
