import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "./TextError";

function Radio(props) {
  const {
    label,
    options,
    name,
    wrapperClass,
    defaultValue,
    ...rest
  } = props;
  return (
    <div
      className={
        wrapperClass ? "form-group row " + wrapperClass : "form-group row"
      }
    >
      <label className="col-sm-3 col-form-label">{label}</label>
      <div className=" col-sm-9">
          {options.map(item=>(
              <div className="form-check form-check-inline">
              <label className="form-check-label">
                <Field
                  className="form-check-input"
                  name={name}
                  value={item.value}
                  defaultChecked={defaultValue==="active"?true:false}
                  {...rest}
                />
                {item.label} <i className="input-frame" />
              </label>
            </div>
          ))}
      </div>
      
    </div>
  );
}

export default Radio;
