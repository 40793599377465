import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./table.css";

function Table({
  column,
  title,
  idField,
  data,
  deleteApi,
  editLink,
  refresh,
  deleteFunc,
  downloadFunc,
}) {
  const [success, setSuccess] = useState("");
  const [warning, setWarning] = useState("");
  const [deletingId, setDeleting] = useState("");
  // to be removed

  return (
    <div className="col-md-12 mx-auto">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">
            {title}
            {downloadFunc && (
              <button
                className="btn btn-danger text-white"
                style={{ cursor: "pointer", float: "right" }}
                onClick={downloadFunc}
              >
                <i className="fa fa-chevron-down"></i> Export
              </button>
            )}
          </h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table id="dataTableExample" className="table table-bordered">
              <thead className="bg-light font-weight-bold text-center">
                <tr>
                  <td className="table-head">#</td>
                  {column.map((item) => (
                    <td className="table-head">{item.name}</td>
                  ))}
                </tr>
              </thead>
              <tbody className="text-center">
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      {column.map((col) => {
                        if (!col.button) {
                          if (col.func) {
                            return <td>{col.func(item[col.value])}</td>;
                          } else {
                            return <td>{item[col.value]}</td>;
                          }
                        } else {
                          return (
                            <td>
                              <div className="text-center">
                                {col.button.map((btn) => {
                                  if (btn === "edit") {
                                    return (
                                      <Link
                                        to={
                                          "/dashboard" +
                                          editLink +
                                          item[idField]
                                        }
                                        className="waves-effect waves-light btn btn-outline btn-primary mr-3"
                                        // onClick={()=>{history.push(editLink+item._id)}}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </Link>
                                    );
                                  } else if (btn === "delete") {
                                    return (
                                      <span
                                        className="waves-effect waves-light btn btn-outline btn-danger mr-3"
                                        onClick={() => deleteFunc(item)}
                                      >
                                        {deletingId === item._id ? (
                                          <i className="fa fa-spin fa-refresh"></i>
                                        ) : (
                                          <i className="fa fa-trash-o"></i>
                                        )}
                                      </span>
                                    );
                                  } else if (btn === "view") {
                                    if (
                                      item.role.name === "Extension Employee"
                                    ) {
                                      return (
                                        <Link
                                          to={"extension-employee/" + item._id}
                                          className="waves-effect waves-light btn btn-outline btn-primary mb-5 mr-3"
                                          // onClick={()=>{history.push(editLink+item._id)}}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </Link>
                                      );
                                    } else if (
                                      item.role.name === "Key Employee"
                                    ) {
                                      return (
                                        <Link
                                          to={"key-employee/" + item._id}
                                          className="waves-effect waves-light btn btn-outline btn-primary mb-5 mr-3"
                                          // onClick={()=>{history.push(editLink+item._id)}}
                                        >
                                          <i className="fa fa-eye"></i>
                                        </Link>
                                      );
                                    }
                                  }
                                })}
                              </div>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  ))}
              </tbody>
              <tfoot className="bg-light font-weight-bold text-center">
                <tr>
                  <th>#</th>
                  {column.map((item) => (
                    <th>{item.name}</th>
                  ))}
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
