import React from "react";
import { Link } from "react-router-dom";

function Error404() {
  return (
    <div className="col-md-12 mx-auto">
      <div className="card shadow-lg">
        <div className="card-body text-center">
          <h1 style={{ fontSize: "100px" }}>404</h1>
          <h4>Data Not Found</h4>
          <Link to="/dashboard">
            <button className="btn btn-primary my-4">
              <i className="fa fa-arrow-left"></i> Back to Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Error404;
