import axios from "axios";
import config from "../config";
import * as yup from "yup";

export const errLog = async (data) => {
  const token =  JSON.parse(localStorage.getItem("token")) 
  await axios
    .post(`${config.API_URL}/errorlog/postError`, { headers: { Authorization: `Bearer ${token}` } }, data)
    .then((result) => {})
    .catch((err) => console.log(err));
};

export const eventLog = async (data) => {
  await axios
    .post(`${config.API_URL}/ms/setmsevent/`, data)
    .then((result) => {})
    .catch((err) => console.log("event err", err));
};

export const fetchCompUi = (id, setUi, randKey, token, obj) => {
  const api = `${config.API_URL}/ms/getcustprofileUi/${randKey}/${id}`;
  axios
    .get(api, { headers: { Authorization: `Bearer ${token}` } })
    .then(async(res) => {
     await setUi(res.data);
      if (obj && obj.onSuccess) {
        obj.onSuccess(res.data);
      }
      const data = [
        {
          RAND_KEY: randKey,
          CUST_ID: res.data.cust_id,
          CAMP_ID: res.data.camp_id,
          STATUS: obj.msg,
        },
      ];
      await eventLog(data);
    })
    .catch((err) => {
      if (obj && obj.onError) {
        obj.onError(err.message);
      }
      const data = {
        sNo: "9999",
        taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/getcustprofileUi/${randKey}/${id}`,
        errMessage: {
          Message: err.message,
        },
        errRemarks: `ERROR OCCURED WHILE FETCHING CUSTPROFILEUI AT COMPONENT ${id}`,
      };
      errLog(data);
    });
};

export const downloadIntoCSV = (Results, data) => {
  data.forEach((obj) => {
    const arr = [];
    Results[0].forEach((item) => {
      arr.push(obj[item]);
    });
    Results.push(arr);
  });
  var CsvString = "";
  Results.forEach(function (RowItem, RowIndex) {
    RowItem.forEach(function (ColItem, ColIndex) {
      CsvString += ColItem + ",";
    });
    CsvString += "\r\n";
  });
  CsvString = "data:application/csv," + encodeURIComponent(CsvString);
  var x = document.createElement("A");
  x.setAttribute("href", CsvString);
  x.setAttribute("download", "Event-data.csv");
  document.body.appendChild(x);
  x.click();
};

export const ipStringToNum = (dot) => {
  var d = dot.split(".");
  return ((+d[0] * 256 + +d[1]) * 256 + +d[2]) * 256 + +d[3];
};

export const ipNumToString = (num) => {
  var d = num % 256;
  for (var i = 3; i > 0; i--) {
    num = Math.floor(num / 256);
    d = (num % 256) + "." + d;
  }
  return d;
};




export function createYupSchema(schema, config) {
  const { name, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  validations.forEach(validation => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schema[name] = validator;
  return schema;
}

export function transformForm (forms){
  return Object.values(forms).map((item) => ({
    ...item,
    options: Object.values(item.options),
    validations: Object.values(item.validations).map((child) => ({
      ...child,
      params: Object.values(child.params),
    })),
  }));
}

export async function getToken(){
  try{
    const {data:{userToken}} =await axios.post(`${config.API_URL}/ms/gettoken`);
    return userToken;
  }catch(err){
    return false;
  }
}
export async function getCampDetails(randstr){
  try{
    const {data} =await axios.get(`${config.API_URL}/ms/getcliConfig/${randstr}`);
    return data;
  }catch(err){
    return false;
  }
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};