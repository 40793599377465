import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "./TextError";

function TextArea(props) {
  const {
    label,
    labelClass,
    name,
    wrapperClass,
    inputClass,
    dataList,
    dataListId,
    ...rest
  } = props;
  return (
    <div
      className={
        wrapperClass ? "form-group row " + wrapperClass : "form-group row"
      }
    >
      {label && (
        <label
          htmlFor={name}
          className={
            labelClass
              ? labelClass + " col-sm-3 col-form-label"
              : "col-sm-3 col-form-label"
          }
        >
          {label}
        </label>
      )}
      <div className="col-sm-9">
        <Field
          as="textarea"
          rows="4" cols="50"
          id={name}
          {...rest}
          className={inputClass ? `${inputClass} form-control` : "form-control"}
          list={dataListId}
        />
        <ErrorMessage name={name} component={TextError} />
      </div>
    </div>
  );
}

export default TextArea;
