import React from 'react'
import Input from './Input';
import './controller.css'
import InputTag from './MultiSelect';
import Radio from './Radio';
import SimpleSelect from './SimpleSelect';
import TextArea from './TextArea';


function Controller(props) {
   const {control, ...rest}=props;
   switch (control) {
      case "input":
        return <Input {...rest} />;
      case "multiSelect":
        return <InputTag {...rest} />
      case "simpleSelect":
        return <SimpleSelect {...rest} />
      case "radio":
        return <Radio {...rest} />
      case "textArea":
        return <TextArea {...rest} />
   }
}

export default Controller
