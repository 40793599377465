import React from 'react'

function Video({data}) {
    const videoArr=data.content.split(",");
    let arr=[];
    videoArr.forEach(item=>{
      const childArr=item.split("~");
      arr.push({name:childArr[0],link:childArr[1]})
    })
    // const arr = Object.keys(data.content);
    const arrLen = arr.length;
    const lg = arrLen === 1 ? "12" : arrLen === 2 ? "6" : "4";
    const md = arrLen === 1 ? "12" : "6";
    return (
        <div className="card mt-4" style={{overflow:"scroll",height:"460px"}}>
        <div className="card-body">
          <div className="row">
            {arr.map((item,index) => (
              <div className={`col-12`} KEY={index}>
                <p className="text-center">{item.name}</p>
                {item.link.startsWith("https://www.youtube.com/") ? (
                <iframe
                  width="100%"
                  height="231"
                  style={{ borderRadius: "8px" }}
                  src={item.link}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              ) : (
                <video style={cardStyle} controls>
                  <source src={item.link} />
                </video>
              )}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
}

export default Video


const cardStyle = {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    borderRadius: "8px",
  };
