import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function SliderPreview({ui}) {
  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1400: {
      items: 1,
    },
  };
  // console.log("UI",ui)
  return (
    <>
      {ui.slider && (
        <OwlCarousel className="owl-theme" margin={10} responsive={responsive} >
          {ui.slider.length > 0 &&
            ui.slider.map((image, index) => (
              <div className="item" key={`image${index}`}>
                <img
                  src={image.image}
                  className="d-block w-100"
                  alt={image.altText}
                />
              </div>
            ))}
        </OwlCarousel>
      )}
    </>
  );
}

export default SliderPreview;
