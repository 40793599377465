import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "./TextError";

function Input(props) {
  const {
    label,
    labelClass,
    name,
    wrapperClass,
    inputClass,
    dataList,
    dataListId,
    asteric,
    col,
    inlineStyle,
    ...rest
  } = props;
  return (
    <div
      className={
        wrapperClass ? "form-group row " + wrapperClass : "form-group row"
      }
    >
      {label && (
        <label
          htmlFor={name}
          className={
            labelClass
              ? labelClass + " col-sm-3 col-form-label"
              : "col-sm-3 col-form-label"
          }
        >
          {label} {asteric && <span style={{color:"red"}}>*</span>}
        </label>
      )}
      <div className={`col-sm-${col==="12"?"12":9}`}>
        <Field
          id={name}
          {...rest}
          className={inputClass ? `${inputClass} form-control` : "form-control"}
          list={dataListId}
          style={inlineStyle}
        />
        <ErrorMessage name={name} component={TextError} />
      </div>
      {dataList && (
        <dataList id={dataListId}>
          {dataList.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </dataList>
      )}
      
    </div>
  );
}

export default Input;
