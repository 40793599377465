import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import config from '../../../config';
import Campaign from './Campaign';

function EditCampaign(props) {
  const {UserToken,userlevel}=useSelector(state=>state.userInfo)
  const history=useHistory()
  if(userlevel && userlevel!=="103"){
    history.push("/dashboard/unauthorize")
  }
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    loaded: false,
    initialValues: {
        campid:"",
        campname:"",
        action_name:"UPDATE",
        isactive:"",
        dbobject:""
    },
  });
  const { camps } = useSelector((store) => store.campList);
  const {_id} = useParams();
  useEffect(() => {
    const obj = camps.find((camps) => camps.campid === _id);
    if (obj) {
      setData({
        loaded: true,
        initialValues: {
          ...data.initialValues,
          ...obj,
        },
      });
      
    } else {
      axios
        .get(`${config.API_URL}/msdash/getcamp/${_id}`, {
          headers: { Authorization: UserToken },
        })
        .then((result) => {
          console.log(result)
          if (!result.data[0].campid) {
            setMessage("User with id does not exist.");
          } else {
            setData({
              loaded: true,
              initialValues: {
                ...data.initialValues,
                ...result.data[0],
              },
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          setMessage("Something went wrong.");
        });

    }
  }, []);
  console.log("initialvalue",data)
    return (
        <>
    {data.loaded && <Campaign initialValues={data.initialValues} _id={_id} title="Edit User"/>}
    </>
    )
}

export default EditCampaign
