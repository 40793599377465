import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Error from "../Component/Error";
import config from "../config";

function ClickToCall() {
  const [ui, setUi] = useState("");
  const { randKey } = useParams();

  useEffect(async () => {
    const {
      data: { userToken: token },
    } = await axios.post(`${config.API_URL}/ms/gettoken`);
    try {
      const { data } = await axios.get(
        `${config.API_URL}/customer/getctcdata/${randKey}`,
        { headers: { Authorization: token } }
      );
      setUi(data);
    } catch (err) {
      setUi(err);
    }
  }, []);
  
  return (
    <>
      {ui && ui.CAMP_ID ? (
        <div className="page-wrapper">
          <div className="page-content">
            <div className="container">
              <div className="card mt-4 clickBoard">
                <div className="card-header text-center">
                  <img src={ui.LOGO_NAME} alt={ui.CAMP_ID} />
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <img src="/images/thanks.jpg" class="w-100"></img>
                    </div>
                    <div
                      className="col-md-6 my-auto"
                      dangerouslySetInnerHTML={{ __html: ui.VAR1 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Error msg={ui.message} />
      )}
    </>
  );
}

export default ClickToCall;
