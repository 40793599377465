import { ErrorMessage, Field } from "formik";
import React from "react";
import TextError from "../../dashboard/shared/controller/TextError";

function Select(props) {
  const { label, options,name ,value, ...rest } = props;
  return (
    <div className="form-group">
     {label &&  <label className="d-block font-weight-bold">{label}</label>}
     <Field as="select" className="form-control" name={name} {...rest} value={value}>
     {options &&
          options.length > 0 &&
          options.map((option) => (
            <option value={option.value}>
              {option.label}
            </option>
          ))}
     </Field>
     <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default Select;
