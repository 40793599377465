import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { fetchCompUi } from "../utils/function";

function Slider({ randKey, setError }) {
  const [ui, setUi] = useState({});
  const token = JSON.parse(localStorage.getItem("token"));
  const setData = (data) => {
    setUi(data);
  };
  useEffect(() => {
    fetchCompUi(3, setData, randKey, token, obj);
  }, []);
  let arr = [];
  if (ui.slider) {
    arr = Object.keys(ui.slider);
  }
  const obj = {
    msg: "Slider Component Loaded.",
    onError: (msg) => {
      setError(msg);
    },
  };
  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1400: {
      items: 1,
    },
  };
  return (
    <>
      {ui.slider && (
        <OwlCarousel
          className="owl-theme"
          margin={10}
          responsive={responsive}
          autoplay={true}
          loop={true}
        >
          {arr.length > 0 &&
            arr.map((_, index) => (
              <div className="item" key={`image${index}`}>
                <img
                  src={ui.slider[index].image}
                  className="d-block w-100"
                  alt={ui.slider[index].altText}
                />
              </div>
            ))}
        </OwlCarousel>
      )}
    </>
  );
}

export default Slider;
