import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Controller from "../../../Component/controller/Controller";
import { createYupSchema } from "../../../utils/function";

function FeedbackPreview({feedbackData}) {
  const initialValues = {};
  feedbackData.forms.forEach((item) => {
    initialValues[item.name] = item.value || "";
  });
  const yepSchema = feedbackData.forms.reduce(createYupSchema, {});
  
  const validateSchema = Yup.object().shape(yepSchema);
  const onSubmit = (values) => {
    console.log(values);
  };

  return (
    <>
      <div
        className="feedbackWrapper"
        style={{
          background: `linear-gradient(-45deg, ${feedbackData.gradientColor1}, ${feedbackData.gradientColor2}, ${feedbackData.gradientColor3}, ${feedbackData.gradientColor4})`,
          height:"80vh",
          overflow:"scroll"
        }}
      >
        <div className="container my-5">
          <div
            className="card"
            style={{ borderRadius: "5px", backgroundColor: "#e8f2fc" }}
          >
            <div className="card-body" style={feedbackData.cardStyle}>
              <div className="row">
                <div className="col-lg-12 col-sm-6 col-md-6 d-flex align-items-center">
                  <img src={feedbackData.backgroundImage} className="w-100"></img>
                </div>
                <div className="col-lg-12 col-sm-6 col-md-6 p-3">
                  <h4>{feedbackData.heading}</h4>
                  <p>
                    {feedbackData.content}
                  </p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validateSchema}
                    onSubmit={onSubmit}
                  >
                    {(formik) => {
                      console.log(formik)
                      return (
                        <form
                          onSubmit={formik.handleSubmit}
                          className="form card mt-2"
                          style={{ borderRadius: "10px" }}
                        >
                          <div className="card-body" >
                            <div
                              className="form-wrapper"  
                            >
                              {feedbackData.forms.map((item, index) => (
                                <React.Fragment key={"form" + index}>
                                  <Controller
                                    {...item}
                                    value={formik.values[item.name]}
                                    formik={formik}
                                  />
                                </React.Fragment>
                              ))}
                              {/* <!----------Rating-----------> */}
                            </div>
                            
                            <div className="form-group text-right">
                              <button type="submit" className="btn btn-primary" >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeedbackPreview;
