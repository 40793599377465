import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import config from "../../../config";
import User from "./User";

function EditUser(props) {
  const {userlevel,UserToken} = useSelector(store=>store.userInfo);
  const history=useHistory()
  if(userlevel && userlevel!=="103"){
    history.push("/dashboard/unauthorize")
  }
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    loaded: false,
    initialValues: {
      username: "",
      campid: [],
      verifier: "",
      usertype: "",
      parentid: "",
      remarks: "",
      mode: "UPDATE",
      createdby: "",
      empno: "",
      active: "Y",
      lockstatus: "UNLOCK",
    },
  });
  const { users } = useSelector((store) => store.userList);
  const { _id } = useParams();
  useEffect(() => {
    const obj = users.find((users) => users.userid === _id);
    if (obj) {
      setData({
        loaded: true,
        initialValues: {
          ...data.initialValues,
          ...obj,
          campid:obj.campid && typeof obj.campid==="string"?JSON.parse(obj.campid).map(item=>item.toString()):"",
          remarks: typeof obj.remarks==="object"?"":obj.remarks,
          usertype:obj.userlevel==="103"?"SUPER_ADMIN":"ADMIN"
        },
      });
      
    } else {
      axios
        .get(`${config.API_URL}/msdash/getuserbyid/${_id}`, {
          headers: { Authorization: UserToken },
        })
        .then((result) => {
          if (!result.data.username) {
            setMessage("User with id does not exist.");
          } else {
            setData({
              loaded: true,
              initialValues: {
                ...data.initialValues,
                ...result.data,
                campid:result.data.campid && typeof result.data.campid==="string"?JSON.parse(result.data.campid).map(item=>item.toString()):"",
                remarks: typeof result.data.remarks==="object"?"":result.data.remarks,
                usertype:result.data.userlevel==="103"?"SUPER_ADMIN":"ADMIN"
              },
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          setMessage("Something went wrong.");
        });

    }
  }, []);
  return (
    <>
    {data.loaded && <User initialValues={data.initialValues} _id={_id} title="Edit User"/>}
    </>
  );
}

export default EditUser;
