import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "../../dashboard/shared/controller/TextError";
function TextArea(props) {
  const { label,name, value, ...rest } = props;
  return (
    <div className="form-group">
      <label>{label}</label>

      <Field
        as="textarea"
        className="form-control"
        name={name}
        rows="5"
        value={value}
        {...rest}
      />
       <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default TextArea;
