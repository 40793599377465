import React, { useState } from "react";
import { useParams } from "react-router";
import Error from "../Component/Error";
import Home from "./Home";
import Login from "./Login";

function Index() {
  const { randKey } = useParams();
  const [error, setError] = useState("");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );
  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.clear()
    setUser({});
  };
  return (
    <>
      {error ? (
        <Error msg={error} />
      ) : (
        <>
          {!error &&
            user &&
            user.CUST_COUNT > 0 &&
            user.randKey === randKey ? (
            <Home randKey={randKey} logOut={logOut} setMainError={setError} />
          ) : (
            <Login randKey={randKey} setUser={setUser} />
          )}
        </>
      )}
    </>
  );
}

export default Index;
