import React from 'react'

function Header({logo,preview}) {
    return (
        <nav className="navbar navbar-expand position-relative">
            <img src={logo} width={preview?"80px":'150px'}/>
        </nav>
    )
}

export default Header
