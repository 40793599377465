import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Controller from "../Component/controller/Controller";
import {
  createYupSchema,
  errLog,
  eventLog,
  fetchCompUi,
  getCampDetails,
  getToken,
  transformForm,
} from "../utils/function";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../config";
import Error from "../Component/Error";
import Loader from "../Component/Loader";

function Feedback() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [formSuccess, setFormSuccess] = useState("");
  const [formError, setFormError] = useState("");
  const [feedbackData, setFeedbackData] = useState({});
  const [validateSchema, setSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const { randstr } = useParams();
  const setData = (data) => {
    const forms = transformForm(data.forms);
    const initValues = {};
    forms.forEach((item) => {
      initValues[item.name] = item.value || "";
    });
    const yepSchema = forms.reduce(createYupSchema, {});
    setLoading(false);
    setInitialValues(initValues);
    setSchema(Yup.object().shape(yepSchema));
    setFeedbackData({ ...data, forms: forms });
    setLoading(false);
  };
  const obj = {
    msg: "Feedback Page Loaded.",
    onError: (msg) => {
      setError(msg);
    },
  };
  useEffect(async () => {
    const token = await getToken();
    if (token) {
      fetchCompUi(6, setData, randstr, token.slice(7), obj);
    } else {
      setError("Something went wrong.");
    }
  }, []);

  const onSubmit = async (values) => {
    // console.log(values);
    try {
      const campInfo = await getCampDetails(randstr);
      if (campInfo) {
        const data = {
          ...values,
          randkey: randstr,
          table: "FEEDBACK",
          req_unique_id: campInfo.CUST_UNK_ID,
          campid: campInfo.CAMP_ID,
          campname: campInfo.CAMP_NAME,
          
        };
        const result = await axios.post(
          `${config.API_URL}/ms/setFormSubmit/`,
          data
        );
        if (result.data.status === "200") {
          setFormSuccess(result.data.message);
          const log = [
            {
              RAND_KEY: randstr,
              CUST_ID: feedbackData.cust_id,
              CAMP_ID: feedbackData.campName,
              STATUS: "Feedback Form Submitted Successfully.",
            },
          ];
          eventLog(log);
        } else {
          setFormError("Something went wrong.");
          const log = {
            sNo: "9999",
            taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/setFormSubmit/`,
            errMessage: {
              Message: result.message,
            },
            errRemarks: "ERROR OCCURED while Feedback Form Submitting.",
          };
          errLog(log);
        }
      }
    } catch (err) {
      setFormError(err.message);
      const log = {
        sNo: "9999",
        taskName: `Method-POST; EndPoint- ${config.API_URL}/ms/setFormSubmit/`,
        errMessage: {
          Message: err.message,
        },
        errRemarks: "ERROR OCCURED while Feedback Form Submitting.",
      };
      errLog(log);
    }
  };

  return (
    <>
      {error ? (
        <Error msg={error} />
      ) : loading ? (
        <Loader />
      ) : (
        feedbackData.forms && (
          <div
            className="feedbackWrapper position-absolute"
            style={{
              background: `linear-gradient(-45deg, ${feedbackData.gradientColor1}, ${feedbackData.gradientColor2}, ${feedbackData.gradientColor3}, ${feedbackData.gradientColor4})`,
            }}
          >
            <div className="container my-5">
              <div
                className="card"
                style={{ borderRadius: "5px", backgroundColor: "#e8f2fc" }}
              >
                <div className="card-body" style={feedbackData.cardStyle}>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 d-flex align-items-center">
                      <img
                        src={feedbackData.backgroundImage}
                        className="w-100"
                      ></img>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-md-6 p-3">
                      <h4>{feedbackData.heading}</h4>
                      <p>
                        {feedbackData.content}
                      </p>
                      {feedbackData.forms.length > 0 && (
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validateSchema}
                          onSubmit={onSubmit}
                        >
                          {(formik) => {
                            console.log(formik);
                            return (
                              <form
                                onSubmit={formik.handleSubmit}
                                className="form card mt-2"
                                style={{ borderRadius: "10px" }}
                              >
                                <div className="card-body">
                                  {formSuccess && (
                                    <div className="alert alert-success">
                                      {formSuccess}
                                    </div>
                                  )}
                                  {formError && (
                                    <div className="alert alert-danger">
                                      {formError}
                                    </div>
                                  )}
                                  <div
                                    className="form-wrapper"
                                    style={{
                                      height: "500px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {feedbackData.forms.map((item, index) => (
                                      <React.Fragment key={"form" + index}>
                                        <Controller
                                          {...item}
                                          value={formik.values[item.name]}
                                          formik={formik}
                                        />
                                      </React.Fragment>
                                    ))}
                                    {/* <!----------Rating-----------> */}
                                  </div>
                                  <div className="form-group text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Submit Feedback
                                    </button>
                                  </div>
                                </div>
                              </form>
                            );
                          }}
                        </Formik>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
}

export default Feedback;
