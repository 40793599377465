import React from "react";
import { Field, FieldArray } from "formik";
import Style from "../../../shared/controller/Style";

function Card(props) {
  const { name, formik, cardIndex, arrayHelper, card, ...rest } =
    props;
  const { setFieldValue } = formik;

  return (
    <div
      className="form-group p-3  mb-4 shadow bg-white position-relative"
      style={{ borderLeft: "5px solid #2b96d6", borderRadius: "10px" }}
    >
      <Style
        defaultValue={["API_DATA", "feature", "cards", cardIndex, "style"]}
        name={`API_DATA.feature.cards[${cardIndex}].style`}
        styleName="Card Style"
        formik={formik}
      />
      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Image</label>
        <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
          <Field
            type="text"
            name={`${name}.image`}
            placeholder="Card Image"
            className="form-control w-100"
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Heading Text</label>
        <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
          <Field
            type="text"
            name={`${name}.heading.text`}
            placeholder="Text"
            className="form-control w-100"
          />
        </div>
      </div>
      <Style
        defaultValue={["API_DATA", "feature", "cards", cardIndex, "heading","style"]}
        name={`API_DATA.feature.cards[${cardIndex}].heading.style`}
        styleName="Heading Style"
        formik={formik}
      />
      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Content Text</label>
        <div className="col-sm-9 col-lg-9 col-xl-9 col-12">
          <Field
            type="text"
            name={`${name}.content.text`}
            placeholder="Content Text"
            className="form-control w-100"
          />
        </div>
      </div>
      <Style
        defaultValue={["API_DATA", "feature", "cards", cardIndex, "content","style"]}
        name={`API_DATA.feature.cards[${cardIndex}].content.style`}
        styleName="Content Style"
        formik={formik}
      />

      <div className="row text-center">
        <div className="col-12">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className="btn btn-secondary mr-1"
              onClick={() => arrayHelper.remove(cardIndex)}
            >
              <i className="fa fa-trash" />
            </button>
            <button
              type="button"
              className="btn btn-dark"
              onClick={() =>
                arrayHelper.insert(cardIndex + 1, {
                  style: {
                    background: "",
                  },
                  image: "",
                  heading: {
                    text: "",
                    style: {
                      color: "",
                      fontSize: "",
                    },
                  },
                  content: {
                    text: "",
                    style: {
                      color: "",
                      fontSize: "",
                    },
                  },
                })
              }
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
