
import '../App.css';

function Error({ msg, btn, logOut }) {
  return (
    <>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center d-flex align-items-center justify-content-center">
              <div>
                <img
                  className="img-fluid w-50"
                  src="/images/500.svg"
                  alt="404 not found"
                />
                <h1 className="mt-5">
                  <span className="font-weight-bolder" style={{ color: "#19034D" }}>{msg ? msg : "You are not allowed for this action."}</span>
                </h1>
                <p className="lead my-4">
                </p>
              </div>
            </div>
          </div>
        </div>
        {btn === "reload" && <button className="btn btn-primary" onClick={logOut}>Log in </button>}
      </section>
      {/* <div className="App" style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}>
        <header className="App-header">
          <p>
            {msg}
          </p>

        </header>
 
      </div> */}
    </>
  );
}

export default Error;
